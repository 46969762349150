import { IListingState } from 'src/app/shared/shared.interface';
import { Status } from '../parts.interfaces';

export interface ICrossSearch {
   
  PartNumber: string;
  Manufacturer: string;
  Lifecycle: string;
  Datasheet: string;
  Description: string;
  RoHSStatus: string;
  CrossID: string;
  CrossPartNumber: string;
  CrossManufacturer: string;
  CrossLifecycle: string;
  CrossDatasheet: string;
  CrossDescription: string;
  CrossRoHSStatus: string;
  PlName: string;
  Type: string;
  Comment: string;
  FormFitFunction: string;
  ReplacementSource: string;
  MPartNumber: string;
  MManfacturer: string;
}

export interface ICrosssState {
  crossSearchGrid: IListingState<ICrossSearch>;
}
export class PartDetails {
  data: any;
  column: string;
}
export interface ICrossSearchRequest {
  crossSearch?: string;
  pageNumber?: number;
  pageSize?: number;
  mode: string;
}

export interface ICrossData {
  CrossDto: Array<ICrossSearch>;
  ReqPartNumber: string;
  ReqManufacturer: string;
}

export interface CrossSearchResponse {
  Status: Status;
  Result: Array<ICrossData>;
}
