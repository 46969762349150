import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  mapKendoSortToAPISort,
} from 'src/app/core/Admin/manufacturer/manufacturer.mapper';
import {
  mapKendoFilterToAPI as mapComplexFilter,
  mapKendoFilterToAPI,
  mapKendoSortToAPISort as mapComplexSort,
} from 'src/app/core/Admin/manufacturer-distributors/manufacturer-distributors.mapper';
import {
  mapKendoFilterToAPI as disMapFilter,
  mapKendoSortToAPISort as disMapSort,
} from 'src/app/core/Admin/distributor/distributor.mapper';

import {
  ActionTypes,
  AddEditCommodity,
  AddEditCommodityError,
  AddEditCommoditySuccess,
  AddEditRecord,
  AddEditRecordError,
  AddEditRecordSuccess,
  BuisnessUnitDelete,
  BuisnessUnitDeleteError,
  BuisnessUnitDeleteSuccess,
  BuisnessUnitEdit,
  BuisnessUnitEditError,
  BuisnessUnitEditSuccess,
  BuisnessUnitExportGrid,
  BuisnessUnitExportGridError,
  BuisnessUnitExportGridSucees,
  BuisnessUnitLoadData,
  BuisnessUnitLoadDataError,
  BuisnessUnitLoadDataSucees,
  CheckIsActiveFile,
  CheckIsActiveFileError,
  CheckIsActiveFileSuccess,
  CMDelete,
  CMDeleteError,
  CMDeleteSuccess,
  CMEdit,
  CMEditError,
  CMEditSuccess,
  CMExportGrid,
  CMExportGridError,
  CMExportGridSucees,
  CMLoadData,
  CMLoadDataError,
  CMLoadDataSucees,
  CMSiteExportGrid,
  CMSiteExportGridError,
  CMSiteExportGridSucees,
  ComDelete,
  ComDeleteError,
  ComDeleteSuccess,
  ComEdit,
  ComEditError,
  ComEditSuccess,
  ComExportGridError,
  ComExportGridSucees,
  ComLoadData,
  ComLoadDataError,
  ComLoadDataSucees,
  CurrencyEdit,
  CurrencyEditError,
  CurrencyEditSuccess,
  CustomerCommodityExportGrid,
  CustomerCommodityExportGridError,
  CustomerCommodityExportGridSucees,
  CustomerSiteExportGrid,
  CustomerSiteExportGridError,
  CustomerSiteExportGridSucees,
  DeleteRecord,
  DeleteRecordError,
  DeleteRecordSuccess,
  DisAdd,
  DisAddError,
  DisAddSuccess,
  DiscardActiveFile,
  DiscardActiveFileError,
  DiscardActiveFileSuccess,
  DisDelete,
  DisDeleteError,
  DisDeleteSuccess,
  DisEdit,
  DisEditError,
  DisEditSuccess,
  DisExportGridError,
  DisExportGridSucees,
  DisLoadData,
  DisLoadDataError,
  DisLoadDataSucees,
  DivisionsExportGrid,
  DivisionsExportGridError,
  DivisionsExportGridSucees,
  ExchangeDelete,
  ExchangeDeleteError,
  ExchangeDeleteSuccess,
  ExchangeEdit,
  ExchangeEditError,
  ExchangeEditSuccess,
  ExchangeLoadData,
  ExchangeLoadDataError,
  ExchangeLoadDataSucees,
  ExportComponentData,
  ExportComponentDataError,
  ExportComponentDataSucees,
  ExportLOAPdf,
  ExportLOAPdfError,
  ExportLOAPdfSuccess,
  ExportTicketReview,
  ExportTicketReviewError,
  ExportTicketReviewSucees,
  ExportValidatedFile,
  ExportValidatedFileError,
  ExportValidatedFileSuccess,
  GetCommList,
  GetCommListError,
  GetCommListSuccess,
  GetCommOwnersList,
  GetCommOwnersListError,
  GetCommOwnersListSuccess,
  GetCompanyByTypeName,
  GetCompanyByTypeNameError,
  GetCompanyByTypeNameSuccess,
  GetCompanyTypeList,
  GetCompanyTypeListError,
  GetCompanyTypeListSuccess,
  GetDisList,
  GetDisListError,
  GetDisListSuccess,
  GetManList,
  GetManListError,
  GetManListSuccess,
  GetRoleList,
  GetRoleListError,
  GetRoleListSuccess,
  GetStatusList,
  GetStatusListError,
  GetStatusListSuccess,
  GetTicketAssigneError,
  GetTicketAssigneSucees,
  GetTicketTypeError,
  GetTicketTypeSucees,
  GetTicketYearError,
  GetTicketYearSucees,
  GetTypeList,
  GetTypeListError,
  GetTypeListSuccess,
  GetUserEmailByInvitationCode,
  GetUserEmailByInvitationCodeError,
  GetUserEmailByInvitationCodeSuccess,
  InvitedAdd,
  InvitedAddError,
  InvitedAddSuccess,
  InvitedEdit,
  InvitedEditError,
  InvitedEditSuccess,
  InvitedExportGridError,
  InvitedExportGridSucees,
  InvitedResend,
  InvitedResendError,
  InvitedResendSuccess,
  InvitedUserDelete,
  InvitedUserDeleteError,
  InvitedUserDeleteSuccess,
  InvitedUsersLoadData,
  InvitedUsersLoadDataError,
  InvitedUsersLoadDataSucees,
  LoadDataGrid,
  LoadDataGridError,
  LoadDataGridSuccess,
  LOAsLoadData,
  LOAsLoadDataError,
  LOAsLoadDataSuceess,
  LogOut,
  LogOutError,
  LogOutSuccess,
  ManDelete,
  ManDeleteError,
  ManDeleteSuccess,
  ManDisDelete,
  ManDisDeleteError,
  ManDisDeleteSuccess,
  ManDisEdit,
  ManDisEditError,
  ManDisEditSuccess,
  ManDisExportGridError,
  ManDisExportGridSucees,
  ManDisLoadData,
  ManDisLoadDataError,
  ManDisLoadDataSucees,
  ManEdit,
  ManEditError,
  ManEditSuccess,
  ManExportGridError,
  ManExportGridSucees,
  ManLoadData,
  ManLoadDataError,
  ManLoadDataSucees,
  MapUploadedFile,
  MapUploadedFileError,
  MapUploadedFileSuccess,
  RegisteredExportGrid,
  RegisteredExportGridError,
  RegisteredExportGridSucees,
  RegisteredLoadData,
  RegisteredLoadDataError,
  RegisteredLoadDataSucees,
  RegisteredUserDelete,
  RegisteredUserDeleteError,
  RegisteredUserDeleteSuccess,
  RegisteredUserEdit,
  RegisteredUserEditError,
  RegisteredUserEditSuccess,
  ShareLOAs,
  ShareLOAsError,
  ShareLOAsSuccess,
  StartUploadFile,
  StartUploadFileError,
  StartUploadFileSuccess,
  SubmitUploadedFile,
  SubmitUploadedFileError,

  SubmitUploadedFileSuccess,
  TicketApprove,
  TicketApproveError,
  TicketApproveSuccess,
  TicketDelete,
  TicketDeleteError,
  TicketDeleteSuccess,
  TicketEdit,
  TicketEditError,
  TicketEditSuccess,
  TicketLoadData,
  TicketLoadDataError,
  TicketLoadDataRecord,
  TicketLoadDataRecordError,
  TicketLoadDataRecordSucees,
  TicketLoadDataSucees,
  TicketReject,
  TicketRejectError,
  TicketRejectSuccess,
  ValidateUploadedFile,
  ValidateUploadedFileError,
  ValidateUploadedFileSuccess,
  YearEdit,
  YearEditError,
  YearEditSuccess,
} from './admin.action';
import { ServiceMiddleware as UploadServiceMiddleware } from '../../../core/upload/service.middleware/api.services.middleware';
import { ServiceMiddleware } from '../../../core/Admin/service.layer/api.services.middleware';
import {
  TicketMapFilter,
  TicketMapSort,
} from 'src/app/core/Admin/ticket/ticket.mapper';
import {
  mapRegisteredUsersAPIFilter,
  mapRegisteredUsersAPISort,
} from 'src/app/core/Admin/users/registered-users/registered-users.mapper';
import { QuoteService } from '../../../core/Admin/quotes/quote.service';
import {
  mapInvitedUsersAPI,
  mapInvitedUsersAPISort,
} from 'src/app/core/Admin/users/invited-users/invited-users.mapper';
import { gridRequest } from 'src/app/core/grid.request';
import { mapFilter, MapMultipleFilter, mapSort } from 'src/app/core/utils';
import { actionType } from 'src/app/core/Admin/loa/loas.interfaces';
import { ApiType, GridDataTypes, IMapColumnsRequest, UploadTypes } from 'src/app/core/Admin/admin.interfaces';
import { SubmitTicketFileError, SubmitTicketFileSuccess } from '../../upload/stores/upload.action';
import { IContractManfacturer } from 'src/app/core/Admin/CMsites/CMsites.interfaces';
import { comMapSort } from 'src/app/core/Admin/customer-commoidities/customer-commodities.mapper';

@Injectable({
  providedIn: 'root',
})
export class AdminEffects {
  APIURL: string;
  constructor(
    private actions$: Actions,
    private serviceMiddleware: ServiceMiddleware,
    private uploadService: UploadServiceMiddleware,
    private quoteService: QuoteService
  ) { }


  
  /*-------------------------------------------------------BUISNESS UNIT-----------------------------------------------------------------------*/

  getGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_DATA_Grid),
      switchMap((action: LoadDataGrid) => {
        switch (action.gridType) {
          case GridDataTypes.CMSITES:
            this.APIURL = '/admin/site/cm/page';
            break;
          case GridDataTypes.COMMODITIES:
            this.APIURL = '/admin/commodity/owner/page';
            break;
          case GridDataTypes.CUSTOMERSITES:
            this.APIURL = '/admin/ownersite/page';
            break;
          case GridDataTypes.DIVISIONS:
            this.APIURL = '/admin/division/page';
            break;
        }
        const request = gridRequest(
          action?.page,
          action?.size,
          comMapSort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map(
            (dataResponse: any) => new LoadDataGridSuccess(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new LoadDataGridError(errorResponse))
          )
        );
      })
    );
  });

  getBuisnessUnitGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.BUISNESS_UNIT_LOAD_DATA),
      switchMap((action: BuisnessUnitLoadData) => {
        this.APIURL = '/admin/businessunits/page';
        const request = gridRequest(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          mapFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map(
            (dataResponse: any) => new BuisnessUnitLoadDataSucees(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new BuisnessUnitLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  	
  ExportDivisionsGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.Divisions_EXPORT_DATA),
      switchMap((action:DivisionsExportGrid) => {
        this.APIURL = '/admin/division/export/filtered';
        const request = gridRequest(
          action.payload?.page,
          action.payload?.size,
          mapSort(action.payload?.sort),
          mapFilter(action.payload?.filter)
        );
        return this.serviceMiddleware.exportFilteredList(this.APIURL,request).pipe(
          map(
            (dataResponse: any) =>
              new DivisionsExportGridSucees(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new DivisionsExportGridError(errorResponse))
          )
        );
      })
    );
  });
  ExportCmSitesGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CMSite_EXPORT_DATA),
      switchMap((action:CMSiteExportGrid) => {
        this.APIURL = '/admin/site/cm/export/filtered';
        const request = gridRequest(
          action.payload?.page,
          action.payload?.size,
          mapSort(action.payload?.sort),
          mapFilter(action.payload?.filter)
        );
        return this.serviceMiddleware.exportFilteredList(this.APIURL,request).pipe(
          map(
            (dataResponse: any) =>
              new CMSiteExportGridSucees(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new CMSiteExportGridError(errorResponse))
          )
        );
      })
    );
  });
  ExportCustomerSitesGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CustomerSite_EXPORT_DATA),
      switchMap((action:CustomerSiteExportGrid) => {
        this.APIURL = '/admin/ownersite/export/filtered';
        const request = gridRequest(
          action.payload?.page,
          action.payload?.size,
          mapSort(action.payload?.sort),
          mapFilter(action.payload?.filter)
        );
        return this.serviceMiddleware.exportFilteredList(this.APIURL,request).pipe(
          map(
            (dataResponse: any) =>
              new CustomerSiteExportGridSucees(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new CustomerSiteExportGridError(errorResponse))
          )
        );
      })
    );
  });
  ExportBuisnessUnitGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.BUISNESS_UNIT_EXPORT_DATA),
      switchMap((action:BuisnessUnitExportGrid) => {
        this.APIURL = '/admin/businessunits/export/filtered';
        const request = gridRequest(
          action.payload?.page,
          action.payload?.size,
          mapSort(action.payload?.sort),
          mapFilter(action.payload?.filter)
        );
        return this.serviceMiddleware.exportFilteredList(this.APIURL,request).pipe(
          map(
            (dataResponse: any) =>
              new BuisnessUnitExportGridSucees(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new BuisnessUnitExportGridError(errorResponse))
          )
        );
      })
    );
  });

  ExportCustomerCommodityGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.Customer_Commodity_EXPORT_DATA),
      switchMap((action:CustomerCommodityExportGrid) => {
        this.APIURL = '/admin/commodity/owner/export/filtered';
        const request = gridRequest(
          action.payload?.page,
          action.payload?.size,
          comMapSort(action.payload?.sort),
          MapMultipleFilter(action.payload?.filter)
        );
    
        return this.serviceMiddleware.exportFilteredList(this.APIURL,request).pipe(
          map(
            (dataResponse: any) =>
              new CustomerCommodityExportGridSucees(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new CustomerCommodityExportGridError(errorResponse))
          )
        );
      })
    );
  });


  EditBuisnessUnitGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.BUISNESS_UNIT_EDIT_RECORD),
      switchMap((action: BuisnessUnitEdit) => {
        this.APIURL = '/admin/businessunits';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) => new BuisnessUnitEditSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new BuisnessUnitEditError(errorResponse))
            )
          );
      })
    );
  });

  DeleteBuisnessUnitGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.BUISNESS_UNIT_DELETE_RECORD),
      switchMap((action: BuisnessUnitDelete) => {
        this.APIURL = '/admin/businessunits/';
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL + action.BuisnessUnitID)
          .pipe(
            map(
              (dataResponse: any) => new BuisnessUnitDeleteSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new BuisnessUnitDeleteError(errorResponse))
            )
          );
      })
    );
  });
  /*-------------------------------------------------------COM-----------------------------------------------------------------------*/

  getComGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.COM_LOAD_DATA),
      switchMap((action: ComLoadData) => {
        this.APIURL = '/admin/commodities/page';
        const request = gridRequest(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          mapFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map((dataResponse: any) => new ComLoadDataSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ComLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  ExportComGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.COM_EXPORT_DATA),
      switchMap(() => {
        this.APIURL = '/admin/commodities/export';
        return this.serviceMiddleware.exportList(this.APIURL).pipe(
          map((dataResponse: any) => new ComExportGridSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ComExportGridError(errorResponse))
          )
        );
      })
    );
  });

  EditComGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.COM_EDIT_RECORD),
      switchMap((action: ComEdit) => {
        this.APIURL = '/admin/commodity/owner/update';
        return this.serviceMiddleware
          .EditRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new ComEditSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ComEditError(errorResponse))
            )
          );
      })
    );
  });

  DeleteComGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.COM_DELETE_RECORD),
      switchMap((action: ComDelete) => {
        this.APIURL = '/admin/commodities/';
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL + action.comID)
          .pipe(
            map((dataResponse: any) => new ComDeleteSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ComDeleteError(errorResponse))
            )
          );
      })
    );
  });

  /*-------------------------------------------------------MAN-----------------------------------------------------------------------*/

  getManGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MAN_LOAD_DATA),
      switchMap((action: ManLoadData) => {
        this.APIURL = '/admin/manufacturers/page';
        const request = gridRequest(
          action?.page,
          action?.size,
          mapKendoSortToAPISort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map((dataResponse: any) => new ManLoadDataSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ManLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  ExportManGridDataa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MAN_EXPORT_DATA),
      switchMap(() => {
        this.APIURL = '/admin/manufacturers/export';
        return this.serviceMiddleware.exportList(this.APIURL).pipe(
          map((dataResponse: any) => new ManExportGridSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ManExportGridError(errorResponse))
          )
        );
      })
    );
  });

  EditManGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MAN_EDIT_RECORD),
      switchMap((action: ManEdit) => {
        this.APIURL = '/admin/manufacturers';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new ManEditSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ManEditError(errorResponse))
            )
          );
      })
    );
  });

  DeleteManGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MAN_DELETE_RECORD),
      switchMap((action: ManDelete) => {
        this.APIURL = '/admin/manufacturers/';
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL + action.manID)
          .pipe(
            map((dataResponse: any) => new ManDeleteSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ManDeleteError(errorResponse))
            )
          );
      })
    );
  });
  /*-------------------------------------------------------DIS-----------------------------------------------------------------------*/

  getDisGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.DIS_LOAD_DATA),
      switchMap((action: DisLoadData) => {
        this.APIURL = '/admin/distributors/page';
        const request = gridRequest(
          action?.page,
          action?.size,
          disMapSort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map((dataResponse: any) => new DisLoadDataSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new DisLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  ExportDisGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.DIS_EXPORT_DATA),
      switchMap(() => {
        this.APIURL = '/admin/distributors/export';
        return this.serviceMiddleware.exportList(this.APIURL).pipe(
          map((dataResponse: any) => new DisExportGridSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new DisExportGridError(errorResponse))
          )
        );
      })
    );
  });

  EditDisGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.DIS_EDIT_RECORD),
      switchMap((action: DisEdit) => {
        this.APIURL = '/admin/distributors';
        return this.serviceMiddleware
          .EditRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new DisEditSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new DisEditError(errorResponse))
            )
          );
      })
    );
  });

  AddDisGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.DIS_ADD_RECORD),
      switchMap((action: DisAdd) => {
        this.APIURL = '/admin/distributors';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new DisAddSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new DisAddError(errorResponse))
            )
          );
      })
    );
  });

  DeleteDisGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.DIS_DELETE_RECORD),
      switchMap((action: DisDelete) => {
        this.APIURL = '/admin/distributors/';
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL + action.disID)
          .pipe(
            map((dataResponse: any) => new DisDeleteSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new DisDeleteError(errorResponse))
            )
          );
      })
    );
  });

  /*------------------------------------------------Invited-User--------------------------------------------------------*/
  getInvitedUserGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.INVITED_USERS_LOAD_DATA),
      switchMap((action: InvitedUsersLoadData) => {
        this.APIURL = '/admin/invitations/page';
        const request = gridRequest(
          action?.page,
          action?.size,
          mapInvitedUsersAPISort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map(
            (dataResponse: any) => new InvitedUsersLoadDataSucees(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new InvitedUsersLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  GetCompanyTypeList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_COMPANY_TYPE_List),
      switchMap((action: GetCompanyTypeList) => {
        this.APIURL = '/admin/companyType/getAllTypes';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map(
            (dataResponse: any) => new GetCompanyTypeListSuccess(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetCompanyTypeListError(errorResponse))
          )
        );
      })
    );
  });

  GetRoleList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_ROLE_List),
      switchMap((action: GetRoleList) => {
        this.APIURL = '/admin/roles/getSimpleRoles/';
        return this.serviceMiddleware
          .Get(this.APIURL + action.companyTypeName)
          .pipe(
            map((dataResponse: any) => new GetRoleListSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new GetRoleListError(errorResponse))
            )
          );
      })
    );
  });

  EditInvitedGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.INVITED_USER_EDIT_RECORD),
      switchMap((action: InvitedEdit) => {
        this.APIURL = '/admin/invitations/edit';
        return this.serviceMiddleware
          .EditRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new InvitedEditSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new InvitedEditError(errorResponse))
            )
          );
      })
    );
  });

  AddInvitedGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.INVITED_USER_ADD_RECORD),
      switchMap((action: InvitedAdd) => {
        this.APIURL = '/admin/invitations/add';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new InvitedAddSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new InvitedAddError(errorResponse))
            )
          );
      })
    );
  });

  ResendInvitedGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.INVITED_USER_RESEND_RECORD),
      switchMap((action: InvitedResend) => {
        this.APIURL = '/admin/invitations/resend/';
        return this.serviceMiddleware
          .AddRecord(this.APIURL + action.invitationId, {})
          .pipe(
            map((dataResponse: any) => new InvitedResendSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new InvitedResendError(errorResponse))
            )
          );
      })
    );
  });
  DeleteInvitedUserRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.INVITED_USER_DELETE_RECORD),
      switchMap((action: InvitedUserDelete) => {
        this.APIURL = '/admin/invitations/delete/';
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL + action.userID)
          .pipe(
            map(
              (dataResponse: any) => new InvitedUserDeleteSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new InvitedUserDeleteError(errorResponse))
            )
          );
      })
    );
  });

  GetCompanyByTypeName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_COMPANY_BY_TYPENAME),
      switchMap((action: GetCompanyByTypeName) => {
        this.APIURL = '/admin/companyType/getCompaniesName/';
        return this.serviceMiddleware
          .Get(this.APIURL + action.companyTypeId + '/' + action.companyName)
          .pipe(
            map(
              (dataResponse: any) =>
                new GetCompanyByTypeNameSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new GetCompanyByTypeNameError(errorResponse))
            )
          );
      })
    );
  });


  ExportInvitedUserGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.INVITED_EXPORT_DATA),
      switchMap((action: CMExportGrid) => {
        this.APIURL = '/admin/invitations/export/filtered';
        const request = gridRequest(
          action.payload?.page,
          action.payload?.size,
          mapInvitedUsersAPISort(action.payload?.sort),
          MapMultipleFilter(action.payload?.filter)
        );
        return this.serviceMiddleware.exportFilteredList(this.APIURL,request).pipe(
          map((dataResponse: any) => new InvitedExportGridSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new InvitedExportGridError(errorResponse))
          )
        );
      })
    );
  });

  /*------------------------------------------------Registered-User--------------------------------------------------------*/
  getRegisteredUserGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.REGISTERED_USERS_LOAD_DATA),
      switchMap((action: RegisteredLoadData) => {
        this.APIURL = '/admin/user/page';
        const request = gridRequest(
          action?.page,
          action?.size,
          mapRegisteredUsersAPISort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map(
            (dataResponse: any) => new RegisteredLoadDataSucees(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new RegisteredLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  EditRegisteredGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.REGISTERED_USER_EDIT_RECORD),
      switchMap((action: RegisteredUserEdit) => {
        this.APIURL = '/admin/user/update';
        return this.serviceMiddleware
          .EditRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) => new RegisteredUserEditSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new RegisteredUserEditError(errorResponse))
            )
          );
      })
    );
  });

  DeleteRegisteredUserRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.REGISTERED_USER_DELETE_RECORD),
      switchMap((action: RegisteredUserDelete) => {
        this.APIURL = '/admin/user/delete/';
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL + action.userID)
          .pipe(
            map(
              (dataResponse: any) =>
                new RegisteredUserDeleteSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new RegisteredUserDeleteError(errorResponse))
            )
          );
      })
    );
  });

  GetUserEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_USER_EMAIL),
      switchMap((action: GetUserEmailByInvitationCode) => {
        this.APIURL = '/admin/invitations/email/';
        return this.serviceMiddleware
          .Get(this.APIURL + action.InvitationCode)
          .pipe(
            map(
              (dataResponse: any) =>
                new GetUserEmailByInvitationCodeSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new GetUserEmailByInvitationCodeError(errorResponse))
            )
          );
      })
    );
  });
  ExportRegisteredUserGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.REGISTERED_EXPORT_DATA),
      switchMap((action: RegisteredExportGrid) => {
        this.APIURL = '/admin/user/export/filtered';
        const request = gridRequest(
          action.payload?.page,
          action.payload?.size,
          mapSort(action.payload?.sort),
          MapMultipleFilter(action.payload?.filter)
        );
 

        return this.serviceMiddleware.exportFilteredList(this.APIURL,request).pipe(
          map(
            (dataResponse: any) => new RegisteredExportGridSucees(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new RegisteredExportGridError(errorResponse))
          )
        );
      })
    );
  });
  /*--------------------------------------------MAN-DIS-Relationship------------------------------------------------------------------*/
  getManDisGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MAN_DIS_LOAD_DATA),
      switchMap((action: ManDisLoadData) => {
        this.APIURL = '/admin/manufacturerDistributors/page';
        const request = gridRequest(
          action?.page,
          action?.size,
          mapComplexSort(action?.sort),
          mapKendoFilterToAPI(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map((dataResponse: any) => new ManDisLoadDataSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ManDisLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  EditManDisGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MAN_DIS_EDIT_RECORD),
      switchMap((action: ManDisEdit) => {
        this.APIURL = '/admin/manufacturerDistributors';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new ManDisEditSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ManDisEditError(errorResponse))
            )
          );
      })
    );
  });

  DeleteManDisGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MAN_DIS_DELETE_RECORD),
      switchMap((action: ManDisDelete) => {
        this.APIURL = '/admin/manufacturerDistributors/';
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL + action.id)
          .pipe(
            map((dataResponse: any) => new ManDisDeleteSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ManDisDeleteError(errorResponse))
            )
          );
      })
    );
  });

  ExportManDisGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MAN_DIS_EXPORT_DATA),
      switchMap(() => {
        this.APIURL = '/admin/manufacturerDistributors/export';
        return this.serviceMiddleware.exportList(this.APIURL).pipe(
          map((dataResponse: any) => new ManDisExportGridSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ManDisExportGridError(errorResponse))
          )
        );
      })
    );
  });

  /**----------------------------------------------------CM-------------------------------------------- */

  getCMGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CM_LOAD_DATA),

      switchMap((action: CMLoadData) => {
        this.APIURL = '/admin/contractmanufacturer/page';
        const request = gridRequest(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          mapFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map((dataResponse: any) => new CMLoadDataSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new CMLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  ExportCMGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CM_EXPORT_DATA),
      switchMap((action: CMExportGrid) => {
        this.APIURL = '/admin/contractmanufacturer/export/filtered';
        const request = gridRequest(
          action.payload?.page,
          action.payload?.size,
          mapSort(action.payload?.sort),
          mapFilter(action.payload?.filter)
        );
        return this.serviceMiddleware.exportFilteredList(this.APIURL,request).pipe(
          map((dataResponse: any) => new CMExportGridSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new CMExportGridError(errorResponse))
          )
        );
      })
    );
  });

  EditCMGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CM_EDIT_RECORD),
      switchMap((action: CMEdit) => {
        this.APIURL = '/admin/contractmanufacturer';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new CMEditSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new CMEditError(errorResponse))
            )
          );
      })
    );
  });
  DeleteCMGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CM_DELETE_RECORD),
      switchMap((action: CMDelete) => {
        this.APIURL = '/admin/contractmanufacturer/';
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL + action.CMID)
          .pipe(
            map((dataResponse: any) => new CMDeleteSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new CMDeleteError(errorResponse))
            )
          );
      })
    );
  });

  /**----------------------------------------------------TICKET-------------------------------------------- */

  getTicketGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.TICKET_LOAD_DATA),

      switchMap((action: TicketLoadData) => {

        this.APIURL = '/cmt/ticket/page';
        const request = gridRequest(
          action?.page,
          action?.size,
          TicketMapSort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map((dataResponse: any) => new TicketLoadDataSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new TicketLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  //get ticket record

  getTicketGridDataRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.TICKET_LOAD_DATA_RECORD),
      switchMap((action: TicketLoadDataRecord) => {
        this.APIURL = '/cmt/CmtProcessing/getSampleRows/';
        return this.serviceMiddleware.Get(this.APIURL + action.TicketID).pipe(
          map(
            (dataResponse: any) => new TicketLoadDataRecordSucees(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new TicketLoadDataRecordError(errorResponse))
          )
        );
      })
    );
  });

  approveTicketRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.TICKET_APPROVE_RECORD),
      switchMap((action: TicketApprove) => {
        this.APIURL = '/cmt/ticket/approveTicketStatus/';
        return this.serviceMiddleware
          .PutTicket(this.APIURL + action.TicketID)
          .pipe(
            map((dataResponse: any) => new TicketApproveSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new TicketApproveError(errorResponse))
            )
          );
      })
    );
  });

  rejectTicketRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.TICKET_REJECT_RECORD),
      switchMap((action: TicketReject) => {
        this.APIURL = '/cmt/ticket/rejectTicketStatus/';
        return this.serviceMiddleware
          .PutTicket(this.APIURL + action.TicketID)
          .pipe(
            map((dataResponse: any) => new TicketRejectSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new TicketRejectError(errorResponse))
            )
          );
      })
    );
  });

  EditTicketGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.TICKET_EDIT_RECORD),
      switchMap((action: TicketEdit) => {
        this.APIURL = '/cmt/ticket';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new TicketEditSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new TicketEditError(errorResponse))
            )
          );
      })
    );
  });

  DeleteTicketGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.TICKET_DELETE_RECORD),
      switchMap((action: TicketDelete) => {
        this.APIURL = '/cmt/ticket/';
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL + action.TicketID)
          .pipe(
            map((dataResponse: any) => new TicketDeleteSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new TicketDeleteError(errorResponse))
            )
          );
      })
    );
  });
  /**----------------------------------------------------YEAR-CURRENCY-------------------------------------------- */
  AddYearGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.YEAR_EDIT_RECORD),
      switchMap((action: YearEdit) => {
        this.APIURL = '/admin/years';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new YearEditSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new YearEditError(errorResponse))
            )
          );
      })
    );
  });

  AddCurrencyGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CURRENCY_EDIT_RECORD),
      switchMap((action: CurrencyEdit) => {
        this.APIURL = '/admin/currencies';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new CurrencyEditSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new CurrencyEditError(errorResponse))
            )
          );
      })
    );
  });

  getYearExchangeGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXCHANGE_LOAD_DATA),
      switchMap((action: ExchangeLoadData) => {
        this.APIURL = '/admin/years/currenciesData';
        return this.serviceMiddleware
          .getYearExchange(action?.page, action?.size, this.APIURL)
          .pipe(
            map(
              (dataResponse: any) => new ExchangeLoadDataSucees(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ExchangeLoadDataError(errorResponse))
            )
          );
      })
    );
  });

  DeleteYearExchangeGrid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXCHANGE_DELETE_RECORD),
      switchMap((action: ExchangeDelete) => {
        this.APIURL = '/admin/years/currencies/';
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL + action.yearId)
          .pipe(
            map((dataResponse: any) => new ExchangeDeleteSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ExchangeDeleteError(errorResponse))
            )
          );
      })
    );
  });

  EditYearExchangeGrid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXCHANGE_EDIT_RECORD),
      switchMap((action: ExchangeEdit) => {
        this.APIURL = '/admin/years/currencies';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new ExchangeEditSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ExchangeEditError(errorResponse))
            )
          );
      })
    );
  });

  /**------------------------------------------------------DropDownLists---------------------------------------------------------- */
  GetManList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_MAN_List),
      switchMap((action: GetManList) => {
        this.APIURL = '/admin/manufacturers';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new GetManListSuccess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetManListError(errorResponse))
          )
        );
      })
    );
  });

  GetDisList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_DIS_List),

      switchMap((action: GetDisList) => {
        this.APIURL = '/admin/distributors';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new GetDisListSuccess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetDisListError(errorResponse))
          )
        );
      })
    );
  });

  GetCommList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_COMM_List),
      switchMap((action: GetCommList) => {
        this.APIURL = '/admin/commodities';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new GetCommListSuccess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetCommListError(errorResponse))
          )
        );
      })
    );
  });


  GetCommOwnersList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_COMM_OWNERS_List),
      switchMap((action: GetCommOwnersList) => {
        this.APIURL = `/admin/commodity/owner/list/${action.commId}`;
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new GetCommOwnersListSuccess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetCommOwnersListError(errorResponse))
          )
        );
      })
    );
  });

  GetStatusList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_STATUS_List),
      switchMap((action: GetStatusList) => {
        this.APIURL = '/admin/manufacturer-status';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new GetStatusListSuccess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetStatusListError(errorResponse))
          )
        );
      })
    );
  });
  // get ticket type
  GetTicketType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_TICKET_TYPE),
      switchMap(() => {
        this.APIURL = '/cmt/ticketType';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new GetTicketTypeSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetTicketTypeError(errorResponse))
          )
        );
      })
    );
  });

  // get ticket assignee
  GetTicketAssignee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_TICKET_ASSIGNEE),
      switchMap(() => {
        this.APIURL = '/cmt/user';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new GetTicketAssigneSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetTicketAssigneError(errorResponse))
          )
        );
      })
    );
  });

    //logout 

    LogOut$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(ActionTypes.LOG_OUT),
        switchMap((action: LogOut) => {
          this.APIURL = '/api/logout';
          return this.serviceMiddleware
            .LogOut(this.APIURL, {})
            .pipe(
              map((dataResponse: any) => new LogOutSuccess(dataResponse)),
              catchError((errorResponse: HttpErrorResponse) =>
                of(new LogOutError(errorResponse))
              )
            );
        })
      );
    });
  

  //get ticket year
  GetTicketYear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_TICKET_YEAR),
      switchMap(() => {
        this.APIURL = '/admin/years';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new GetTicketYearSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetTicketYearError(errorResponse))
          )
        );
      })
    );
  });

  GetTypeList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_TYPE_List),

      switchMap((action: GetTypeList) => {
        this.APIURL = '/admin/distributorTypes';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new GetTypeListSuccess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetTypeListError(errorResponse))
          )
        );
      })
    );
  });
  exportComponentData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_COMPONENT_DATA),
      switchMap((action: ExportComponentData) => {
        this.APIURL =
          '/cmt/CmtProcessing/exportComponentData/' + `${action?.ticketId}`;
        return this.uploadService.exportList(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new ExportComponentDataSucees(dataResponse, action.fileName)
          ),
          catchError((errorResponse: any) =>
            of(new ExportComponentDataError(errorResponse))
          )
        );
      })
    );
  });
  exportTicketReviewData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_TICKET_REVIEW),
      switchMap((action: ExportTicketReview) => {
        this.APIURL =
          '/cmt/CmtProcessing/ticket/download/' + `${action?.ticketId}`;
        return this.uploadService.exportList(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new ExportTicketReviewSucees(dataResponse, action.fileName)
          ),
          catchError((errorResponse: any) =>
            of(new ExportTicketReviewError(errorResponse))
          )
        );
      })
    );
  });

  /**------------------------------------------------------LOA---------------------------------------------------------- */

  getLOAsGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOA_GRID_LOAD_DATA),
      switchMap((action: LOAsLoadData) => {
        if (action.actionType === actionType.ADMIN) {
          this.APIURL =
            '/rfq/loa/GetAdminLoas';
        }
        else {
          this.APIURL =
            '/rfq/loa/GetSupplierLoas';
        }

        const request = gridRequest(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map((dataResponse: any) => new LOAsLoadDataSuceess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new LOAsLoadDataError(errorResponse))
          )
        );
      })
    );
  });
  shareSelectedLOAsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SHARE_LOA),
      switchMap((action: ShareLOAs) => {
        this.APIURL = '/rfq/loa/UpdateLoaStatus';
        return this.serviceMiddleware.AddRecord(this.APIURL, action.payload).pipe(
          map((dataResponse: any) => new ShareLOAsSuccess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ShareLOAsError(errorResponse))
          )
        );
      })
    );
  });
  exportLOAPdf$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_LOA_PDF),
      switchMap((action: ExportLOAPdf) => {
        //if (action.actionType === actionType.ADMIN) {
        this.APIURL =
          `/rfq/Loa/ExportLoa`;
        // }
        // else {
        //   this.APIURL =
        //   `/rfq/Suppliers/Export/Loa/${action?.loaId}?fileName=${action?.fileName}`;
        // }
        return this.serviceMiddleware.exportFilteredList(this.APIURL, action.payload).pipe(
          map(
            (dataResponse: any) =>
              new ExportLOAPdfSuccess(
                dataResponse,
                action.payload.fileName
              )
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ExportLOAPdfError(errorResponse))
          )
        );
      })
    );
  });


  //////////////////////////////////////////////////////MASSIVE UPLOAD///////////////////////////////////////

  checkIsActiveFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CHECK_IS_ACTIVE_FILE),
      switchMap((action: CheckIsActiveFile) => {

        this.APIURL = '/admin/mass/isActiveFile';

        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new CheckIsActiveFileSuccess(dataResponse)),
          catchError((errorResponse: any) =>
            of(new CheckIsActiveFileError(errorResponse))
          )
        );
      })
    );
  });

  discardActiveFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.DISCARD_ACTIVE_FILE),
      switchMap((action: DiscardActiveFile) => {

        this.APIURL =
          `/admin/mass/discardActiveFile/${action.fileId}`;
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new DiscardActiveFileSuccess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new DiscardActiveFileError(errorResponse))
          )
        );
      })
    );
  });


  startUploadFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.START_UPLOAD_FILE),
      switchMap((action: StartUploadFile) => {

        this.APIURL = `/admin/mass/upload/${action.fileType}`;
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new StartUploadFileSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new StartUploadFileError(errorResponse))
            )
          );
      })
    );
  });

  mapUploadedFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MAP_UPLOADED_FILE),
      switchMap((action: MapUploadedFile) => {
        this.APIURL = `/admin/mass/read/type/${action.fileType}/file/${action.fileId}`;
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new MapUploadedFileSuccess(dataResponse)
          ),
          catchError((errorResponse: any) =>
            of(new MapUploadedFileError(errorResponse))
          )
        );
      })
    );
  });

  validateUploadedFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.VALIDATE_UPLOADED_FILE),
      switchMap((action: ValidateUploadedFile) => {
        const request: IMapColumnsRequest = {
          cmMappedColumns: action?.mappedColumns || [],
          fileId: action?.fileId || 0,
          startRow: action?.startRow || 0
        };
        return this.serviceMiddleware
          .getList(`/admin/mass/normalize/type/${action.fileType}`, request)
          .pipe(
            map(
              (dataResponse: any) => new ValidateUploadedFileSuccess(dataResponse)
            ),
            catchError((errorResponse: any) =>
              of(new ValidateUploadedFileError(errorResponse))
            )
          );
      })
    );
  });

  exportValidatedFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_VALIDATED_UPLOADED_FILE),
      switchMap((action: ExportValidatedFile) => {
        this.APIURL = `/admin/mass/normalize/exportData/type/${action?.fileType}/file/${action?.fileId}/true`
        action?.option;
        return this.serviceMiddleware.exportList(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new ExportValidatedFileSuccess(dataResponse, action.fileName)
          ),
          catchError((errorResponse: any) =>
            of(new ExportValidatedFileError(errorResponse))
          )
        );
      })
    );
  });

  submitUploadedFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SUBMIT_UPLOADED_FILE),
      switchMap((action: SubmitUploadedFile) => {
        this.APIURL = `/admin/mass/save/file/${action?.fileId}/type/${action?.fileType}`;

        return this.serviceMiddleware.AddRecord(this.APIURL, {}).pipe(
          map((dataResponse: any) => new SubmitUploadedFileSuccess(dataResponse)),
          catchError((errorResponse: any) =>
            of(new SubmitUploadedFileError(errorResponse))
          )
        );
      })
    );
  });
  /////////////////////////////////////////GRIDS ACTIONS/////////////////////////////////////////////////////
 
// delete record 
DeleteGridRecord$ = createEffect(() => {
  var cmObject:IContractManfacturer ;  
  return this.actions$.pipe(
    ofType(ActionTypes.DELETE_RECORD),
    switchMap((action: DeleteRecord) => {
      switch (action.gridType) {
        case GridDataTypes.CMSITES:
          this.APIURL = '/admin/site/cm/delete/'
          +action.payload.contractManufacturerId+'/'+action.payload.cmSiteName;
          break;
        case GridDataTypes.COMMODITIES:
          this.APIURL = '/admin/commodity/owner/delete/'+action.payload.commodityId;
          break;
        case GridDataTypes.CUSTOMERSITES:
          this.APIURL = '/admin/ownersite/'+action.payload.ownerSiteId;
          break;
        case GridDataTypes.DIVISIONS:
          this.APIURL = '/admin/division/'+action.payload.divisionId;
          break;
      }
      return this.serviceMiddleware
        .DeleteRecord(this.APIURL)
        .pipe(
          map(
            (dataResponse: any) => new DeleteRecordSuccess(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new DeleteRecordError(errorResponse))
          )
        );
      })
    );
  });

  //add or edit 
  AddEditGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.ADD_EDIT_RECORD),
      switchMap((action: AddEditRecord) => {
        switch (action.gridType) {
          case GridDataTypes.CMSITES:
            if (action?.apiType == ApiType.ADD) {
              this.APIURL = '/admin/site/cm/add';
            } else if (action?.apiType == ApiType.EDIT) {
              this.APIURL = '/admin/site/cm/update';
            }
            break;
          case GridDataTypes.COMMODITIES:
              this.APIURL = '/admin/commodities';
            break;
          case GridDataTypes.CUSTOMERSITES:
            this.APIURL = '/admin/ownersite';
            break;
          case GridDataTypes.DIVISIONS:
              this.APIURL = '/admin/division';
            break;
        }
        if (action?.apiType == ApiType.ADD) {
          return this.serviceMiddleware
            .AddRecord(this.APIURL, action.payload)
            .pipe(
              map((dataResponse: any) => new AddEditRecordSuccess(dataResponse)),
              catchError((errorResponse: HttpErrorResponse) =>
                of(new AddEditRecordError(errorResponse))
              )
            );
        }
        return this.serviceMiddleware
          .EditRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new AddEditRecordSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new AddEditRecordError(errorResponse))
            )
          );

      })
    );
  });

  AddEditGridCommodity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.ADD_EDIT_COMMODITY),
      switchMap((action: AddEditCommodity) => {
              this.APIURL = '/admin/commodities';
        if (action?.apiType == ApiType.ADD) {
          return this.serviceMiddleware
            .AddRecord(this.APIURL, action.payload)
            .pipe(
              map((dataResponse: any) => new AddEditCommoditySuccess(dataResponse)),
              catchError((errorResponse: HttpErrorResponse) =>
                of(new AddEditCommodityError(errorResponse))
              )
            );
        }
        return this.serviceMiddleware
          .EditRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new AddEditCommoditySuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new AddEditCommodityError(errorResponse))
            )
          );

      })
    );
  });
}

