import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UploadService } from 'src/app/core/upload/upload.service';
import { ServiceMiddleware } from 'src/app/core/upload/service.middleware/api.services.middleware';
import {
  ActionTypes,
  UploadReviewData,
  UploadReviewDataError,
  UploadReviewDataSuccess,
  TicketRequestData,
  TicketRequestDataError,
  TicketRequestDataSuccess,
  TicketHistoryData,
  TicketHistoryDataError,
  TicketHistoryDataSuccess,
  ExportNormalizedGridData,
  ExportNormalizedGridDataError,
  ExportNormalizedGridDataSucees,
  MappedData,
  MappedDataError,
  MappedDataSuccess,
  GetFileStatus,
  GetFileStatusError,
  GetFileStatusSuccess,
  DiscardTicket,
  UPLOAD_FILE,
  DiscardTicketError,
  DiscardTicketSucess,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  SubmitTicketFileError,
  SubmitTicketFileSuccess,
  SubmitTicketFile,
  ExportPriceReview,
  ExportPriceReviewSuccess,
  ExportPriceReviewError,
  CloseTicket,
  CloseTicketSuccess,
  CloseTicketError,
  GetSupplierGridData,
  GetSupplierGridDataSuccess,
  GetSupplierGridDataError,
  ExportQuotePartsDetailsGridData,
  ExportQuotePartsDetailsGridDataSuccess,
  ExportQuotePartsDetailsGridDataError,
  ExportSupplierQuotesGridData,
  ExportSupplierQuotesGridDataSuccess,
  ExportSupplierQuotesGridDataError,
  ResponseUploadFile,
  ResponseUploadFileError,
  ResponseUploadFileSuccess,
  DiscardResponse,
  DiscardResponseError,
  DiscardResponseSucess,
  GetLastResponseFileStatus,
  GetLastResponseFileStatusError,
  GetLastResponseFileStatusSuccess,
  UploadResponseReviewData,
  UploadResponseReviewDataSuccess,
  UploadResponseReviewDataError,
  MappedSupplierData,
  MappedSupplierDataSuccess,
  MappedSupplierDataError,
  ExportSuppNormalizedGridData,
  ExportSuppNormalizedGridDataSucees,
  ExportSuppNormalizedGridDataError,
  SubmitSupplierFile,
  SubmitSupplierFileError,
  SubmitSupplierFileSuccess,
  GetSupplierAwardedQuotesGridData,
  GetSupplierAwardedQuotesGridDataSuccess,
  GetSupplierAwardedQuotesGridDataError,
  ExportSupplierAwardedQuotesGridData,
  ExportSupplierAwardedQuotesGridDataError,
  ExportSupplierAwardedQuotesGridDataSuccess,
  ExportAwardedQuotePartsDetailsGridData,
  ExportAwardedQuotePartsDetailsGridDataSuccess,
  ExportAwardedQuotePartsDetailsGridDataError,
  UpdateAwardedQuotePartsAckStatus,
  UpdateAwardedQuotePartsAckStatusSuccess,
  UpdateAwardedQuotePartsAckStatusError,
  GetUpdateExistingFileStatus,
  GetUpdateExistingFileStatusSuccess,
  GetUpdateExistingFileStatusError,
} from './upload.action';
import {
  TicketMapFilter,
  TicketMapSort,
} from 'src/app/core/upload/history/history.mapper';
import { HttpErrorResponse } from '@angular/common/http';
import { gridRequest } from 'src/app/core/grid.request';
import { mapFilter, MapMultipleFilter, mapSort } from 'src/app/core/utils';
import { returnGridData } from '../pages/quote/utils/responsegriddata';
import { QuoteService } from 'src/app/core/Admin/quotes/quote.service';
import { MappedSupplierDataRequest } from 'src/app/core/upload/upload.request';
import { fileType, UpdateExistingType } from 'src/app/core/upload/upload.interface';

@Injectable({
  providedIn: 'root',
})
export class UploadEffects {
  APIURL: string;
  constructor(
    private actions$: Actions,
    private uploadService: UploadService,
    private serviceMiddleware: ServiceMiddleware,
    private quoteService: QuoteService
  ) { }

  getUploadReviewGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.UPLOAD_REVIEW_GRID_DATA),
      switchMap((action: UploadReviewData) => {
        if (action.fileType === fileType.ENGINEER) {
          this.APIURL =
            '/cmt/engbom/readEngBomFile/' + action?.ticketId + '';
        }
        else if(action.fileType === fileType.COMPONENT){
          this.APIURL =
          '/cmt/PartsComponent/readUploadedDataFile/' + action?.file + '';
        }
        else if(action.fileType === fileType.CBOM){
          this.APIURL =
          '/cmt/cbomParts/readUploadedDataFile/' + action?.file + '';
        }
        else {
          this.APIURL =
            '/cmt/CmtProcessing/readTicketFile/ticket/' + action?.ticketId + '';
        }

        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new UploadReviewDataSuccess(dataResponse)),
          catchError((errorResponse: any) =>
            of(new UploadReviewDataError(errorResponse))
          )
        );
      })
    );
  });

  geTicketRequestCardData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.TICKET_REQUEST_DATA),
      switchMap((action: TicketRequestData) => {
        this.APIURL = '/cmt/ticket/' + action.status + '';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map(
            (dataResponse: any) => new TicketRequestDataSuccess(dataResponse)
          ),
          catchError((errorResponse: any) =>
            of(new TicketRequestDataError(errorResponse))
          )
        );
      })
    );
  });

  getDiscardTiecketresponse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.DISCARD_TICKET),
      switchMap((action: DiscardTicket) => {
        if(action.fileType == UpdateExistingType.CBOM || action.fileType == UpdateExistingType.COMPONENT){
          this.APIURL =
          '/cmt/PartsComponent/discardActiveFile/' + action.ticketId + '';
        }else{ 
          this.APIURL =
          '/cmt/CmtProcessing/discardActiveTicket/' + action.ticketId + '';
        }
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new DiscardTicketSucess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new DiscardTicketError(errorResponse))
          )
        );
      })
    );
  });

  uploadTicketFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.UPLOAD_FILE),
      switchMap((action: UPLOAD_FILE) => {
        if (action.fileType === fileType.ENGINEER) {
          this.APIURL = '/cmt/engbom/upload';
        }else if(action.fileType === fileType.COMPONENT){
          this.APIURL = '/cmt/PartsComponent/uploadFile';
        }else if(action.fileType === fileType.CBOM) {
          this.APIURL = '/cmt/cbomParts/uploadFile';
        }
        else {
          this.APIURL = '/cmt/CmtProcessing/ticket/uploadTicketFile';
        }
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new UPLOAD_FILE_SUCCESS(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new UPLOAD_FILE_ERROR(errorResponse))
            )
          );
      })
    );
  });

  geTicketHistoryData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.TICKET_HISTORY_DATA),
      switchMap((action: TicketHistoryData) => {
        this.APIURL = '/cmt/ticket/history';
        const request = gridRequest(
          action?.page,
          action?.size,
          TicketMapSort(action?.sort),
          TicketMapFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map(
            (dataResponse: any) => new TicketHistoryDataSuccess(dataResponse)
          ),
          catchError((errorResponse: any) =>
            of(new TicketHistoryDataError(errorResponse))
          )
        );
      })
    );
  });

  geTicketStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.VALIDATE_FILE_STATUS),
      switchMap((action: GetFileStatus) => {
        // if( action.fileType == UpdateExistingType.COMPONENT || action.fileType == UpdateExistingType.CBOM){
        //   this.APIURL =
        //   '/cmt/PartsComponent/isActiveFile';
        // }
        //  else if (action.fileType == UpdateExistingType.CBOM) {
        //   this.APIURL =
        //   '/cmt/cbomParts/isActiveFile';
        // }
        // else{ 
          this.APIURL =
          '/cmt/CmtProcessing/ticket/isActiveFile/' + action.ticketID + '';
        // }

        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new GetFileStatusSuccess(dataResponse)),
          catchError((errorResponse: any) =>
            of(new GetFileStatusError(errorResponse))
          )
        );
      })
    );
  });

  getUpdateExistingFileStatus= createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_UPDATE_EXISTING_FILE_STATUS),
      switchMap((action: GetUpdateExistingFileStatus) => {
          this.APIURL =
          '/cmt/PartsComponent/isActiveFile';

        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new GetUpdateExistingFileStatusSuccess(dataResponse)),
          catchError((errorResponse: any) =>
            of(new GetUpdateExistingFileStatusError(errorResponse))
          )
        );
      })
    );
  });
  exportNormalizedGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_NORMALIZED_GRID_DATA),
      switchMap((action: ExportNormalizedGridData) => {
        if (action.fileId && (action.fileType == ''||action.fileType===undefined)) {
          this.APIURL = '/cmt/engbom/export/' + action.fileId +'/' +
          action?.option;
        }
       else if (action.fileId && action?.fileType ==  UpdateExistingType.CBOM) {
          this.APIURL = '/cmt/cbomParts/exportUpdateFile/' + action.fileId +'/' +
          action?.option;
        }
        else if (action.fileId && action?.fileType ==  UpdateExistingType.COMPONENT) {
          this.APIURL = '/cmt/PartsComponent/exportUpdateFile/' + action.fileId +'/' +
          action?.option;
        }
        else {
          this.APIURL =
            '/cmt/CmtProcessing/ticket/export/' +
            action?.ticketId +
            '/' +
            action?.option;
        }
        return this.serviceMiddleware.exportList(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new ExportNormalizedGridDataSucees(dataResponse, action.fileName)
          ),
          catchError((errorResponse: any) =>
            of(new ExportNormalizedGridDataError(errorResponse))
          )
        );
      })
    );
  });

  sendMappedData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MAPPED_GRID_DATA),
      switchMap((action: MappedData) => {
        if (action?.fileType === fileType.ENGINEER) {
          this.APIURL = '/cmt/engbom/normalize';
        }
        else if(action?.fileType === fileType.COMPONENT){
         action.ticketId=1;
          this.APIURL = '/cmt/PartsComponent/normalize';
        }
        else if(action?.fileType === fileType.CBOM){
         action.ticketId=2;
          this.APIURL = '/cmt/cbomParts/normalize';
        }
        else {
          this.APIURL = '/cmt/CmtProcessing/ticket/normalize';
        }
        return this.serviceMiddleware
          .sendMappedColumnsData(action?.mappedColumns, action?.startRow,this.APIURL,action?.ticketId)
          .pipe(
            map((dataResponse: any) => new MappedDataSuccess(dataResponse)),
            catchError((errorResponse: any) =>
              of(new MappedDataError(errorResponse))
            )
          );
      })
    );
  });

  submitTicketFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SUBMIT_TICKET_FILE),
      switchMap((action: SubmitTicketFile) => {
        if(action.fileType==fileType.CBOM){
          this.APIURL='/cmt/cbomParts/updateData/'+action.fileId;
        }
        else if(action.fileType==fileType.COMPONENT){
          this.APIURL='/cmt/PartsComponent/updateData/'+action.fileId;
        }
        else{
          this.APIURL='';
        }
        return this.uploadService.submitTicketFile(this.APIURL,action?.ticketId).pipe(
          map((dataResponse: any) => new SubmitTicketFileSuccess(dataResponse)),
          catchError((errorResponse: any) =>
            of(new SubmitTicketFileError(errorResponse))
          )
        );
      })
    );
  });

  downloadPriceReviewFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_COMPONENT_PRICE_REVIEW),
      switchMap((action: ExportPriceReview) => {
        this.APIURL = '/cmt/ticket/download/' + action?.ticketId;
        return this.serviceMiddleware.exportList(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new ExportPriceReviewSuccess(dataResponse, action?.fileName)
          ),
          catchError((errorResponse: any) =>
            of(new ExportPriceReviewError(errorResponse))
          )
        );
      })
    );
  });

  closePriceReview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CLOSE_TICKET),
      switchMap((action: CloseTicket) => {
        return this.uploadService.closeTicketFile(action?.ticketId).pipe(
          map((dataResponse: any) => new CloseTicketSuccess(dataResponse)),
          catchError((errorResponse: any) =>
            of(new CloseTicketError(errorResponse))
          )
        );
      })
    );
  });

  /*----------------------------supplier-----------------------------*/
  getSupplierGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SUPPLIER_LOAD_DATA_GRID),
      switchMap((action: GetSupplierGridData) => {
        this.APIURL = '/rfq/Suppliers/GetSupplierQuotes';
        const request = gridRequest(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          mapFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map(
            (dataResponse: any) => new GetSupplierGridDataSuccess(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetSupplierGridDataError(errorResponse))
          )
        );
      })
    );
  });

  getSupplierAwardedQuotesGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID),
      switchMap((action: GetSupplierAwardedQuotesGridData) => {
        this.APIURL = '/rfq/Suppliers/GetSupplierAwardedQuotes';
        const request = gridRequest(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map(
            (dataResponse: any) => new GetSupplierAwardedQuotesGridDataSuccess(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetSupplierAwardedQuotesGridDataError(errorResponse))
          )
        );
      })
    );
  });

  UploadResponseFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SUPPLIER_UPLOAD_FILE),
      switchMap((action: ResponseUploadFile) => {
        this.APIURL =
          '/rfq/Suppliers/UploadResponseFile' + '/' + action.quoteId;
        return this.serviceMiddleware
          .getList(this.APIURL, action.responseFile)
          .pipe(
            map(
              (dataResponse: any) => new ResponseUploadFileSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ResponseUploadFileError(errorResponse))
            )
          );
      })
    );
  });
  exportQuotePartsDetailsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA),
      switchMap((action: ExportQuotePartsDetailsGridData) => {
        this.APIURL = `/rfq/Suppliers/Export/QuotePartsDetails/${action?.quoteId}?fileName=${action?.fileName}`;
        return this.serviceMiddleware.exportList(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new ExportQuotePartsDetailsGridDataSuccess(
                dataResponse,
                action.fileName
              )
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ExportQuotePartsDetailsGridDataError(errorResponse))
          )
        );
      })
    );
  });
  exportAwardedQuotePartsDetailsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA),
      switchMap((action: ExportAwardedQuotePartsDetailsGridData) => {
        this.APIURL = `/rfq/Suppliers/Export/ExportAwardedQuotePartsDetails/${action?.quoteId}?fileName=${action?.fileName}`;
        return this.serviceMiddleware.exportList(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new ExportAwardedQuotePartsDetailsGridDataSuccess(
                dataResponse,
                action.fileName
              )
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ExportAwardedQuotePartsDetailsGridDataError(errorResponse))
          )
        );
      })
    );
  });
  exportSupplierQuotesData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_SUPPLIER_QUOTES_GRID_DATA),
      switchMap((action: ExportSupplierQuotesGridData) => {
        const request = gridRequest(
          action?.payload?.page,
          action?.payload?.size,
          mapSort(action?.payload?.sort),
          mapFilter(action?.payload?.filter)
        );

        this.APIURL = `/rfq/Suppliers/Export/SupplierQuotesList`;
        return this.serviceMiddleware
          .exportFilteredList(this.APIURL, request)
          .pipe(
            map(
              (data: ArrayBuffer) =>
                new ExportSupplierQuotesGridDataSuccess(data, action?.fileName)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ExportSupplierQuotesGridDataError(errorResponse))
            )
          );
      })
    );
  });
  exportSupplierAwardedQuotesData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA),
      switchMap((action: ExportSupplierAwardedQuotesGridData) => {
        const request = gridRequest(
          action?.payload?.page,
          action?.payload?.size,
          mapSort(action?.payload?.sort),
          mapFilter(action?.payload?.filter)
        );

        this.APIURL = `/rfq/Suppliers/Export/SupplierAwardedQuotesList`;
        return this.serviceMiddleware
          .exportFilteredList(this.APIURL, request)
          .pipe(
            map(
              (data: ArrayBuffer) =>
                new ExportSupplierAwardedQuotesGridDataSuccess(data, action?.fileName)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ExportSupplierAwardedQuotesGridDataError(errorResponse))
            )
          );
      })
    );
  });
  geResponseFileStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SUPPLIER_VALIDATE_FILE_STATUS),
      switchMap((action: GetLastResponseFileStatus) => {
        this.APIURL =
          '/rfq/Suppliers/GetLastResponseFileStatus/' + action.quoteId + '';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new GetLastResponseFileStatusSuccess(dataResponse)
          ),
          catchError((errorResponse: any) =>
            of(new GetLastResponseFileStatusError(errorResponse))
          )
        );
      })
    );
  });
  DiscardResponseFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SUPPLIER_DISCARD_FILE),
      switchMap((action: DiscardResponse) => {
        this.APIURL =
          '/rfq/Suppliers/DiscardResponseFile';
        return this.serviceMiddleware.EditRecord(this.APIURL, action.fileId).pipe(
          map((dataResponse: any) => new DiscardResponseSucess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new DiscardResponseError(errorResponse))
          )
        );
      })
    );
  });
  getSupplierUploadReviewGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SUPPLIER_UPLOAD_REVIEW_GRID_DATA),
      switchMap((action: UploadResponseReviewData) => {
        this.APIURL =
          '/rfq/Suppliers/MapResponseFile/' +
          action?.fileId +
          '/' +
          action?.tempResponseId +
          '/' +
          action?.QuoteId;
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new UploadResponseReviewDataSuccess(dataResponse)
          ),
          catchError((errorResponse: any) =>
            of(new UploadResponseReviewDataError(errorResponse))
          )
        );
      })
    );
  });
  sendSupplierMappedData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SUPPLIER_MAPPED_GRID_DATA),
      switchMap((action: MappedSupplierData) => {
        const request = MappedSupplierDataRequest(
          action?.mappedColumns,
          action?.startRow,
          action?.fileId,
          action?.tempResponseId,
          action?.quoteId
        );
        return this.serviceMiddleware
          .getList('/rfq/Suppliers/normalize', request)
          .pipe(
            map(
              (dataResponse: any) => new MappedSupplierDataSuccess(dataResponse)
            ),
            catchError((errorResponse: any) =>
              of(new MappedSupplierDataError(errorResponse))
            )
          );
      })
    );
  });
  submitSupplierFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SUBMIT_SUPPLIER_FILE),
      switchMap((action: SubmitSupplierFile) => {
        return this.serviceMiddleware
          .AddRecord(
            '/rfq/Suppliers/CreateSupplierResponse',
            action?.supplierResponse
          )
          .pipe(
            map(
              (dataResponse: any) => new SubmitSupplierFileSuccess(dataResponse)
            ),
            catchError((errorResponse: any) =>
              of(new SubmitSupplierFileError(errorResponse))
            )
          );
      })
    );
  });
  exportSuppNormalizedGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_SUPPLIER_NORMALIZED_GRID_DATA),
      switchMap((action: ExportSuppNormalizedGridData) => {
        this.APIURL =
          '/rfq/Suppliers/Export/normalizedResponse/' +
          action?.tempResId +
          '/' +
          action?.option;
        return this.serviceMiddleware.exportList(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new ExportSuppNormalizedGridDataSucees(
                dataResponse,
                action.fileName
              )
          ),
          catchError((errorResponse: any) =>
            of(new ExportSuppNormalizedGridDataError(errorResponse))
          )
        );
      })
    );
  });
  updateAwardedQuotePartsAckStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS),
      switchMap((action: UpdateAwardedQuotePartsAckStatus) => {
        this.APIURL =
          '/rfq/Suppliers/UpdateAwardedQuotePartsAckStatus/' +
          action?.quoteId +
          '/' +
          action?.status;
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new UpdateAwardedQuotePartsAckStatusSuccess(
                dataResponse
              )
          ),
          catchError((errorResponse: any) =>
            of(new UpdateAwardedQuotePartsAckStatusError(errorResponse))
          )
        );
      })
    );
  });
}
