import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import { ICrossData, ICrossSearch } from './cross-search.interfaces';

export function flatCrossSearchData(data: ICrossData): ICrossSearch[] {
  let flattedData: ICrossSearch[] = [];
  data?.CrossDto.forEach((element) => {
    var elem: ICrossSearch = {
      CrossID: element.CrossID,
      PlName:element.PlName,
      PartNumber: element.PartNumber,
      Manufacturer: element.Manufacturer,
      Lifecycle: element.Lifecycle,
      Datasheet: element.Datasheet,
      Description: element.Description,
      RoHSStatus: element.RoHSStatus,
      CrossPartNumber: element.CrossPartNumber,
      CrossManufacturer: element.CrossManufacturer,
      CrossLifecycle: element.CrossLifecycle,
      CrossRoHSStatus: element.CrossRoHSStatus,
      Type: element.Type,
      MPartNumber: data.ReqPartNumber,
      MManfacturer: data.ReqManufacturer,
    } as ICrossSearch;
    flattedData.push(elem);
  });
  return flattedData;
}
