<div class="part-details-content">
    <div class="row">
        <div class="col-3 main-info">
            <h4>
                MPN
                <span class="value d-block">{{MPN}}
                </span>
            </h4>
            <h4>
                manufacturer
                <span class="value d-block">{{Manufacturer}} </span>
            </h4>
            <h4>
                description
                <span class="value d-block">{{Description}}</span>
            </h4>
        </div>
        <div class="col-9">
            <div class="details">
                <h4 class="details-tilte">part data</h4>
                <div>
                    <div class="row">
                        <div class="col-4">
                            <div class="row inner-row" *ngFor="let item of (PartDataDetails| keyvalue ) | slice:0:11 ;">
                                <h5 class="col-6">{{item.key}}</h5>
                                <div class="col-6"><span class="value d-block">{{item.value}}</span></div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row inner-row"
                                *ngFor="let item of (PartDataDetails| keyvalue ) | slice:11:22 ;">
                                <h5 class="col-6">{{item.key}}</h5>
                                <div class="col-6"><span class="value d-block">{{item.value}}</span></div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row inner-row"
                                *ngFor="let item of (PartDataDetails| keyvalue ) | slice:22:33 ;">
                                <ng-container *ngIf="item.key !== 'Where Used'; else elseBlock">
                                    <h5 class="col-6">{{item.key}}</h5>
                                    <div class="col-6"><span class="value d-block">{{item.value}}</span></div>
                                </ng-container>

                                <ng-template #elseBlock>
                                    <h5 class="col-6">{{item.key}}</h5>
                                    <div class="col-6">
                                          <!-- <span
                                        class="eye-icon k-icon k-i-eye flex-right"
                                        style="text-align: end; display: block;"
                                      ></span> -->
                                      <kendo-dropdownbutton style="text-align: end; display: block; float:right;" [data]="whereUsedDataList" [iconClass]="'k-i-preview
                                      k-i-eye
                                      '">
                                      </kendo-dropdownbutton>
                                    </div>
                                </ng-template>
                              
                            </div>
                            <div class="row inner-row" *ngIf="seCrossesDetails">
                                <h5 class="col-6">Crosses</h5>
                                <div class="col-6"><span style="float: right;"
                                    class="eye-icon k-icon k-i-eye d-block" (click)="openCrossDetailsPopup()"
                                  ></span></div>

                                </div>
                        </div>
                    </div>
                    <div class="row sperateror">
                        <div class="col-6">
                            <h4 class="details-tilte">lifecycle & risk & summary</h4>
                            <div class="row inner-row" *ngFor="let item of (lifeCycleRiskSummaryDetails| keyvalue )">
                                <h5 class="col-6">{{item.key}}</h5>
                                <div class="col-6"><span class="value d-block">{{item.value}}</span></div>
                            </div>
                        </div>
                        <div class="col-6">
                            <h4 class="details-tilte">price History</h4>
                            <div class="row inner-row" *ngFor="let item of (priceHistoryDetails| keyvalue )">
                                <h5 class="col-6">{{item.key}}</h5>
                                <div class="col-6"><span class="value d-block">{{item.value}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="opened">
    <app-new-window
      [title]="title"
      [openedComponent]="openedComponent"
      [data]="selectedData"
      (closeAction)="toggleClosed()"
      [width]="700"
      height="430"
      [hasSubmitBtn]="false"
    >
    </app-new-window>
  </ng-container>