import { isDate } from 'lodash';
import {
  actionMenu,
  ColumnType,
  GridHeaderData,
} from 'src/app/shared/shared.interface';

export const headers: GridHeaderData[] = [
  {
    field: 'quoteName',
    title: 'Quote Name',
    type: ColumnType.LINKWITHNAVIGATIONROUTE,
    filterable: true,
    filterColName:'quoteName',
  },
  {
    field: 'noOfParts',
    title: '# of Parts',
    filterable: true,
    filterColName: 'noOfParts',

  },
  {
    field: 'rfqInDate',
    title: 'RFQ in Date',
    filterable: true,
    type: ColumnType.DATE,
    isDate:true,
    filterColName: 'rfqInDate',
  },
  {
    field: 'dueDate',
    title: 'Due Date',
    filterable: true,
    type: ColumnType.DATE,
    isDate:true,
    filterColName: 'dueDate',
  },
  {
    field: 'operationStatus',
    title: 'Status',
    filterable: true,
    filterColName: 'operationStatus',

  },
  {
    field: 'priority',
    title: 'priority',
    filterable: true,
    filterColName: 'priority',

  },
  {
    field: 'minPrice',
    title: 'Min. Price',
    filterable: true,
    filterColName: 'minPrice',
  },
  {
    field: 'maxPrice',
    title: 'Max. Price',
    filterable: true,
    filterColName: 'maxPrice',
  },
  {
    field: 'estimateTotalPrice',
    title: 'Est. Total Price',
    filterable: true,
    filterColName: 'estimateTotalPrice',
  },
  {
    field: 'minLeadTime',
    title: 'Min. LT (Days)',
    filterable: true,
    filterColName: 'minLeadTime',
  },
  {
    field: 'maxLeadTime',
    title: 'Max. LT (Days)',
    filterable: true,
    filterColName: 'maxLeadTime',
  },
  {
    field: 'noOfResponses',
    title: '# of Responses',
    filterable: true,
    filterColName: 'noOfResponses',
  },
  {
    actions: [
      {
        componentData: {},
        actionType: actionMenu.download,
      },
    ],
    title: 'Price Table',
    field: 'Actions',
  },
];
export const priceHistoryHeaders: GridHeaderData[] = [
  {
    field: 'rowId',
    title: 'Row No',
    filterable: false,
    sortable: false,
  },
  
  {
    field: 'cpn',
    title: 'CPN',
    filterable: false,
    sortable: false,
    filterColName:'cpn',
  },
  {
    field: 'mpn',
    title: 'MPN',
    filterable: false,
    sortable: false,
    filterColName:'mpn',

  },
  {
    field: 'mfr',
    title: 'Manufacturer',
    filterable: false,
    sortable: false,
    filterColName:'mfr',

  },
  {
    field: 'rfqTo',
    title: 'RFQ To',
    filterable: false,
    sortable: false,
    filterColName:'rfqTo',
  },
  {
    field: 'responderTypeName',
    title: 'Responder Type',
    filterable: false,
    sortable: false,
    filterColName:'responderTypeName',
  },
  {
    field: 'rfqStatus',
    title: 'Status',
    filterable: false,
    sortable: false,
    filterColName:'rfqStatus',

  },
  {
    field: 'price',
    title: 'Price',
    filterable: false,
    sortable: false,
    filterColName:'price',
    getCellValue: (data: any) => {
       
      return data?.price != null ? '$' + data?.price.toString() : null;
    },
  },
  {
    field: 'seAveragePrice',
    title: 'SE AVG Price',
    filterable: false,
    filterColName:'seAveragePrice',
    sortable: false,
    getCellValue: (data: any) => {
      return data?.seAveragePrice != null ? '$' + data?.seAveragePrice.toString() : null;
    },
  },
  {
    field: 'seMinPrice',
    title: 'SE Min Price',
    filterColName:'seMinPrice',
    filterable: false,
    sortable: false,
    getCellValue: (data: any) => {
      return data?.seMinPrice != null ? '$' + data?.seMinPrice.toString() : null;
    },
  },
  {
    field: 'leadTime',
    title: 'Lead time (days)',
    filterColName:'leadTime',
    filterable: false,
    sortable: false,
  },
  {
    field: 'seMinLeadTime',
    title: 'SE Min LT',
    filterColName:'seMinLeadTime',
    filterable: false,
    sortable: false,
  },
  {
    field: 'seMaxLeadTime',
    title: 'SE Max LT',
    filterColName:'seMaxLeadTime',
    filterable: false,
    sortable: false,
  },
  {
    field: 'moq',
    title: 'MOQ',
    filterColName:'moq',
    filterable: false,
    sortable: false,
  },
  {
    field: 'lastResponseDate',
    title: 'Lasted Response',
    filterable: false,
    sortable: false,
    type: ColumnType.DATE,
    filterColName:'lastResponseDate',

    isDate:true,
     
  },
  {
    field: 'expirationDate',
    title: 'Expiration Date',
    filterable: false,
    sortable: false,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'expirationDate',
     
  },
  {
    field: 'rebate',
    title: 'Rebate',
    filterable: false,
    sortable: false,
    filterColName:'rebate',
  },
  {
    field: 'awarded',
    title: 'Awarded',
    filterable: false,
    sortable: false,
    filterColName:'awarded',

    getCellValue: (data: any) => {
      if (data?.awarded != null) {
        return data?.awarded ? 'Yes' : 'No';
      }
      return null;
    },
  },
  {
    field: 'awardedNotificationDate',
    title: 'Awarded Notification Date',
    filterable: false,
    sortable: false,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'awardedNotificationDate',
     
  },
  // {
  //   field: 'awardedByName',
  //   title: 'Awarded By',
  //   filterable: true,
  //   sortable: true,
  // },
  {
    field: 'ackStatus',
    title: 'ACK status',
    filterable: false,
    sortable: false,
    filterColName:'ackStatus',

  },
  {
    field: 'ackNotification',
    title: 'ACK Notifications',
    filterable: false,
    sortable: false,
    filterColName:'ackNotification',

  },
  {
    field: 'ackDate',
    title: 'ACK Date',
    filterable: false,
    sortable: false,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'ackDate',
     
  },
  // {
  //   field: 'canceledByName',
  //   title: 'Canceled by',
  //   filterable: true,
  //   sortable: true,
  // },
  {
    field: 'email',
    title: 'Ref. Email',
    filterable: false,
    sortable: false,
    filterColName:'email',

  },
];

export const QuoteDetailsHeaders: GridHeaderData[] = [
  {
    field: 'rowId',
    title: 'Row No',
    filterable: true,
    sortable: true,
    filterColName:'rowId',

  },
  {
    field: 'noOfResponses',
    title: 'No. responses',
    type: ColumnType.LINKWITHNAVIGATIONROUTE,
    filterable: true,
    sortable: true,
    filterColName:'noOfResponses',

  },
  {
    field: 'cpn',
    title: 'CPN',
    filterable: true,
    sortable: true,
    filterColName:'cpn',

  },
  {
    field: 'mpn',
    title: 'MPN',
    filterable: true,
    sortable: true,
    filterColName:'mpn',

  },
  {
    field: 'mfr',
    title: 'Manufacturer',
    filterable: true,
    sortable: true,
    filterColName:'mfr',

  },
  {
    field: 'rfqTo',
    title: 'RFQ To',
    filterable: true,
    sortable: true,
    filterColName:'rfqTo',

  },
  {
    field: 'responderTypeName',
    title: 'Responder Type',
    filterable: true,
    sortable: true,
    filterColName:'responderTypeName',

  },
  {
    field: 'rfqStatus',
    title: 'Status',
    filterable: true,
    sortable: true,
    filterColName:'rfqStatus',

  },
  {
    field: 'price',
    title: 'Price',
    filterable: true,
    sortable: true,
    filterColName:'price',

    getCellValue: (data: any) => {
      return data?.price != null ? '$' + data?.price.toString() : null;
    },
  },
  {
    field: 'seAveragePrice',
    title: 'SE AVG Price',
    filterable: true,
    sortable: true,
    filterColName:'seAveragePrice',

    getCellValue: (data: any) => {
      return data?.seAveragePrice != null ? '$' + data?.seAveragePrice.toString() : null;
    },
  },
  {
    field: 'seMinPrice',
    title: 'SE Min Price',
    filterable: true,
    sortable: true,
    filterColName:'seMinPrice',

    getCellValue: (data: any) => {
      return data?.seMinPrice != null ? '$' + data?.seMinPrice.toString() : null;
    },
  },
  {
    field: 'leadTime',
    title: 'Lead time (days)',
    filterable: true,
    sortable: true,
    filterColName:'leadTime',

  },
  {
    field: 'seMinLeadTime',
    title: 'SE Min LT',
    filterable: true,
    sortable: true,
    filterColName:'seMinLeadTime',

  },
  {
    field: 'seMaxLeadTime',
    title: 'SE Max LT',
    filterable: true,
    sortable: true,
    filterColName:'seMaxLeadTime',

  },
  {
    field: 'moq',
    title: 'MOQ',
    filterable: true,
    sortable: true,
    filterColName:'moq',

  },
  {
    field: 'lastResponseDate',
    title: 'Lasted Response',
    filterable: true,
    sortable: true,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'lastResponseDate',
     
  },
  {
    field: 'expirationDate',
    title: 'Expiration Date',
    filterable: true,
    sortable: true,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'expirationDate',
     
  },
  {
    field: 'rebate',
    title: 'Rebate',
    filterable: true,
    sortable: true,
    filterColName:'rebate',

  },
  {
    field: 'awarded',
    title: 'Awarded',
    filterable: true,
    sortable: true,
    filterColName:'awarded',

    getCellValue: (data: any) => {
      if (data?.awarded != null) {
        return data?.awarded ? 'Yes' : 'No';
      }
      return null;
    },
  },
  {
    field: 'awardedNotificationDate',
    title: 'Awarded Notification Date',
    filterable: true,
    sortable: true,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'awardedNotificationDate',
     
  },
  {
    field: 'awardedByName',
    title: 'Awarded By',
    filterable: true,
    sortable: true,
    filterColName:'awardedByName',
  },
  {
    field: 'ackStatus',
    title: 'ACK status',
    filterable: true,
    sortable: true,
    filterColName:'ackStatus',

  },
  {
    field: 'acknotification',
    title: 'ACK Notifications',
    filterable: true,
    sortable: true,
    filterColName:'acknotification',
    getCellValue: (data: any) => {
      if (data?.acknotification != null) {
        return data?.acknotification ? 'Yes' : 'No';
      }
      return null;
    },
  },
  {
    field: 'ackDate',
    title: 'ACK Date',
    filterable: true,
    sortable: true,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'ackDate',
     
  },
  {
    field: 'canceledByName',
    title: 'Canceled by',
    filterable: true,
    sortable: true,
    filterColName:'canceledByName',

  },
  {
    field: 'email',
    title: 'Ref. Email',
    filterable: true,
    sortable: true,
    filterColName:'email',

  },
];

export const Manheaders: GridHeaderData[] = [
  {
    field: 'manName',
    title: 'Manufacturer',
    filterable: true,
  },
  {
    field: 'statusName',
    title: 'Status',
    filterable: true,
  },
];
export const ManLOAheaders: GridHeaderData[] = [
  {
    field: 'contractManufacturerName',
    title: 'CM Manufacturer',
    filterable: true,
  },
];


export const Disheaders: GridHeaderData[] = [
  {
    field: 'distName',
    title: 'Distributor Name',
    filterable: true,
  },
  {
    field: 'Status',
    title: 'Type',
    filterable: true,
  },
];
export const Colheaders: GridHeaderData[] = [
  {
    field: 'columnName',
    title: 'Column Name',
    filterable: true,
  },
];
export const EngBomDetailsheaders: GridHeaderData[] = [
  {
    field: 'whereUsed',
    title: 'Where Used',
    filterable: false,
    sortable: false
  },
];
export function uploadedQuoteGridDDL(features: any = []): GridHeaderData[] {
  return [
    {
      field: 'UnMapped',
      title: 'UnMapped',
      required: false,
      index: -1,
    },
    ...features?.map((c: any) => {
      return {
        index: c.index,
        mappedIndexes: c.index != null ? [c.index] : [],
        field: c.columnId,
        title: c.featureName,
        required: c?.required,
      };
    }),
  ];
}

export function uploadedQuoteGridColumns(
  dynamicColumns: any = []
): GridHeaderData[] { 
  return [
    {
      field: 'row',
      title: 'Row',
      filterable: false,
      sortable: false,
      reorderable: false,
      dropdown: false,
      getCellValue: (data: any) => {
        return data?.rowIndex + 1;
      },
    },
    ...dynamicColumns?.map((c: any) => {
      return {
        field: `col${c.columnIndex}`,
        title: c.value,
        filterable: false,
        sortable: false,
        reorderable: false,
        dropdown: true,
        getCellValue: (data: any, column: any, columnIndex: any) => {
          if (data && data.cells && columnIndex) {
            return data.cells.find(
              (c: any) => c.columnIndex === columnIndex - 1
            )?.value;
          }
        },
        getSelectedValue: (data: any, columnIndex: any) => {
          if (data && columnIndex) {
            const value = data.find((c: any) => c.index === columnIndex - 1)
              ? data.find((c: any) => c.index === columnIndex - 1)
              : data.find((c: any) => c.index === -1);
            return value;
          }
        },
      };
    }),
  ];  
}
