import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUploadState } from 'src/app/core/upload/upload.interface';

export const getUploadState = createFeatureSelector<IUploadState>('upload');

export const normalizationResultSelector = createSelector(
  getUploadState,
  (state) => state?.normalizationResult
);

export const normalizationResultLoadingSelector = createSelector(
  getUploadState,
  (state) => {
    return {
      loading: state?.normalizationResult?.loading,
      isNormalized: state?.normalizationResult?.isNormalized,
    };
  }
);

export const currentActionSelector = createSelector(
  getUploadState,
  (state) => state?.currentAction
);

export const SupplierGridDataSelector = createSelector(
  getUploadState,
  (state) => state?.supplierGrid
);
export const SupplierGridFilterSelector = createSelector(
  getUploadState,
  (state) => state?.supplierGridFilter
);

export const SupplierGridSortSelector = createSelector(
  getUploadState,
  (state) => state?.supplierGridSort
);

export const SupplierAwardedQuotesGridDataSelector = createSelector(
  getUploadState,
  (state) => state?.supplierAwardedQuotesGrid
);
export const SupplierAwardedQuotesGridFilterSelector = createSelector(
  getUploadState,
  (state) => state?.supplierAwardedQuotesGridFilter
);

export const SupplierAwardedQuotesGridSortSelector = createSelector(
  getUploadState,
  (state) => state?.supplierAwardedQuotesGridSort
);
export const uploadReviewGridSelector = createSelector(
  getUploadState,
  (state) => state?.reviewGrid
);

export const ticketRequestSelector = createSelector(
  getUploadState,
  (state) => state?.requestGrid
);
export const validateTicketSelector = createSelector(
  getUploadState,
  (state) => state?.validateFile
);
export const updateExistingFileStatusSelector = createSelector(
  getUploadState,
  (state) => state?.updateExistingFileStatus
);
export const currentExistActionSelector = createSelector(
  getUploadState,
  (state) => state?.currentExistAction
);
export const uploadFileSelector = createSelector(
  getUploadState,
  (state) => state?.uploadFile
);
// get ticket type
export const getTicketTypeSelector = createSelector(
  getUploadState,
  (state) => state?.currentAction
);
export const ticketHistorySelector = createSelector(
  getUploadState,
  (state) => state?.historyGrid
);
export const discardSelector = createSelector(
  getUploadState,
  (state) => state?.discard
);
export const ticketFileSubmitedSelector = createSelector(
  getUploadState,
  (state) => state?.ticketFileSubmited
);
export const closeSelector = createSelector(
  getUploadState,
  (state) => state?.close
);
export const quotePartsDetailsSelector = createSelector(
  getUploadState,
  (state) => state?.quotePartsDetails
);
export const exportAwardedQuotePartsDetailsSelector = createSelector(
  getUploadState,
  (state) => state?.awardedQuotePartsDetails
);
export const exportSupplierGridDataSelector = createSelector(
  getUploadState,
  (state) => state?.supplierGrid?.export
);
export const exportSupplierAwardedGridDataSelector = createSelector(
  getUploadState,
  (state) => state?.supplierAwardedQuotesGrid?.export
);
export const uploadResponseFileSelector = createSelector(
  getUploadState,
  (state) => state?.supplierUploadFile
);
export const currentSupplierResponseActionSelector = createSelector(
  getUploadState,
  (state) => state?.currentSupplierAction
);

export const uploadSuppReviewGridSelector = createSelector(
  getUploadState,
  (state) => state?.supplierReviewGrid
);
export const validateResponseSelector = createSelector(
  getUploadState,
  (state) => state?.validateSupplierFile
);
export const normalizationSuppResSelector = createSelector(
  getUploadState,
  (state) => state?.normalizationSupplierResult
);

export const normalizationSuppResLoadingSelector = createSelector(
  getUploadState,
  (state) => {
    return {
      loading: state?.normalizationSupplierResult?.loading,
      isNormalized: state?.normalizationSupplierResult?.isNormalized,
    };
  }
);
export const supplierFileSubmitedSelector = createSelector(
  getUploadState,
  (state) => state?.supplierFileSubmitted
);

export const updateAwardedQuotePartsAckStatusSelector = createSelector(
  getUploadState,
  (state) => state?.updateAwardedQuoteAckStatus
);