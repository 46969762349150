import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  ICompanyType,
  IRoleList,
} from 'src/app/core/Admin/users/invited-users/invited-users.interfaces';
import {
  GetCompanyByTypeName,
  GetCompanyTypeList,
  GetRoleList,
  InvitedAdd,
  InvitedEdit,
  InvitedUsersLoadData,
  SetValidationError,
} from 'src/app/features/admin/stores/admin.action';
import {
  CompanySelector,
  CompanyTypeSelector,
  GetValidationErrorSelector,
  InvitedGridDataSelector,
  InvitedselectedFiltersSelector,
  RoleSelector
} from 'src/app/features/admin/stores/admin.selector';
import { CustomValidators } from 'src/app/shared/classes/custom-validator';
import { ProviderClass } from 'src/app/shared/classes/ProviderClass';
import { DialogShareService } from 'src/app/shared/components/Modals/dialog.service';
import { Dialog } from 'src/app/shared/models/model.interface';
import {
  actionComponentData,
  IAppState,
} from 'src/app/shared/shared.interface';

@Component({
  selector: 'app-add-invitation',
  templateUrl: './add-invitation.component.html',
  styleUrls: ['./add-invitation.component.scss'],
})
export class AddInvitationComponent implements OnInit, OnDestroy {
  public CompanyTypeId: any;
  public CompanyType: string;
  public CompanyTypeList: any;
   public CompanyList: any[] = [];
  saveData: boolean = false;
  IsDisabled: boolean = false;
 addInvitationForm:FormGroup;
 erorrMessage: string;
  
  public RolelistItems: IRoleList[] = [];
  // InvitedUserForm;
  public CompanyTypes: ICompanyType[] = [];
  emailError: string = '';
  companyTypeError: string = '';
  companyError: string = '';
  roleError: string = '';
  error: any;
  IsError: boolean = false;
  subscription: Subscription = new Subscription();
  Email: string = '';
  InvitationId: number = 0;
  roleName: string = '';
  roleId: any;
  companyTypeId: any;
  role: number = 0;
  companyId: number = 0;
  companyType: string = '';
  CompanyName: string = '';
  public gridFilters = {
    filter: undefined,
    sort: undefined,
    page: 0,
    pageSize: 10,
  };
  selectedData: actionComponentData = {};
  edit: boolean = false;
  Cardinal: string='Cardinal';
  IsSaveValid: boolean = false;

  constructor(private fb:FormBuilder,
    public dataIn: ProviderClass,
    private dialogService: DialogShareService,
    private store: Store<IAppState>
  ) {
    this.loadData();
  this.addInvitationForm=this.fb.group({
    email:['',CustomValidators.emailFormat],
    companyTypeId:[0,Validators.required],
    companyName:['',Validators.required],
    roleId:[0,Validators.required]

  });
  }
  hasError(ctrName:string,errorName:string):boolean{
   let res=this.addInvitationForm?.controls[ctrName]?.hasError(errorName);

  return res;
  }
  public CompanyTypeplaceHolder: ICompanyType = {
    companyTypeId: 0,
    companyTypeName: 'Select Company Type',
  };
  public RoleplaceHolder: IRoleList = {
    roleId: 0,
    roleName: 'Select Role',
  };


  ngOnInit(): void {
    this.selectedData = this.dataIn;
    if (this.selectedData?.data) {
      this.getEditData();
      this.store.dispatch(new GetRoleList(this.selectedData.data.companyTypeName));
      this.edit = true;
    }
    this.startSubscribtion();
  }
  startSubscribtion() {
    this.subscription.add(this.subscribeToDialog());
    this.subscription.add(this.subscribeCompanyTypeListItems());
    this.subscription.add(this.subscribeRoleListItems());
    this.subscription.add(this.subscribeCompanyListItems());
    this.subscription.add(this.subscribeToFilterSelector());
    this.subscription.add(this.subscribeToAddEditSelector());
    this.subscription.add(this.subscribTovalidation());
    this.subscription.add(this.subscribeToForm());
  }

  subscribeToForm(){
    this.addInvitationForm.statusChanges.subscribe(data=>{
      this.IsSaveValid = false;
    /*  if(data==='INVALID'){
        this.dialogService.sendMessage(Dialog.disable);
      }
      else if(data==='VALID'){
        this.dialogService.sendMessage(Dialog.enable);
      }*/
    })
  }
  subscribTovalidation() {
    this.store.select(GetValidationErrorSelector).subscribe((error) => {
      if (error?.error != undefined) {
        this.erorrMessage = error.error;
        this.IsError = error.isError;
       }
    });
  }
  subscribeToFilterSelector() {
    return this.store.select(InvitedselectedFiltersSelector).subscribe((data) => {
      if ((data: any) => data) {
        this.gridFilters.filter = data.filter;
        this.gridFilters.sort = data.sort;
        this.gridFilters.pageSize = data.pageSize;
        this.gridFilters.page = data.page;
      }
    });
  }
  getSelectedCompanyTypeItem($event: any) {
     this.CompanyList = [];
    this.CompanyName = '';
    this.companyId = $event.companyTypeId;
    this.companyType = $event.companyTypeName;
    this.role=0;
 
    if(this.companyType==='Wabtec'){
      this.CompanyName='wabtec';
      this.IsDisabled=true;
     }
      if (this.companyType.toUpperCase() === this.Cardinal.toUpperCase()) {
      this.CompanyName = 'Cardinal';
      this.IsDisabled = true;
    
    }
     else{
       this.IsDisabled=false;
       this.CompanyName='';
     }
    this.store.dispatch(new GetRoleList(this.companyType));

  }
  public itemDisabled(itemArgs: { dataItem: string; index: number }) {
    return itemArgs.index === 1; // disable the 3rd item
  }
  getSelectedRole($event: any) {
    this.role = $event.roleId;
  }
  getEditData() {
    (this.Email = this.selectedData.data.email),
      (this.InvitationId = this.selectedData.data.invitationId),
      (this.companyId = this.selectedData.data.companyTypeId),
      (this.role = this.selectedData.data.roleId),
      (this.CompanyName = this.selectedData.data.companyName);
      if(this.CompanyName==='wabtec'){
        this.IsDisabled=true;
      }
      if(this.CompanyName==='Cardinal'){
        this.IsDisabled=true;
      }
  }
  loadData() {
    this.store.dispatch(new GetCompanyTypeList());
      
  }
  subscribeToAddEditSelector() {
    this.store.dispatch(new SetValidationError(''));

    return this.store.select(InvitedGridDataSelector).subscribe((res: any) => {

      if ((res?.add?.invitationId || res?.edit?.invitationId) && this.saveData) {
        this.saveData = false;
        this.store.dispatch(
          new InvitedUsersLoadData(
            this.gridFilters.page,
            this.gridFilters.pageSize,
            this.gridFilters.filter,
            this.gridFilters.sort
          )
        );
        this.dialogService.sendMessage(Dialog.close);
      }

      else if (this.saveData) {
        this.store.select(GetValidationErrorSelector).subscribe((error) => {
          if (error.error != undefined) {
            this.error = error.error;
            this.IsError = error.isError;
          }
        });
      }
    });
  }
  subscribeCompanyTypeListItems() {
    return this.store.select(CompanyTypeSelector).subscribe((res) => {
      if (res?.length) {
        this.CompanyTypes = res;
        this.CompanyTypes = this.CompanyTypes.slice();
      }
    });
  }
  subscribeRoleListItems() {
    return this.store.select(RoleSelector).subscribe((res) => {
      if (res?.length===1) {
        this.RolelistItems = res;
        this.RolelistItems = this.RolelistItems.slice();
        this.role=res[0].roleId;
      }
      else if(res?.length>1){
        if(!this.edit){
        this.role=0;
      }
        this.RolelistItems = res;
        this.RolelistItems = this.RolelistItems.slice();
      }
    });
  }
  click(value: any) {
    if (value.length >= 3) {
      return this.store.dispatch(
        new GetCompanyByTypeName(this.companyId, value)
      );
    } else {
      this.CompanyList = [];
    }
  }
  subscribeCompanyListItems() {
    return this.store.select(CompanySelector).subscribe((res) => {
      if (res?.length) {
        this.CompanyList = res;
      }
    });
  }
  public valueChange(value: string): void {
    return this.store.dispatch(new GetCompanyByTypeName(this.companyId, value));
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  subscribeToDialog() {
    return this.dialogService.getMessage().subscribe((data) => {
      if (data.res == Dialog.submit) {
        this.selectedData = {};
        this.save();
      }
    });
  }

  save() {
    if (this.validate()) {
      this.saveData = true;
      if (this.edit) {
        this.store.dispatch(
          new InvitedEdit({
            invitationId: this.InvitationId,
            email: this.Email,
            companyTypeId: this.companyId,
            companyName: this.CompanyName,
            roleId: this.role,
          })
        );
      } else {
        this.store.dispatch(
          new InvitedAdd({
            email: this.Email,
            companyTypeId: this.companyId,
            companyName: this.CompanyName,
            roleId: this.role,
          })
        );
      }
    }
    else {
      this.store.dispatch(new SetValidationError(''));
      this.error = '';
      this.IsError = false;
      if(this.Email == ''){
      this.IsSaveValid = true;
      }    
    }
  }

  validate() {
    return this.addInvitationForm.valid;
  }
}
