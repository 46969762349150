import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { ITicketKendoUIGrid, IData } from './ticket.interfaces';

export function flatTicketData(data: IData[]): ITicketKendoUIGrid[] {
  let flattedData: ITicketKendoUIGrid[] = [];
  data?.forEach((element) => {
    var elem: ITicketKendoUIGrid = {
      TicketId: element?.ticketId,
      YearId: element?.year?.yearId,
      AssigneeId: element?.assignee?.userId,
      RequesterId: element?.requester?.userId,
      TicketTypeId: element?.ticketType?.ticketTypeId,
      Assignee: element?.assignee?.fullName,
      Requester: element?.requester?.fullName,
      Year: element?.year?.year,
      TicketType: element?.ticketType?.ticketType,
      TicketStatus: element?.ticketStatus?.ticketStatus,
      ContractManufacturerName:
        element.contractManufacturer?.contractManufacturerName,
      InsertionDate: element?.insertionDate,
      LastUpdateDate: element?.lastUpdateDate,
    };
    flattedData.push(elem);
  });
  return flattedData;
}

function convert(date: number[]): string {
  if (date == null) {
    return '';
  }
  return `${date[0]}/${date[1]}/${date[2]}`;
}

export function TicketMapSort(sort?: SortDescriptor[]) {
  var kendoSort = _.isArray(sort) ? sort[0] : sort;
  var dir = kendoSort?.dir === 'desc' ? 'desc' : 'asc';
  switch (kendoSort?.field) {
    case 'TicketType':
      return { columnName: 'ticketType.ticketType', direction: dir };
    case 'TicketStatus':
      return {
        columnName: 'ticketStatus.ticketStatus',
        direction: dir,
      };
    case 'Requester':
      return {
        columnName: 'requester.fullName',
        direction: dir,
      };
    case 'Assignee':
      return {
        columnName: 'assignee.fullName',
        direction: dir,
      };
    case 'Year':
      return {
        columnName: 'year.year',
        direction: dir,
      };
    case 'ContractManufacturerName':
      return {
        columnName: 'contractManufacturer.contractManufacturerName',
        direction: dir,
      };
      case 'InsertionDate':
        return {
          columnName: 'insertionDate',
          direction: dir,
        };
        case 'LastUpdateDate':
        return {
          columnName: 'lastUpdateDate',
          direction: dir,
        };
    default:
      return { columnName: kendoSort?.field, direction: dir };
  }
  // return { columnName: 'requester.fullName', direction: dir };
}

export function TicketMapFilter(gridFilters: any) {
  var kendoFilter = _.isArray(gridFilters) ? gridFilters[0] : gridFilters;
  var kendoFilters = _.isArray(kendoFilter?.filters)
    ? kendoFilter?.filters
    : kendoFilter;
  switch (kendoFilters?.field) {
    case 'requester.fullName':
      return [
        {
          columnName: 'requester.fullName',
          value: kendoFilters?.value,
        },
      ];
    default:
      return [];
  }
}
