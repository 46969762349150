import { Action, createAction } from "@ngrx/store";
import { HttpErrorResponse } from '@angular/common/http';
import { IUser } from "../user.interface";

export enum UserActionTypes {
  USER_LOGGED_IN = 'USER_LOGGED_IN',
  //USER_REGISTERED = 'USER_REGISTERED',
  USER_LOGGED_IN_COMPLETE = 'USER_LOGGED_IN_COMPLETE',
  USER_LOGGED_IN_FAILED = 'USER_LOGGED_IN_FAILED',
  USER_AFTER_LOGGED_IN = 'USER_AFTER_LOGGED_IN',
  USER_AFTER_LOGGED_IN_COMPLETE = 'USER_AFTER_LOGGED_IN_COMPLETE',
  USER_AFTER_LOGGED_IN_FAILED = 'USER_AFTER_LOGGED_IN_FAILED',
  RESET_USER = 'RESET USER',
  USER_ERROR = 'USER_ERROR',
  CLEAR_USER_ERROR = 'CLEAR_USER_ERROR',
  USER_LOGGED_OUT = 'USER_LOGGED_OUT',
  USER_LOGGED_OUT_COMPLETE = 'USER_LOGGED_OUT_COMPLETE',
  USER_LOGGED_OUT_FAILED = 'USER_LOGGED_OUT_FAILED',
  TOKEN_REFRESH = 'TOKEN_REFRESH',
  REFRESH_USER_PROFILE = 'REFRESH_USER_PROFILE',
  REFRESH_USER_PROFILE_SUCCESS = 'REFRESH_USER_PROFILE_SUCCESS',
  REDIRECT = 'REDIRECT',

  USER_FOTGET_PASSWORD='USER_FOTGET_PASSWORD',
  USER_FOTGET_PASSWORD_SUCCESS='USER_FOTGET_PASSWORD_SUCCESS',
  USER_FOTGET_PASSWORD_ERROR='USER_FOTGET_PASSWORD_ERROR',

  USER_RESET_PASSWORD = 'USER_PASSWORD_RESET',
  USER_RESET_PASSWORD_SUCCESS='USER_PASSWORD_RESET_SUCCESS',
  USER_RESET_PASSWORD_ERROR='USER_PASSWORD_RESET_ERROR',
  
  GET_USER_EMAIL_BY_CODE = 'GET_USER_EMAIL_BY_CODE',
  GET_USER_EMAIL_BY_CODE_SUCCESS='GET_USER_EMAIL_BY_CODE_SUCCESS',
  GET_USER_EMAIL_BY_CODE_ERROR='GET_USER_EMAIL_BY_CODE_ERROR',
}

export const resetUser = createAction(UserActionTypes.RESET_USER, (payload = 'dashboard') => ({ payload }));
export const userError = createAction(UserActionTypes.USER_ERROR, (payload: HttpErrorResponse) => ({
  payload,
}));
export const redirect = createAction(UserActionTypes.REDIRECT, (redirectUrl?: string) => ({ redirectUrl }));
//export const userRegistered = createAction(UserActionTypes.USER_REGISTERED) ;
// export const userLoggedIn = createAction(UserActionTypes.USER_LOGGED_IN) ;

// export class userLoggedIn implements Action {
//   readonly type = UserActionTypes.USER_LOGGED_IN;
//   constructor(public UserData?: any) {}
// }
export const userLoggedIn = createAction(
  UserActionTypes.USER_LOGGED_IN,
  (payload: any) => ({
    payload,
    //shouldRedirect,
  })
);

export const userLoggedInFailed = createAction(UserActionTypes.USER_LOGGED_IN_FAILED, (payload: string | HttpErrorResponse) => ({
  payload,
}));
//forget password
export class ForgetPassword implements Action {
  readonly type = UserActionTypes.USER_FOTGET_PASSWORD;
  constructor(public payload:any) {}
}

export class ForgetPasswordSuccess implements Action {
  readonly type = UserActionTypes.USER_FOTGET_PASSWORD_SUCCESS;
  constructor(public payload: any) {}
}

export class ForgetPasswordError implements Action {
  readonly type = UserActionTypes.USER_FOTGET_PASSWORD_ERROR;
  constructor(public payload: any) {}
}
//reset password 
export class ResetPassword implements Action {
  readonly type = UserActionTypes.USER_RESET_PASSWORD;
  constructor(public payload:any) {}
}

export class ResetPasswordSuccess implements Action {
  readonly type = UserActionTypes.USER_RESET_PASSWORD_SUCCESS;
  constructor(public payload: any) {}
}

export class ResetPasswordError implements Action {
  readonly type = UserActionTypes.USER_RESET_PASSWORD_ERROR;
  constructor(public payload: any) {}
}
//get user-email by code  
export class getUserEmailByCode implements Action {
  readonly type = UserActionTypes.GET_USER_EMAIL_BY_CODE;
  constructor(public code:string) {}
}

export class getUserEmailByCodeSuccess implements Action {
  readonly type = UserActionTypes.GET_USER_EMAIL_BY_CODE_SUCCESS;
  constructor(public payload: any) {}
}

export class getUserEmailByCodeError implements Action {
  readonly type = UserActionTypes.GET_USER_EMAIL_BY_CODE_ERROR;
  constructor(public payload: any) {}
}
export const userAfterLoggedIn = createAction(UserActionTypes.USER_AFTER_LOGGED_IN);

export const userAfterLoggedInComplete = createAction(UserActionTypes.USER_AFTER_LOGGED_IN_COMPLETE, (payload: IUser) => ({
  payload,
}));

export const userAfterLoggedInFailed = createAction(UserActionTypes.USER_AFTER_LOGGED_IN_FAILED, (payload: string) => ({
  payload,
}));

 

export const clearUserError = createAction(UserActionTypes.CLEAR_USER_ERROR);

export const userLoggedOut = createAction(UserActionTypes.USER_LOGGED_OUT, (payload: string) => ({
  payload,
}));

export const userLoggedOutSuccess = createAction(UserActionTypes.USER_LOGGED_OUT_COMPLETE, (payload: string) => ({
  payload,
}));

export const userLoggedOutFailed = createAction(UserActionTypes.USER_LOGGED_OUT_FAILED, (error: Error) => ({
  error,
}));

export const refreshUserProfile = createAction(
  UserActionTypes.REFRESH_USER_PROFILE,
  (payload: { selectedShipToId: number; fallbackShipToId: number }) => ({
    payload,
  })
);

export const refreshUserProfileSuccess = createAction(UserActionTypes.REFRESH_USER_PROFILE_SUCCESS, (payload: IUser) => ({
  payload,
}));

export const tokenRefresh = createAction(UserActionTypes.TOKEN_REFRESH);