<form [formGroup]="editRegisterForm">
  <div class="form-group row">
    <label class="col-3 col-form-label"
      >First Name <span class="astric">*</span></label
    >
    <kendo-textbox
      class="col-9"
      placeholder="FirstName"
      name="firstName"
      formControlName="firstName">
    </kendo-textbox>
    <app-error-message message="FirstName is required" *ngIf="editRegisterForm.touched &&hasError('firstName','required')" [isError]="true"></app-error-message>
  
    <app-error-message message="Max length is 256 chars" *ngIf="!hasError('firstName','required') &&
    hasError('firstName','maxlength')" [isError]="true"></app-error-message>
    <app-error-message message="Min length is 1 char"  *ngIf="!hasError('firstName','required') &&
    hasError('firstName','minLength')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't contain space only"  *ngIf="!hasError('firstName','required') &&
    hasError('firstName','cannotContainSpaceOnly')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't start with white space"  *ngIf="!hasError('firstName','required') &&
    hasError('firstName','cannotStartWithWhiteSpace')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't end with white space"  *ngIf="!hasError('firstName','required') &&
    hasError('firstName','cannotEndWithWhiteSpace')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't be numbers only"  *ngIf="!hasError('firstName','required') &&
    hasError('firstName','cannotBeNumbersOnly')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't be special chars only"  *ngIf="!hasError('firstName','required') &&
    hasError('firstName','cannotBeSpecialCharsOnly')" [isError]="true"></app-error-message>
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label"
      >Last Name <span class="astric">*</span></label
    >
    <kendo-textbox
      class="col-9"
      placeholder="LastName"
      name="lastName"
      formControlName="lastName"
       
    >
    </kendo-textbox>
    <app-error-message message="lastName is required" *ngIf="editRegisterForm.touched &&hasError('lastName','required')" [isError]="true"></app-error-message>
  
    <app-error-message message="Max length is 256 chars" *ngIf="!hasError('lastName','required') &&
    hasError('lastName','maxlength')" [isError]="true"></app-error-message>
    <app-error-message message="Min length is 1 char"  *ngIf="!hasError('lastName','required') &&
    hasError('lastName','minLength')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't contain space only"  *ngIf="!hasError('lastName','required') &&
    hasError('lastName','cannotContainSpaceOnly')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't start with white space"  *ngIf="!hasError('lastName','required') &&
    hasError('lastName','cannotStartWithWhiteSpace')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't end with white space"  *ngIf="!hasError('lastName','required') &&
    hasError('lastName','cannotEndWithWhiteSpace')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't be numbers only"  *ngIf="!hasError('lastName','required') &&
    hasError('lastName','cannotBeNumbersOnly')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't be special chars only"  *ngIf="!hasError('lastName','required') &&
    hasError('lastName','cannotBeSpecialCharsOnly')" [isError]="true"></app-error-message>
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label"
      >ُEmail <span class="astric">*</span></label
    >
    <kendo-textbox
      class="col-9"
      placeholder="Email"
      name="email"
      formControlName="email"
      #email
    >
    </kendo-textbox>
    <app-error-message message="email is required" *ngIf="editRegisterForm.touched &&hasError('email','required')" [isError]="true"></app-error-message>
    <app-error-message message="email is invalid" *ngIf="hasError('email','emailFormat')" [isError]="true"></app-error-message>
  </div>
  
  <div class="form-group row">
    <label class="col-3 col-form-label"
      >Phone <span class="astric">*</span></label
    >
    <kendo-textbox
      class="col-9"
      placeholder="Phone"
      name="phone"
      formControlName="phone"
     
    >
    </kendo-textbox>
    <app-error-message message="phone is required" *ngIf="editRegisterForm.touched &&editRegisterForm.controls['phone'].value==''" [isError]="true"></app-error-message>
    <app-error-message message="phone must be numbers only" *ngIf="hasError('phone','NumbersOnlyValidator')" [isError]="true"></app-error-message>
    <!-- <app-error-message message="phone must be 12 numbers only " *ngIf="hasError('phone','maxLength')" [isError]="true"></app-error-message> -->
    
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label"
      >Region <span class="astric">*</span></label
    >
    <kendo-textbox
      class="col-9"
      placeholder="Region"
      name="region"
      formControlName="region"
       
    >
    </kendo-textbox>
    <app-error-message message="region is required" *ngIf="editRegisterForm.touched &&hasError('region','required')" [isError]="true"></app-error-message>
  
    <app-error-message message="Max length is 256 chars" *ngIf="!hasError('region','required') &&
    hasError('region','maxlength')" [isError]="true"></app-error-message>
    <app-error-message message="Min length is 1 char"  *ngIf="!hasError('region','required') &&
    hasError('region','minLength')" [isError]="true"></app-error-message>
  
    <app-error-message message="region can't contain space only"  *ngIf="!hasError('region','required') &&
    hasError('region','cannotContainSpaceOnly')" [isError]="true"></app-error-message>
  
    <app-error-message message="region can't start with white space"  *ngIf="!hasError('region','required') &&
    hasError('region','cannotStartWithWhiteSpace')" [isError]="true"></app-error-message>
  
    <app-error-message message="region can't end with white space"  *ngIf="!hasError('region','required') &&
    hasError('region','cannotEndWithWhiteSpace')" [isError]="true"></app-error-message>
  
    <app-error-message message="region can't be numbers only"  *ngIf="!hasError('region','required') &&
    hasError('region','cannotBeNumbersOnly')" [isError]="true"></app-error-message>
  
    <app-error-message message="region can't be special chars only"  *ngIf="!hasError('region','required') &&
    hasError('region','cannotBeSpecialCharsOnly')" [isError]="true"></app-error-message>
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label"
      >Title <span class="astric">*</span></label
    >
    <kendo-textbox
      class="col-9"
      placeholder="Title"
      name="title"
      formControlName="title"
       
    >
    </kendo-textbox>
    <app-error-message message="title is required" *ngIf="editRegisterForm.touched &&
    hasError('title','required')" [isError]="true"></app-error-message>
    <app-error-message message="Max length is 256 chars" *ngIf="!hasError('title','required') &&
    hasError('title','maxlength')" [isError]="true"></app-error-message>
    <app-error-message message="Min length is 1 char"  *ngIf="!hasError('title','required') &&
    hasError('title','minLength')" [isError]="true"></app-error-message>
  
    <app-error-message message="title can't contain space only"  *ngIf="!hasError('title','required') &&
    hasError('title','cannotContainSpaceOnly')" [isError]="true"></app-error-message>
  
    <app-error-message message="title can't start with white space"  *ngIf="!hasError('title','required') &&
    hasError('title','cannotStartWithWhiteSpace')" [isError]="true"></app-error-message>
  
    <app-error-message message="title can't end with white space"  *ngIf="!hasError('title','required') &&
    hasError('title','cannotEndWithWhiteSpace')" [isError]="true"></app-error-message>
  
    <app-error-message message="title can't be numbers only"  *ngIf="!hasError('title','required') &&
    hasError('title','cannotBeNumbersOnly')" [isError]="true"></app-error-message>
  
    <app-error-message message="title can't be special chars only"  *ngIf="!hasError('title','required') &&
    hasError('title','cannotBeSpecialCharsOnly')" [isError]="true"></app-error-message>
  </div>
  
  <div class="form-group row">
    <label class="col-3 col-form-label"
      >Company Type<span class="astric">*</span></label
    >
    <app-dropdown-list
      class="col-9"
      [data]="CompanyTypes"
      textField="companyTypeName"
      valueField="companyTypeId"
      [valuePrimitive]="true"
      [selectedItem]="companyTypeId"
      (selectionChange)="getSelectedCompanyTypeItem($event)"
     >
    </app-dropdown-list>
    <app-error-message message="companyType is required" *ngIf="editRegisterForm.touched &&
    hasError('companyType','required')" [isError]="true"></app-error-message>
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label"
      >Company <span class="astric">*</span></label
    >
    <kendo-autocomplete
    [data]="CompanyList"
    class="col-9"
    [disabled]="IsDisabled"
    formControlName="companyName"
    placeholder="Company"
    [filterable]="true"
    (filterChange)="click($event)"
  >
  </kendo-autocomplete>
    <app-error-message message="companyName is required" *ngIf="editRegisterForm.touched &&
    hasError('companyName','required')" [isError]="true"></app-error-message>
   <app-error-message message="Max length is 256 chars" *ngIf="!hasError('companyName','required') &&
    hasError('companyName','maxlength')" [isError]="true"></app-error-message>
    <app-error-message message="Min length is 1 char"  *ngIf="!hasError('companyName','required') &&
    hasError('companyName','minLength')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't contain space only"  *ngIf="!hasError('companyName','required') &&
    hasError('companyName','cannotContainSpaceOnly')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't start with white space"  *ngIf="!hasError('companyName','required') &&
    hasError('companyName','cannotStartWithWhiteSpace')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't end with white space"  *ngIf="!hasError('companyName','required') &&
    hasError('companyName','cannotEndWithWhiteSpace')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't be numbers only"  *ngIf="!hasError('companyName','required') &&
    hasError('companyName','cannotBeNumbersOnly')" [isError]="true"></app-error-message>
  
    <app-error-message message="Name can't be special chars only"  *ngIf="!hasError('companyName','required') &&
    hasError('companyName','cannotBeSpecialCharsOnly')" [isError]="true"></app-error-message> 
  
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label">Role<span class="astric">*</span></label>
    <app-dropdown-list
      class="col-9"
      [data]="RolelistItems"
      textField="roleName"
      valueField="roleId"
      [selectedItem]="roleId"
      [valuePrimitive]="true"
      (selectionChange)="getSelectedRole($event)"
    >
    </app-dropdown-list>
    <app-error-message message="role is required" *ngIf="editRegisterForm.touched &&
    hasError('roleId','required')" [isError]="true"></app-error-message>
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label"
      >Login Status<span class="astric">*</span></label
    >
 
    <app-check-box
       [text]="'Enabled'"
        [value]="checkedEnabledvalue"
        [name]="'loginStatus'"
        (change)="getCheckedValue($event)"
        [checkedStatus]="checkedStatusEnable"
       ></app-check-box>
   
    <app-check-box
      [checkedStatus]="checkedStatusDisable"
       [text]="'Disabled'"
       [value]="checkedDisabledvalue"
       [name]="'loginStatus'"  
       (change)="getCheckedValue($event)"
  
     ></app-check-box>
   
  
  </div>
  <app-error-message
    message="{{ error }}"
    *ngIf="this.IsError"
    [isError]="true"
  ></app-error-message>
  </form>
  
