 import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ServiceMiddleware } from "src/app/core/Admin/service.layer/api.services.middleware";
import {  bomDelete, bomDeleteError, bomDeleteSuccess, DiscardEngineerFile, DiscardEngineerFileError, DiscardEngineerFileSucess, exportBomDetails, exportBomDetailsError, exportBomDetailsSucces, GetBomDetailsGridById, GetBomDetailsGridByIdError, GetBomDetailsGridByIdSuccess, getEngBomDetailsById, getEngBomDetailsByIdError, getEngBomDetailsByIdSuccess, GetLastEngineerFileStatus, GetLastEngineerFileStatusError, GetLastEngineerFileStatusSuccess, SubmitEngineerFile, SubmitEngineerFileError, SubmitEngineerFileSuccess } from "./engineer.action";
import { ActionTypes, GetUploadedBoms, GetUploadedBomsError, GetUploadedBomsSuccess } from "./engineer.action";
import { catchError, map, switchMap } from 'rxjs/operators';
import { gridRequest } from "src/app/core/grid.request";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";
import { MapMultipleFilter, mapFilter, mapSort } from "src/app/core/utils";
@Injectable({
    providedIn: 'root',
  })
  export class EngineerEffects { 
    APIURL: string;
    constructor(
        private actions$: Actions,
        private serviceMiddleware: ServiceMiddleware
      ) { }
    
      geEngineerFileStatus$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(ActionTypes.VALIDATE_ENGINEER_FILE_STATUS),
          switchMap((action: GetLastEngineerFileStatus) => {
     
            this.APIURL =
              '/cmt/engbom/isActiveFile';
            return this.serviceMiddleware.Get(this.APIURL).pipe(
              map((dataResponse: any) => new GetLastEngineerFileStatusSuccess(dataResponse)),
              catchError((errorResponse: HttpErrorResponse) =>
                of(new GetLastEngineerFileStatusError(errorResponse))
              )
            );
          })
        );
      });

      DiscardEngineerFile$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(ActionTypes.DISCARD_ENGINEER_FILE),
          switchMap((action: DiscardEngineerFile) => {
            this.APIURL =
              '/cmt/engbom/discardActiveFile/' + action.filetId + '';
            return this.serviceMiddleware.Get(this.APIURL).pipe(
              map((dataResponse: any) => new DiscardEngineerFileSucess(dataResponse)),
              catchError((errorResponse: HttpErrorResponse) =>
                of(new DiscardEngineerFileError(errorResponse))
                )
            );
          })
        );
      });

      SubmitEngineerFile$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(ActionTypes.SUBMIT_ENGINEER_FILE),
          switchMap((action: SubmitEngineerFile) => {
            this.APIURL =
              '/cmt/engbom/save';
            return this.serviceMiddleware.AddRecord(this.APIURL,action.payload).pipe(
              map((dataResponse: any) => new SubmitEngineerFileSuccess(dataResponse)),
              catchError((errorResponse: HttpErrorResponse) =>
                of(new SubmitEngineerFileError(errorResponse))
                )
            );
          })
        );
      });

      getBomGridData$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(ActionTypes.GET_UPLOADED_BOMS),
          switchMap((action: GetUploadedBoms) => {
            this.APIURL = '/cmt/engbom';
            const request = gridRequest(
              action?.page,
              action?.size,
              mapSort(action?.sort),
              mapFilter(action?.filter)
            );
            return this.serviceMiddleware.getList(this.APIURL, request).pipe(
              map((dataResponse: any) => new GetUploadedBomsSuccess(dataResponse)),
              catchError((errorResponse: HttpErrorResponse) =>
                of(new GetUploadedBomsError(errorResponse))
              )
            );
          })
        );
      });

      getBomDetailsGridData$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(ActionTypes.GET_BOM_DETAILS_GRID_BY_ID),
          switchMap((action: GetBomDetailsGridById) => {
            this.APIURL = '/cmt/engbom/details';
            const request = gridRequest(
              action?.page,
              action?.size,
              mapSort(action?.sort),
              MapMultipleFilter(action?.filter)
            );
             const requestWithParentId = {parentId: action?.parentId, ...request}
            return this.serviceMiddleware.getList(this.APIURL, requestWithParentId).pipe(
              map((dataResponse: any) => new GetBomDetailsGridByIdSuccess(dataResponse)),
              catchError((errorResponse: HttpErrorResponse) =>
                of(new GetBomDetailsGridByIdError(errorResponse))
              )
            );
          })
        );
      });
      
  deleteBomGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.BOM_DELETE_RECORD),
      switchMap((action: bomDelete) => {
        this.APIURL = '/cmt/engbom/delete/';
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL + action.bomId)
          .pipe(
            map((dataResponse: any) => new bomDeleteSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new bomDeleteError(errorResponse))
            )
          );
      })
    );
  });
  getEngBomDetailsById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_ENG_BOM_DETAILS_BY_ID),
      switchMap((action: getEngBomDetailsById) => {
        this.APIURL = `/cmt/engbom/acl/${action.whereUsed}`;
        return this.serviceMiddleware
          .Get(this.APIURL)
          .pipe(
            map((dataResponse: any) => new getEngBomDetailsByIdSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new getEngBomDetailsByIdError(errorResponse))
            )
          );
      })
    );
  });
  exportEngBomDetailsById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_BOM_DETAILS),
      switchMap((action: exportBomDetails) => {
        this.APIURL = `/cmt/engbom/exportDetails`;
        const request = gridRequest(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        const requestWithParentId = {parentId: action?.bomId, ...request}
        return this.serviceMiddleware
          .exportFilteredList(this.APIURL,requestWithParentId)
          .pipe(
            map((dataResponse: any) => new exportBomDetailsSucces(dataResponse, action.fileName)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new exportBomDetailsError(errorResponse))
            )
          );
      })
    );
  });
  }