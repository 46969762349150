<div class="k-overlay" *ngIf="!closed"></div>
<div class="example-wrapper">
  <kendo-window
    [title]="title"
    *ngIf="!closed"
    (close)="closeWindow()"
    [minWidth]="minWidth"
    [width]="width"
    [top]="windowTop"
   >
    <div class="modal-content">
      <ng-container
        *ngComponentOutlet="openedComponent; injector: injector"
      ></ng-container>
    </div>
    <div class="k-actions k-actions-end">
      <button
        kendoButton
        fillMode="flat"
        type="button"
        class="k-button close-btn"
        (click)="cancel()" 
      >
        {{ cancelText }}
      </button>
      <button
        *ngIf="hasSubmitBtn"
        kendoButton
        type="button"
        [themeColor]="'primary'"
        class="k-button"
        (click)="submit()"
        [disabled]="disable"
      >
        {{ submitText }}
      </button>
      
    </div>
  </kendo-window>
</div>
