import { Injectable, Injector } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RoleSelector } from 'src/app/features/admin/stores/admin.selector';
import { Role } from 'src/app/shared/models/role';
import { IAppState } from 'src/app/shared/shared.interface';
import { AuthTokenService } from './auth-token.service';
import { allRoles, AllRoles } from './auth.roles';

@Injectable({
  providedIn: 'root',
})
export class RouteGuard implements CanLoad, CanActivate {
  CurrentUser: any;
  HasMenuRole: any;
  HasSubMenuRole: any;
  HasSubRole: any;
  subMenuRole: any;
  rolePath: any;

  AllRoles: AllRoles = new AllRoles();

  authorized: boolean = false;
  authorizedSubMenu: boolean = false;
  manMenu: any;
  manMenuPath: any;
  subMenuPath: any;
  currentURL: string;
  subMenu: any;
  roleIncluded: boolean = false;
  currentMainRole: any;
  constructor(
    private router: Router,
    private authTokenService: AuthTokenService,
    private store: Store<IAppState>
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     this.CurrentUser = JSON.parse(this.authTokenService.getUserRoles());
    this.authorizedSubMenu = false;
    if (this.CurrentUser !== undefined) {
      this.HasMenuRole = this.CurrentUser.filter(
        (x: any) => x.menuTitle == route.data.roles[0]
      );
       
      this.CurrentUser.forEach((element: any) => {
        this.subMenuRole = element.subMenuList.filter(
          (x: any) => x.subMenuName == route.data.roles[0]
        );
        if (this.subMenuRole?.length > 0) {
          this.authorizedSubMenu = true;
        }
        if (this.HasMenuRole.length === 0 && this.subMenuRole.length === 0) {
          if (this.currentMainRole !== element.menuTitle) {
            if (this.manMenuPath?.length === undefined||this.manMenuPath?.length === 0) {
              this.manMenuPath = this.AllRoles.roles.filter(
                (x: any) => x.role == element.menuTitle
              );
              this.subMenu = element.subMenuList[0].subMenuName;
              this.subMenuPath = this.AllRoles.roles.filter(
                (x: any) => x.role == this.subMenu
              );
            }
          }
        }
      });
  
      if (this.HasMenuRole?.length > 0) {
        this.authorized = true;
      } else if (this.subMenuRole?.length > 0) {
        this.authorized = true;
        this.authorizedSubMenu = true;
      } else {
        if (this.authorizedSubMenu) {
          this.authorized = true;
        }
        else {
          this.rolePath = this.manMenuPath[0].path + '/' + this.subMenuPath[0].path;
          this.manMenuPath=[];
          // this.currentMainRole = this.manMenuPath[0].role;
          return this.router.navigateByUrl(this.rolePath);
        }
        // return this.router.navigateByUrl('404');
      }
    } else {
      return this.router.navigateByUrl('404');
    }

    return this.authorized;
  }
  canLoad(route: Route, segments: UrlSegment[]) {
    if (
      this.authTokenService.getValidAccessToken() ||
      this.authTokenService.getValidRefreshToken()
    ) {
      return true;
    }
    this.router.navigateByUrl('/login');
    return false;
  }
}
