import { IEngineerState } from "src/app/core/engineer/engineer.interfaces";
import { UploadAction } from "src/app/core/upload/upload.interface";
import { UPLOAD_FILE_ID, UPLOAD_TICKET_ID } from "src/app/core/upload/upload.request";
import { downLoadFile } from "src/app/core/utils";
import { ActionTypes } from "./engineer.action";

export const INITIAL_ENGINEER_STATE: IEngineerState = {
    engBomDetails :{ 
        data: [],
        error: undefined,
        loading: false,
    },
    bomGrid: {
        currentPage: 1,
        data: [],
        headers: [],
        offset: 1,
        pageSize: 10,
        total: 0,
        error: undefined,
        loading: false,
        export: {},
        add: undefined,
        edit: undefined,
        delete: 0,
    },
    submitFile: {
        submited: false
    },
    validateFile: {
        loading: false,
        fileData: null,
    },
    currentAction: {
        actionType: '',
        fileId: null,
    },
    discard: {
        status: undefined,
    },
    bomDetailsGrid: {
        currentPage: 1,
        data: [],
        headers: [],
        offset: 1,
        pageSize: 10,
        total: 0,
        error: undefined,
        loading: false,
        export: {},
        add: undefined,
        edit: undefined,
        delete: 0,
    },
    bomData: undefined,
    export:undefined
}
export function EngineerReducer(state = INITIAL_ENGINEER_STATE, action: any): any {
    switch (action.type) {
        case ActionTypes.SET_CURRENT_ACTION_DATA: {
            //Save data in local storage as well to help if user refresh page
            sessionStorage.setItem(UPLOAD_FILE_ID, action.fileId);
            sessionStorage.setItem(UPLOAD_TICKET_ID, action.fileId);

            return {
                ...state,
                currentAction: {
                    ...state.currentAction,
                    actionType: action.actionType,
                    fileId: action.fileId,
                },
            };
        }
        case ActionTypes.VALIDATE_ENGINEER_FILE_STATUS: {

            return {
                ...state,
                validateFile: {
                    ...state.validateFile,
                    fileData: null,
                    loading: true,
                },
                currentAction: {
                    ...state.currentAction,
                    fileId: null,
                },
            };
        }
        case ActionTypes.VALIDATE_ENGINEER_FILE_STATUS_SUCCESS: {

            return {
                ...state,
                validateFile: {
                    ...state.validateFile,
                    fileData: {
                        isActive: action.payload?.isActive,
                        fileId: action.payload?.fileId,
                    },
                    loading: false,
                },
                currentAction: {
                    ...state.currentAction,
                    fileId: action.payload?.fileId,
                },
            };
        }
        case ActionTypes.VALIDATE_ENGINEER_FILE_STATUS_ERROR: {
            return {
                ...state,
                validateFile: {
                    ...state.validateFile,
                    fileData: null,
                    loading: false,
                },
            };
        }

        //discard Engineer File
        case ActionTypes.DISCARD_ENGINEER_FILE: {
            return {
                ...state,
                discard: {
                    ...state.discard,
                    loading: true,
                },
                currentAction: {
                    ...state.currentAction,
                    fileId: null,
                },
            };
        }
        case ActionTypes.DISCARD_ENGINEER_FILE_SUCCESS: {
            return {
                ...state,
                discard: {
                    ...state.discard,
                    status: 'success',
                    loading: false,
                },
            };
        }
        case ActionTypes.DISCARD_ENGINEER_FILE_ERROR: {
            return {
                ...state,
                discard: {
                    ...state.discard,
                    loading: false,
                    error: action.payload,
                },
            };
        }
        // submit engineer bom file 
        //discard Engineer File
        case ActionTypes.SUBMIT_ENGINEER_FILE: {
            return {
                ...state,
                submitFile: {
                    ...state.submitFile,
                     loading: true,
                }
            };
        }
        case ActionTypes.SUBMIT_ENGINEER_FILE_SUCCESS: {
            return {
                ...state,
                submitFile: {
                    ...state.submitFile,
                    submited: true,
                    loading: false,
                }
            };
        }
        case ActionTypes.SUBMIT_ENGINEER_FILE_ERROR: {
            return {
                ...state,
                submitFile: {
                    ...state.submitFile,
                    submited: false,
                    eror:action?.payload,
                    loading: false,
                }
            };
        }
        case ActionTypes.GET_UPLOADED_BOMS: {
            return {
                ...state,
                bomGrid: {
                    ...state.bomGrid,
                    loading: true,
                },
            };
        }

        case ActionTypes.GET_UPLOADED_BOMS_SUCCESS: {
            return {
                ...state,
                bomGrid: {
                    ...state.bomGrid,
                    data: action.payload.data,
                    currentPage: action.payload.currentPage,
                    offset: action.payload.offset,
                    pageSize: action.payload.pageSize,
                    total: action.payload.total,
                    loading: false,
                },
            };
        }

        case ActionTypes.GET_UPLOADED_BOMS_ERROR: {
            return {
                ...state,
                bomGrid: {
                    ...state.bomGrid,
                    loading: false,
                    error: action.payload,
                },
            };
        }
        case ActionTypes.GET_BOM_DETAILS_GRID_BY_ID: {
            return {
                ...state,
                bomDetailsGrid: {
                    ...state.bomDetailsGrid,
                    loading: true,
                },
            };
        }

        case ActionTypes.GET_BOM_DETAILS_GRID_BY_ID_SUCCESS: {
            return {
                ...state,
                bomDetailsGrid: {
                    ...state.bomDetailsGrid,
                    data: action.payload.data,
                    currentPage: action.payload.currentPage,
                    offset: action.payload.offset,
                    pageSize: action.payload.pageSize,
                    total: action.payload.total,
                    loading: false,
                },
            };
        }

        case ActionTypes.GET_BOM_DETAILS_GRID_BY_ID_ERROR: {
            return {
                ...state,
                bomDetailsGrid: {
                    ...state.bomDetailsGrid,
                    loading: false,
                    error: action.payload,
                },
            };
        }

        case ActionTypes.SET_BOM_DATA: {
            return {
                ...state,
                bomData: {
                    ...action,
                },
            };
        }

        case ActionTypes.BOM_DELETE_RECORD: {
            return {
                ...state,
                bomGrid: {
                    ...state.bomGrid,
                    delete: undefined,
                    loading: true,
                    error: undefined,
                },
            };
        }

        case ActionTypes.BOM_DELETE_RECORD_SUCCESS: {
            return {
                ...state,
                bomGrid: {
                    ...state.bomGrid,
                    delete: 'deleted',
                    loading: false,
                },
            };
        }

        case ActionTypes.BOM_DELETE_RECORD_ERROR: {
            return {
                ...state,
                bomGrid: {
                    ...state.bomGrid,
                    loading: false,
                    error: action.payload,
                },
            };
        }


        
        case ActionTypes.BOM_GRID_DETAILS_PAGE_SIZE_CHANGED: {
            return {
              ...state,
              bomDetailsGrid: {
                ...state.bomDetailsGrid,
                pageSize: action.pageSize,
              },
            };
          }
          case ActionTypes.BOM_GRID_DETAILS_PAGE_CHANGED: {
            return {
              ...state,
              bomDetailsGrid: {
                ...state.bomDetailsGrid,
                currentPage: action.currentPage,
              },
            };
          }
          case ActionTypes.BOM_GRID_DETAILS_FiLTER_CHANGED: {
            return {
              ...state,
              bomDetailsGrid: {
                ...state.bomDetailsGrid,
                filter: action?.filter?.filters,
              },
            };
          }
          case ActionTypes.BOM_GRID_PAGE_CHANGED: {
            return {
              ...state,
              bomGrid: {
                ...state.bomGrid,
                currentPage: action.currentPage,
              },
            };
          }
          case ActionTypes.BOM_GRID_FiLTER_CHANGED: {
            return {
              ...state,
              bomGrid: {
                ...state.bomGrid,
                filter: action?.filter?.filters,
              },
            };
          }
          case ActionTypes.BOM_GRID_PAGE_SIZE_CHANGED: {
            return {
              ...state,
              bomGrid: {
                ...state.bomGrid,
                pageSize: action.pageSize,
              },
            };
          }
          case ActionTypes.BOM_GRID_DETAILS_SORT_CHANGED: {
            return {
              ...state,
              bomDetailsGrid: {
                ...state.bomDetailsGrid,
                sort: action?.sort,
              },
            };
          }

          case ActionTypes.BOM_GRID_SORT_CHANGED: {
            return {
              ...state,
              bomGrid: {
                ...state.bomGrid,
                sort: action?.sort,
              },
            };
          }
        case ActionTypes.GET_ENG_BOM_DETAILS_BY_ID: {
            return {
                ...state,
                engBomDetails :{
                    ...state.engBomDetails,
                    loading: true
                 }
              
            };
        }

        case ActionTypes.GET_ENG_BOM_DETAILS_BY_ID_SUCCESS: {
            return {
                ...state,
                engBomDetails :{
                    ...state.engBomDetails,
                    loading: false,
                    data:  action.payload
                 }
            };
        }

        case ActionTypes.GET_ENG_BOM_DETAILS_BY_ID_ERROR: {
            return {
                ...state,
                engBomDetails :{
                    ...state.engBomDetails,
                    loading: false,
                    data: [],
                    error:  action.payload
                 }
            };
        }
        case ActionTypes.EXPORT_BOM_DETAILS: {
            return {
              ...state,
              export: {
                loading: true,
                error: undefined,
              },
            };
          }
          case ActionTypes.EXPORT_BOM_DETAILS_SUCCESS: {
            downLoadFile(action.payload, 'application/ms-excel', action.fileName);
            return {
              ...state,
              export: {
                loading: false,
                error: undefined,
              },
            };
          }
          case ActionTypes.EXPORT_BOM_DETAILS_ERROR: {
            return {
              ...state,
              export: {
                loading: false,
                error: action.payload,
              },
            };
          }
    }
}