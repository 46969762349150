import { DatePipe } from '@angular/common';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { IInvitedData, IInvitedFlatObject } from './invited-users.interfaces';

export function flatInvitedData(data: IInvitedData[]): IInvitedFlatObject[] {
  let flattedData: IInvitedFlatObject[] = [];
  data?.forEach((element) => {
    var elem: IInvitedFlatObject = {
      roleId: element.role.roleId,
      roleName: element.role.roleName,
      companyTypeId: element.companyType.companyTypeId,
      companyTypeName: element.companyType.companyTypeName,
      email: element.email,
      companyName: element.companyName,
      invitationId: element.invitationId,
      insertionDate: element.insertionDate,
    };
    flattedData.push(elem);
  });
  return flattedData;
}

export function mapInvitedUsersAPISort(sort?: SortDescriptor[]) {
  var kendoSort = _.isArray(sort) ? sort[0] : sort;
  var dir = kendoSort?.dir === 'desc' ? 'desc' : 'asc';
  switch (kendoSort?.field) {
    case 'companyTypeName':
      return { columnName: 'companyType.companyTypeName', direction: dir };
    case 'roleName':
      return { columnName: 'role.roleName', direction: dir };
    default:
      return { columnName: kendoSort?.field, direction: dir };
  }
}

export function mapInvitedUsersAPI(gridFilters: any) {
  var kendoFilter = _.isArray(gridFilters) ? gridFilters[0] : gridFilters;
  var kendoFilters = _.isArray(kendoFilter?.filters)
    ? kendoFilter?.filters[0]
    : kendoFilter;

  switch (kendoFilters?.field) {
    case 'roleName':
      return [
        {
          columnName: 'role.roleName',
          value: kendoFilters?.value,
        },
      ];
    case 'insertionDate':
      return [
        {
          columnName: kendoFilters?.field,
          value:new DatePipe('en-US').transform( kendoFilters?.value, 'yyyy-MM-dd')?.toString(),   
        },
      ];
    case 'companyTypeName':
      return [
        {
          columnName: 'companyType.companyTypeName',
          value: kendoFilters?.value,
        },
      ];
    default:
      return [
        {
          columnName: kendoFilters?.field,
          value: kendoFilters?.value,
        },
      ];
  }
}
