import { NgModule,ErrorHandler,Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { effects } from './stores/effects';
import { reducers } from './stores/reducers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { environment } from 'src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalExceptionhandelor } from './shared/classes/GlobalErrorHandler';
import { GlobalHttpInterceptorService } from './shared/classes/GlobalHttpErrorHandler';
 

const devTools = environment.devTool
  ? StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.devTool,
    })
  : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
     StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot(effects),
    devTools,
    CoreModule,
    LayoutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy  },
    {provide:ErrorHandler , useClass:GlobalExceptionhandelor},
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true  }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
