import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  ICompanyType,
  IRoleList,
  LoginStatus,
  LoginStatusChecked,
} from 'src/app/core/Admin/users/invited-users/invited-users.interfaces';
import {
  GetCompanyByTypeName,
  GetCompanyTypeList,
  GetRoleList,
  RegisteredLoadData,
  RegisteredUserEdit,
  SetValidationError,
} from 'src/app/features/admin/stores/admin.action';
import {
  CompanySelector,
  CompanyTypeSelector,
  GetValidationErrorSelector,
  RegisteredGridDataSelector,
  RegisteredGridEditSelector,
  RegisteredselectedFiltersSelector,
  RoleSelector,
  selectedFiltersSelector,
} from 'src/app/features/admin/stores/admin.selector';
import { ProviderClass } from 'src/app/shared/classes/ProviderClass';
import { CustomValidators } from 'src/app/shared/classes/custom-validator';
import { DialogShareService } from 'src/app/shared/components/Modals/dialog.service';
import { Dialog } from 'src/app/shared/models/model.interface';
import {
  actionComponentData,
  IAppState,
} from 'src/app/shared/shared.interface';

@Component({
  selector: 'app-edit-registered',
  templateUrl: './edit-registered.component.html',
  styleUrls: ['./edit-registered.component.scss'],
})
export class EditRegisteredComponent implements OnInit, OnDestroy {
  public CompanyTypeList: any;
  public CompanyList: any[] = [];
  saveData: boolean = false;
  public RolelistItems: IRoleList[] = [];
  public CompanyTypes: ICompanyType[] = [];

  //public loginStatus: LoginStatus;
  checkedStatusEnable: boolean = false;
  checkedStatusDisable: boolean = false;
  public editRegisterForm:FormGroup;

  firstNameError: string = '';
  lastNameError: string = '';
  emailError: string = '';
  phoneError: string = '';
  regionError: string = '';
  titleError: string = '';
  companyError: string = '';
  companyTypeError: string = '';
  roleError: string = '';

  loginStatus: string;
  loginStatusId: number;

  error: any;
  IsError: boolean = false;
  subscription: Subscription = new Subscription();

  roleName: string = '';
  companyId: any;
  compantName: string;

  public gridFilters = {
    filter: undefined,
    sort: undefined,
    page: 0,
    pageSize: 10,
  };
  selectedData: actionComponentData = {};
  edit: boolean = false;
  checkedEnabledvalue: number = LoginStatusChecked.ENABLED;
  checkedDisabledvalue: number = LoginStatusChecked.DISABLED;
  IsDisabled: boolean=false;
  companyTypeId: any;
  companyTypeName: any;
  roleId: any;
  Cardinal: string='Cardinal';
  companyName: string;

  constructor(private fb:FormBuilder,
    public dataIn: ProviderClass,
    private dialogService: DialogShareService,
    private store: Store<IAppState>
  ) {
    this.loadData();

    this.editRegisterForm=this.fb.group({
      userId:[0],
      firstName:["", [Validators.required,CustomValidators.cannotContainSpace,
        CustomValidators.cannotStartWithWhiteSpaceValidator,
        CustomValidators.cannotEndWithWhiteSpaceValidator,
        CustomValidators.cannotBeSpecialCharsOnlyValidator,
        CustomValidators.cannotBeNumbersOnlyValidator,
        Validators.minLength(1),
        Validators.maxLength(256)]],
      lastName:["", [Validators.required,CustomValidators.cannotContainSpace,
        CustomValidators.cannotStartWithWhiteSpaceValidator,
        CustomValidators.cannotEndWithWhiteSpaceValidator,
        CustomValidators.cannotBeSpecialCharsOnlyValidator,
        CustomValidators.cannotBeNumbersOnlyValidator,
        Validators.minLength(1),
        Validators.maxLength(256)]],
      email:["",[Validators.required,CustomValidators.emailFormat]],
      phone:["",[Validators.required,CustomValidators.NumbersOnlyValidator]],
      region:["", [Validators.required,CustomValidators.cannotContainSpace,
        CustomValidators.cannotStartWithWhiteSpaceValidator,
        CustomValidators.cannotEndWithWhiteSpaceValidator,
        CustomValidators.cannotBeSpecialCharsOnlyValidator,
        CustomValidators.cannotBeNumbersOnlyValidator,
        Validators.minLength(1),
        Validators.maxLength(256)]],
      title:["", [Validators.required,CustomValidators.cannotContainSpace,
        CustomValidators.cannotStartWithWhiteSpaceValidator,
        CustomValidators.cannotEndWithWhiteSpaceValidator,
        CustomValidators.cannotBeSpecialCharsOnlyValidator,
        CustomValidators.cannotBeNumbersOnlyValidator,
        Validators.minLength(1),
        Validators.maxLength(256)]],
      companyTypeId:[0,Validators.required],
      companyName:['',Validators.required],

  
    });
    
  }

  
  public CompanyTypeplaceHolder: ICompanyType = {
    companyTypeId: 0,
    companyTypeName: 'Select Company Type',
  };
  public RoleplaceHolder: IRoleList = {
    roleId: 0,
    roleName: 'Select Role',
  };

  ngOnInit(): void {
    this.selectedData = this.dataIn;
    if (this.selectedData?.data) {
      this.getEditData();
       this.edit = true;
    }
    this.startSubscribtion();

 
    
  }
  
  hasError(ctrName:string,errorName:string):boolean{
    let res=this.editRegisterForm?.controls[ctrName]?.hasError(errorName);
    return res;
   }
  startSubscribtion() {
    this.subscription.add(this.subscribeToDialog());
    this.subscription.add(this.subscribeCompanyTypeListItems());
    this.subscription.add(this.subscribeRoleListItems());
    this.subscription.add(this.subscribeCompanyListItems());
    this.subscription.add(this.subscribeToFilterSelector());
    this.subscription.add(this.subscribeToAddEditSelector());
  }
  subscribeToFilterSelector() {
    return this.store
      .select(RegisteredselectedFiltersSelector)
      .subscribe((data) => {
        if ((data: any) => data) {
          this.gridFilters.filter = data.filter;
          this.gridFilters.sort = data.sort;
          this.gridFilters.pageSize = data.pageSize;
          this.gridFilters.page = data.page;
        }
      });
  }
  getSelectedCompanyTypeItem($event: any) {
    this.CompanyList = [];
    this.editRegisterForm.controls['companyTypeId'].setValue($event.companyTypeId);
    this.editRegisterForm.controls['companyName'].setValue($event.companyTypeName);
    this.companyId=$event.companyTypeId;
    this.companyTypeName = $event.companyTypeName;
    if(this.companyTypeName==='Wabtec'){
      this.IsDisabled=true;
    } 
    if (this.companyTypeName.toUpperCase() === this.Cardinal.toUpperCase()) {

      this.IsDisabled=true;
    
    }
    
    else{
      this.IsDisabled=false;
      // this.companyTypeName='';
    }
    this.store.dispatch(new GetRoleList(this.companyTypeName));
  }
  getSelectedRole($event: any) {
    let role = $event.roleId;
    this.roleId=role;//this.selectedData?.data?.roleId;

  }

  getEditData() {
    this.companyTypeId=this.selectedData?.data?.companyTypeId;
    this.companyTypeName=this.selectedData?.data?.companyTypeName;
    this.roleId=this.selectedData?.data?.roleId;
    this.roleName=this.selectedData?.data?.roleName;
    this.editRegisterForm.controls['firstName'].setValue(this.selectedData?.data?.firstName);
    this.editRegisterForm.controls['lastName'].setValue(this.selectedData?.data?.lastName);
    this.editRegisterForm.controls['email'].setValue(this.selectedData?.data?.email);
    this.editRegisterForm.controls['phone'].setValue(this.selectedData?.data?.phone);
    this.editRegisterForm.controls['title'].setValue(this.selectedData?.data?.title);
    this.editRegisterForm.controls['userId'].setValue(this.selectedData?.data?.userId);
    this.editRegisterForm.controls['region'].setValue(this.selectedData?.data?.region);
    this.editRegisterForm.controls['companyName'].setValue(this.selectedData?.data?.companyName);

      if(  this.companyTypeName==='wabtec'){
        this.IsDisabled=true;
      }
      if(  this.companyTypeName===this.Cardinal){
        this.IsDisabled=true;
      }
    this.loginStatus = this.selectedData?.data?.status;
    if (this.loginStatus === LoginStatus.ENABLED) {
      this.checkedStatusEnable = true;
      this.loginStatusId = LoginStatusChecked.ENABLED;
    } else if (this.loginStatus === LoginStatus.DISABLED) {
      this.checkedStatusDisable = true;
      this.loginStatusId = LoginStatusChecked.DISABLED;
    }
    this.store.dispatch(new GetRoleList(this.selectedData?.data?.companyType?.companyTypeName));

  }
  loadData() {
    this.store.dispatch(new GetCompanyTypeList());
  }
  subscribeToAddEditSelector() {
    this.store.dispatch(new SetValidationError(''));

    return this.store
      .select(RegisteredGridEditSelector)
      .subscribe((edit: any) => {
        if (edit?.userId && this.saveData) {
          this.saveData = false;
          this.store.dispatch(
            new RegisteredLoadData(
              this.gridFilters.page,
              this.gridFilters.pageSize,
              this.gridFilters?.filter,
              this.gridFilters.sort
            )
          );
          this.dialogService.sendMessage(Dialog.close);
        } else if (this.saveData) {
          this.store.select(GetValidationErrorSelector).subscribe((error) => {
            if (error.error != undefined) {
              this.error = error.error;
              this.IsError = error.isError;
            }
          });
        }
      });
  }
  subscribeCompanyTypeListItems() {
    return this.store.select(CompanyTypeSelector).subscribe((res) => {
      if (res?.length) {
        this.CompanyTypes = res;
        this.CompanyTypes = this.CompanyTypes.slice();
      }
    });
  }
  subscribeRoleListItems() {
    return this.store.select(RoleSelector).subscribe((res) => {
      if (res?.length===1) {
        this.RolelistItems = res;
        this.RolelistItems = this.RolelistItems.slice();
      }
      else if(res?.length>1){
        
        this.RolelistItems = res;
        this.RolelistItems = this.RolelistItems.slice();
      }
    });
    

  }
  click(value: any) {
    if (value.length >= 3) {
      return this.store.dispatch(
        new GetCompanyByTypeName(this.companyId, value)
      );
    } else {
      this.CompanyList = [];
    }
  }
  subscribeCompanyListItems() {
    return this.store.select(CompanySelector).subscribe((res) => {
    
      if (res?.length) {
        this.CompanyList = res;
      }
    });
  }
  public valueChange(value: string): void {
    return this.store.dispatch(new GetCompanyByTypeName(this.companyId, value));
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscribeToDialog() {
    return this.dialogService.getMessage().subscribe((data) => {
      if (data.res == Dialog.submit) {
        this.selectedData = {};
        this.save();
      }
    });
  }
  getCheckedValue(event: any) {
    this.loginStatusId = event.target.value;
  }
  save() { 
    if (this.validate()) {
      this.saveData = true;
      if (this.edit) {
        this.store.dispatch(
          new RegisteredUserEdit({
            userId: this.editRegisterForm.controls['userId'].value,
            firstName: this.editRegisterForm.controls['firstName'].value,
            lastName: this.editRegisterForm.controls['lastName'].value ,
            fullName: this.selectedData?.data?.fullName,
            email: this.editRegisterForm.controls['email'].value,
          
            phone: this.editRegisterForm.controls['phone'].value,
            region:this.editRegisterForm.controls['region'].value,
            companyTypeId: this.editRegisterForm.controls['companyTypeId'].value,
            companyName: this.editRegisterForm.controls['companyName'].value,
            title: this.editRegisterForm.controls['title'].value,
            roleId:  this.roleId,
            userStatusId: this.loginStatusId,
          })
        );
      }
    }
  }

  validate() {
    return this.editRegisterForm.valid;
  }
}
