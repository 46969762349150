import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  production: true,
  devTool: false,
  baseUrls: {
    serviceSecurity: 'https://cardinal-health.siliconexpert.com',
    serviceURL: 'https://cardinal-health.siliconexpert.com/api/v1',
   },
   ConfigParams: {
    infoUserEmail: 'michael.santoro@cardinalhealth.com',
    infouser: 'Michael Santoro',
    projectName:'Cardinal Health',
    domain:'https://cardinal-health.siliconexpert.com'
    },
  powerBiReports: {
    dashboardReportId: '4c92e06a-a325-4629-a31b-d9773e5439e5',
  },
};
