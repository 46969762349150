import { Component, Input, OnInit } from '@angular/core';
import { ItemType, navBarItems } from '../../shared.interface';
import { ISTEPPER } from '../stepper/stepper.interface';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @Input() items: navBarItems[] = [];
  @Input() hasStepper: boolean = false;
  @Input() currentStep = 0;
  @Input() txt? :string='';
  @Input() stepperSteps: ISTEPPER[] = [];
  public itemType: typeof ItemType = ItemType;
 
  constructor() {
  }

  ngOnInit(): void {
    setTimeout(() => {
     }, 10000);
  }
}
