import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PartSearchService } from 'src/app/core/parts/part-search/part-search.service';
import { ServiceMiddleware } from 'src/app/core/Admin/service.layer/api.services.middleware';
import {
  ActionTypes,
  ExportReportById,
  ExportReportByIdError,
  ExportReportByIdSucees,
  LoadPivoteTable,
  LoadPivoteTableError,
  LoadPivoteTableSuccees,
  getSourceColumns,
  getSourceColumnsError,
  getSourceColumnsSucees,
  ExportPivoteTable,
  ExportPivoteTableSucees,
  ExportPivoteTableError,
} from './report.action';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportEnum } from 'src/app/core/Reports/reports.interface';

@Injectable({
  providedIn: 'root',
})
export class ReprtsEffects {
  APIURL: string;
  constructor(
    private actions$: Actions,
    private serviceMiddleware: ServiceMiddleware
  ) { }

  /*-------------------------------------------------------Part Search-----------------------------------------------------------------------*/

  ExportReportById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_REPORT_By_ID),
      switchMap((action: ExportReportById) => {
     
        if (action.reporType === ReportEnum.rfq) {
          this.APIURL = '/rfq/Report/Export/Report';
          return this.serviceMiddleware.exportFilteredList(this.APIURL, action.payload).pipe(
            map((data: any) => new ExportReportByIdSucees(data, action.fileName)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ExportReportByIdError(errorResponse))
            )
          );
        }
        else if (action.reporType === ReportEnum.cmt) {
          if(action.reportId==5){
            this.APIURL = '/cmt/aclCross/exportInternalCrossReport/' + action.yearId;
            return this.serviceMiddleware.exportFilteredList(this.APIURL,{}).pipe(
              map((data: any) => new ExportReportByIdSucees(data, action.fileName)),
              catchError((errorResponse: HttpErrorResponse) =>
                of(new ExportReportByIdError(errorResponse))
              )
            );
          }
          else{
          this.APIURL = '/cmt/reports/export/' + action.reportId + '/' + action.yearId;
          }
        }
        return this.serviceMiddleware.exportList(this.APIURL).pipe(
          map((data: any) => new ExportReportByIdSucees(data, action.fileName)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ExportReportByIdError(errorResponse))
          )
        );
      })
    );
  });

// load pivote table 
$LoadPivoteTable = createEffect(() => {
  return this.actions$.pipe(
    ofType(ActionTypes.LOAD_PIVOTE_TABLE),
    switchMap((action: LoadPivoteTable) => {
        this.APIURL = '/rfq/Report/GetPivoteTable';
        return this.serviceMiddleware.AddRecord(this.APIURL,action.payload).pipe(
          map((data: any) => new LoadPivoteTableSuccees(data)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new LoadPivoteTableError(errorResponse))
          )
        );
    })
  );
});

  GetSourceColumns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_SOURCE_COLUMNS),
      switchMap((action: getSourceColumns) => {
        this.APIURL = '/rfq/Report/GetResourceColumns';
        return this.serviceMiddleware.getList(this.APIURL, { resouseTypeId: action.sourceId } ).pipe(
          map((data: any) => new getSourceColumnsSucees(data)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new getSourceColumnsError(errorResponse))
          )
        );
      })
    );
  });

  ExportPivoteTable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_PIVOTE_TABLE),
      switchMap((action: ExportPivoteTable) => {
        this.APIURL = '/rfq/Report/ExportPivoteTable';
        return this.serviceMiddleware.exportFilteredList(this.APIURL,action.payload).pipe(
          map((data: any) => new ExportPivoteTableSucees(data,action.fileName)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ExportPivoteTableError(errorResponse))
          )
        );
      })
    );
  });
}
