import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthTokenService } from 'src/app/core/auth/auth-token.service';
 import { IAppState, menuItem } from 'src/app/shared/shared.interface';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  menuItems: menuItem[] = [];
  isExist:any;
  constructor(private authTokenService: AuthTokenService) {}

  ngOnInit(): void {
    this.menuItems = [
      {
        name: 'Parts',
        routerLink: 'parts',
        permissions: ['Parts'],
      },
      {
        name: 'ACL',
        routerLink: 'acl',
        permissions: ['ACL'],
      },
      {
        name: 'Reports',
        routerLink: 'reports',
        permissions: ['Reports'],
        className:true        
      },
      {
        name: 'Upload',
        routerLink: 'upload',
        permissions: ['Upload'],
        
      },
      {
        name: 'Engineer',
        routerLink: 'engineer',
        permissions: ['Engineer'],
      },
      {
        name: 'Admin',
        routerLink: 'admin',
        permissions: ['Admin'],
      },

      {
        name: 'Info',
        routerLink: 'info',
        permissions: ['Info'],
      },
    ];
    this.checkMenuItemsPermission();
  }

  checkMenuItemsPermission() {
    let currentUser=JSON.parse(this.authTokenService.getUserRoles());
     if (currentUser && currentUser != undefined) {
      this.menuItems = this.menuItems.filter((item) => {
        if (item.permissions){
          this.isExist=currentUser.filter((x:any)=>x.menuTitle==item.permissions);
          if(this.isExist.length>0){
            return true;
          }
          else{
            return false;
          }
         }
        else return '';
      });
    }
  }
}
