import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { ITicketKendoUIGrid, IData } from './history.interfaces';
import { IFilterModel } from 'src/app/shared/shared.interface';

export function flatTicketData(data: IData[]): ITicketKendoUIGrid[] {
  let flattedData: ITicketKendoUIGrid[] = [];
  data?.forEach((element) => {
    var elem: ITicketKendoUIGrid = {
      TicketId: element?.ticketId,
      YearId: element?.year.yearId,
      AssigneeId: element?.assignee.userId,
      RequesterId: element?.requester.userId,
      TicketTypeId: element?.ticketType.ticketTypeId,
      Assignee: element?.assignee.fullName,
      Requester: element?.requester.fullName,
      Year: element?.year.year,
      TicketType: element?.ticketType.ticketType,
      TicketStatus: element?.ticketStatus?.ticketStatus,
      ContractManufacturerName:
        element?.requester?.contractManufacturer?.contractManufacturerName,
      InsertionDate: element?.insertionDate,
      LastUpdateDate: element?.lastUpdateDate,
    };
    flattedData.push(elem);
  });
  return flattedData;
}

function convert(date: number[]): string {
  if (date == null) {
    return '';
  }
  return `${date[0]}/${date[1]}/${date[2]}`;
}

export function TicketMapSort(sort?: SortDescriptor[]) {
  var kendoSort = _.isArray(sort) ? sort[0] : sort;
  var dir = kendoSort?.dir === 'desc' ? 'desc' : 'asc';
  switch (kendoSort?.field) {
    case 'TicketType':
      return { columnName: 'ticketType.ticketType', direction: dir };
    case 'TicketStatus':
      return { columnName: 'ticketStatus.ticketStatus', direction: dir };
    case 'Year':
      return { columnName: 'year.year', direction: dir };
    case 'InsertionDate':
      return { columnName: 'insertionDate', direction: dir };
    case 'LastUpdateDate':
      return { columnName: 'lastUpdateDate', direction: dir };
    default:
      return { columnName: 'ticketStatus.ticketStatus', direction: dir };
  }
}

export function TicketMapFilter(gridFilters: any) {
  var allfilters = !_.isArray(gridFilters) ? gridFilters[0] : gridFilters;
  var kendoFilters: IFilterModel[]  = [];

  var filterObject: IFilterModel;
  allfilters.forEach((kendoFilter: any) => {
    var filterValue=_.isArray(kendoFilter?.filters)? kendoFilter?.filters[0]: kendoFilter; 
  switch (filterValue?.field) {
    case 'TicketType':
      filterObject ={
        columnName: 'ticketType.ticketType',
        value: filterValue?.value,
      };
      break;
      case 'TicketStatus':
        filterObject ={
        columnName: 'ticketStatus.ticketStatus',
        value: filterValue?.value,
      };
      break;
      case 'Year':
        filterObject= {
        columnName: 'year.year',
        value: filterValue?.value,
      };
      break;
      case 'InsertionDate':
      filterObject= {
        columnName: 'insertionDate',
        value: filterValue?.value,
      };
      break;
      case 'LastUpdateDate':
        filterObject={
          columnName: 'lastUpdateDate',
          value: filterValue?.value,
        };
        break;
    default:
      return [];
  }
  kendoFilters.push(filterObject);
  });
  return kendoFilters;
}
