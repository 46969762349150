import {
  flatTicketData,
  TicketMapSort,
} from 'src/app/core/upload/history/history.mapper';
import {
  IUploadState,
  UploadAction,
} from 'src/app/core/upload/upload.interface';
import { UPLOAD_FILE_ID } from 'src/app/core/upload/upload.request';
import { downLoadFile } from 'src/app/core/utils';
import {
  normalizedGridColumns,
  uploadedGridColumns,
  uploadedGridDDL,
  uploadedSuppliereGridColumns,
  uploadedSupplierGridDDL,
} from '../utils/headers';
import { ActionTypes } from './upload.action';

const UPLOAD_TICKET_ID = 'upload-ticket-id';
const UPLOAD_QUOTE_ID = 'upload-quote-id';
const UPLOAD_EXISTING_ID = 'upload-existing-id';


export const INITIAL_UPLOAD_STATE: IUploadState = {
  normalizationResult: {
    duplicateCount: 0,
    errorCount: 0,
    isNormalized: null,
    normalizedGrid: {
      data: [],
      currentPage: 1,
      pageSize: 15,
    },
  },

  normalizationSupplierResult: {
    duplicateCount: 0,
    errorCount: 0,
    isNormalized: null,
    normalizedGrid: {
      data: [],
      currentPage: 1,
      pageSize: 15,
    },
  },
  quotePartsDetails: [],
  awardedQuotePartsDetails: [],
  filter: undefined,
  uploadFile: {
    fileId: 0,
    isActive: '',
  },
  discard: {
    status: undefined,
  },
  supplierGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
  },
  supplierAwardedQuotesGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
  },
  supplierGridFilter: undefined,
  supplierGridSort: undefined,
  supplierAwardedQuotesGridSort: undefined,
  supplierAwardedQuotesGridFilter: undefined,
  validateFile: {
    loading: false,
    fileData: null,
  },
  updateExistingFileStatus: {
    loading: false,
    fileData: null,
  },
  reviewGrid: {
    data: [],
    columns: [],
    currentPage: 1,
    pageSize: 15,
  },
  historyGrid: {
    currentPage: 0,
    offset: 1,
    data: [
      {
        Requester: '',
        year: '',
        ticketStatus: '',
        ticketType: '',
        contractManufacturerName: '',
        insertionDate: '',
        lastUpdateDate: '',
      },
    ],
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
    sort: {},
    export: {},
    edit: {},
    delete: '',

  },
  updateAwardedQuoteAckStatus: undefined,
  updateAwardedQuoteAckStatusBySelection: undefined,
  requestGrid: {
    data: [],
    currentPage: 1,
    pageSize: 10,
  },
  currentAction: {
    actionType: UploadAction.UPLOAD,
    ticketId: sessionStorage.getItem(UPLOAD_TICKET_ID),
    fileId: null,
  },
  currentExistAction: {
    actionType: UploadAction.UPLOAD,
    fileId: sessionStorage.getItem(UPLOAD_EXISTING_ID),
  },
  currentSupplierAction: {
    actionType: UploadAction.UPLOAD,
    id: sessionStorage.getItem(UPLOAD_QUOTE_ID),
    fileId: null,
  },
  currentUpdateExistingAction: {
    actionType: UploadAction.UPLOAD,
    id: sessionStorage.getItem(UPLOAD_EXISTING_ID),
    fileId: null,
  },
  ticketFileSubmited: {
    loading: false,
    error: undefined,
    ticketFileSubmited: false,
  },
  close: {
    status: undefined,
  },
  supplierUploadFile: { fileId: 0, isActive: '' },
  supplierReviewGrid: {
    data: [],
    columns: [],
    currentPage: 1,
    pageSize: 15,

  },
  validateSupplierFile: {
    loading: false,
    fileData: null,
  },
  uploadSupplierFile: {
    fileId: 0,
    isActive: '',
  },
  supplierFileSubmitted: {
    loading: false,
    error: undefined,
    supplierFileId: 0,
  },
  awardedQuotePartsDetailsGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
  },
  awardedQuoteDetails: {
    supplierId: undefined,
    quoteId: undefined,
    quoteName: undefined
  }

};

export function UploadReducer(state = INITIAL_UPLOAD_STATE, action: any): any {
  switch (action.type) {
    case ActionTypes.UPLOAD_REVIEW_GRID_DATA: {
      return {
        ...state,
        reviewGrid: {
          ...state.reviewGrid,
          columns: [],
          DDL: [],
          data: [],
          loading: true,
          error: undefined,
        },
        normalizationResult: {
          ...state.normalizationResult,
          isNormalized: null,
        },
      };
    }
    // Request
    case ActionTypes.UPLOAD_REVIEW_GRID_DATA_SUCCESS: {

      return {
        ...state,
        reviewGrid: {
          ...state.reviewGrid,
          columns: uploadedGridColumns(action.payload?.rows[0]?.cells),
          DDL: uploadedGridDDL(action.payload?.columns),
          data: action.payload?.rows,
          valid: action.payload?.valid,
          loading: false,
          error: undefined,
        },
        currentAction: {
          ...state.currentAction,
          fileId: action.payload?.fileId,
        },
        uploadFile: {
          ...state.uploadFile,
          loading: false,
        },
      };
    }
    case ActionTypes.UPLOAD_REVIEW_GRID_DATA_ERROR: {
      return {
        ...state,
        reviewGrid: {
          ...state.reviewGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.TICKET_REQUEST_DATA: {
      return {
        ...state,
        requestGrid: {
          ...state.requestGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.TICKET_REQUEST_DATA_SUCCESS: {
      return {
        ...state,
        requestGrid: {
          ...state.requestGrid,
          data: action.payload,
          total: action.payload?.total,
          loading: false,
        },
      };
    }
    case ActionTypes.TICKET_REQUEST_DATA_ERROR: {
      return {
        ...state,
        requestGrid: {
          ...state.requestGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    //discard ticket
    case ActionTypes.DISCARD_TICKET: {
      return {
        ...state,
        discard: {
          ...state.discard,
          loading: true,
        },
        currentAction: {
          ...state.currentAction,
          ticketId: null,
          fileId: null,
        },
        uploadFile: {
          fileId: null,
          isActive: false,
          loading: false,
        },
      };
    }
    case ActionTypes.DISCARD_TICKET_SUCCESS: {
      return {
        ...state,
        discard: {
          ...state.discard,
          status: 'success',
          loading: false,
        },
      };
    }
    case ActionTypes.DISCARD_TICKET_ERROR: {
      return {
        ...state,
        discard: {
          ...state.discard,
          loading: false,
          error: action.payload,
        },
      };
    }
    //close ticket
    case ActionTypes.CLOSE_TICKET: {
      return {
        ...state,
        close: {
          ...state.close,
          loading: true,
        },
      };
    }
    case ActionTypes.CLOSE_TICKET_SUCCESS: {
      return {
        ...state,
        close: {
          ...state.close,
          status: 'closed',
          loading: false,
        },
      };
    }
    case ActionTypes.CLOSE_TICKET_ERROR: {
      return {
        ...state,
        close: {
          ...state.close,
          loading: false,
          error: action.payload,
        },
      };
    }
    // History
    case ActionTypes.TICKET_HISTORY_DATA: {
      return {
        ...state,
        historyGrid: {
          ...state.historyGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.TICKET_HISTORY_DATA_SUCCESS: {
      return {
        ...state,
        historyGrid: {
          ...state.historyGrid,
          data: flatTicketData(action.payload.data),
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.TICKET_HISTORY_DATA_ERROR: {
      return {
        ...state,
        historyGrid: {
          ...state.historyGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.SET_CURRENT_ACTION_DATA: {
      //Save data in local storage as well to help if user refresh page
      sessionStorage.setItem(UPLOAD_TICKET_ID, action.ticketId);
      sessionStorage.setItem(UPLOAD_FILE_ID, action.fileId);
      sessionStorage.setItem(UPLOAD_EXISTING_ID, action.fileId);

      return {
        ...state,
        currentAction: {
          ...state.currentAction,
          actionType: action.actionType,
          ticketId: action.ticketId,
        },
      };
    }
    case ActionTypes.SET_CURRENT_EXIST_ACTION_DATA: {

      sessionStorage.setItem(UPLOAD_EXISTING_ID, action.fileId);

      return {
        ...state,
        currentExistAction: {
          ...state.currentExistAction,
          actionType: action.actionType,
          fileId: action.fileId,
          type:action.filetype
        },
      };
    }
    case ActionTypes.EXPORT_NORMALIZED_GRID_DATA: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          export: {
            loading: true,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_NORMALIZED_GRID_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          export: {
            loading: false,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_NORMALIZED_GRID_DATA_ERROR: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          export: {
            loading: false,
            error: action.payload,
          },
        },
      };
    }
    case ActionTypes.EMPTY_VALIDATE_FILE_STATUS: {
      return {
        ...state,
        validateFile: {
          ...state.validateFile,
          fileData: null,
          loading: false,
        },
        currentAction: {
          ...state.currentAction,
          fileId: null,
        },
      };
    }

    case ActionTypes.VALIDATE_FILE_STATUS: {
      return {
        ...state,
        validateFile: {
          ...state.validateFile,
          fileData: null,
          loading: true,
        },
        currentAction: {
          ...state.currentAction,
          fileId: null,
        },
      };
    }

    case ActionTypes.VALIDATE_FILE_STATUS_SUCCESS: {
      return {
        ...state,
        validateFile: {
          ...state.validateFile,
          fileData: {
            isActive: action.payload?.isActive,
            fileId: action.payload?.fileId,
          },
          loading: false,
        },
        currentAction: {
          ...state.currentAction,
          fileId: action.payload?.fileId,
        },
      };
    }

    case ActionTypes.VALIDATE_FILE_STATUS_ERROR: {
      return {
        ...state,
        validateFile: {
          ...state.validateFile,
          fileData: null,
          loading: false,
        },
      };
    }

    case ActionTypes.GET_UPDATE_EXISTING_FILE_STATUS: {
      return {
        ...state,
        updateExistingFileStatus: {
          ...state.updateExistingFileStatus,
          fileData: null,
          loading: true,
        },
        currentAction: {
          ...state.currentAction,
          fileId: null,
        },
      };
    }

    case ActionTypes.GET_UPDATE_EXISTING_FILE_STATUS_SUCCESS: {
      return {
        ...state,
        updateExistingFileStatus: {
          ...state.updateExistingFileStatus,
          fileData: {
            isActive: action.payload?.isActive,
            fileId: action.payload?.fileId,
          },
          loading: false,
        },
        currentAction: {
          ...state.currentAction,
          fileId: action.payload?.fileId,
        },
      };
    }

    case ActionTypes.GET_UPDATE_EXISTING_FILE_STATUS_ERROR: {
      return {
        ...state,
        updateExistingFileStatus: {
          ...state.updateExistingFileStatus,
          fileData: null,
          loading: false,
        },
      };
    }
    case ActionTypes.EMPTY_UPDATE_EXISTING_FILE_STATUS: {
      return {
        ...state,
        updateExistingFileStatus: {
          ...state.updateExistingFileStatus,
          fileData: null,
          loading: false,
        },
        currentAction: {
          ...state.currentAction,
          fileId: null,
        },
      };
    }
    // upload ticket file
    case ActionTypes.UPLOAD_FILE: {
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.UPLOAD_FILE_SUCCESS: {
      sessionStorage.setItem(UPLOAD_FILE_ID, action.payload?.fileId);
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          fileId: action.payload?.fileId,
          isActive: action.payload?.isActive,
          error: undefined,
          loading: false,
        },
      };
    }
    case ActionTypes.UPLOAD_FILE_ERROR: {
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.EMPTY_UPLOAD_FILE: {
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          fileId: undefined,
          isActive: false,
          loading: false,
          error: undefined,
        }
      };
    }
    // history

    case ActionTypes.TICKET_PAGE_CHANGED: {
      return {
        ...state,
        historyGrid: {
          ...state.historyGrid,
          currentPage: action.payload,
        },
      };
    }

    case ActionTypes.TICKET_FILTER_CHANGED: {
      return {
        ...state,
        historyGrid: {
          ...state.historyGrid,
          filter: action?.filter?.filters,
        },
      };
    }

    case ActionTypes.TICKET_SORT_CHANGED: {
      return {
        ...state,
        ticketGrid: {
          ...state.historyGrid,
          sort: TicketMapSort(action?.payload),
        },
      };
    }

    case ActionTypes.TICKET_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        ticketGrid: {
          ...state.historyGrid,
          pageSize: action.payload,
        },
      };
    }

    case ActionTypes.MAPPED_GRID_DATA: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          isNormalized: null,
          loading: true,
        },
      };
    }
    case ActionTypes.MAPPED_GRID_DATA_SUCCESS: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          duplicateCount: action.payload?.duplicateCount,
          errorCount: action.payload?.errorCount,
          loading: false,
          isNormalized: true,
          normalizedGrid: {
            ...state.normalizationResult.normalizedGrid,
            columns: normalizedGridColumns(action.payload?.mappedColumns),
            data: action.payload?.rows,
          },
        },
      };
    }
    case ActionTypes.MAPPED_GRID_DATA_ERROR: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          loading: false,
          isNormalized: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.SUBMIT_TICKET_FILE: {
      return {
        ...state,
        ticketFileSubmited: {
          ...state.ticketFileSubmited,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.SUBMIT_TICKET_FILE_SUCCESS: {
      return {
        ...state,
        ticketFileSubmited: {
          ...state.ticketFileSubmited,
          loading: false,
          ticketFileSubmited: true,
        },
      };
    }
    case ActionTypes.SUBMIT_TICKET_FILE_ERROR: {
      return {
        ...state,
        ticketFileSubmited: {
          ...state.ticketFileSubmited,
          loading: false,
          error: action.payload,
          ticketFileSubmited: false,
        },
      };
    }
    case ActionTypes.EXPORT_COMPONENT_PRICE_REVIEW: {
      return {
        ...state,
        requestGrid: {
          ...state.requestGrid,
          export: {
            loading: true,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_COMPONENT_PRICE_REVIEW_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        requestGrid: {
          ...state.requestGrid,
          export: {
            loading: false,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_COMPONENT_PRICE_REVIEW_ERROR: {
      return {
        ...state,
        requestGrid: {
          ...state.requestGrid,
          export: {
            loading: false,
            error: action.payload,
          },
        },
      };
    }
    /*---------------------Supplier------------------*/

    case ActionTypes.SUPPLIER_LOAD_DATA_GRID: {
      return {
        ...state,
        supplierGrid: {
          ...state.supplierGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.SUPPLIER_LOAD_DATA_GRID_SUCCESS: {
      return {
        ...state,
        supplierGrid: {
          ...state.supplierGrid,
          data: action?.payload?.data,
          currentPage: action?.payload?.currentPage,
          offset: action?.payload?.offset,
          pageSize: action?.payload?.pageSize,
          total: action?.payload?.total,
          loading: false,
        },
      };
    }
    case ActionTypes.SUPPLIER_LOAD_DATA_GRID_ERROR: {
      return {
        ...state,
        supplierGrid: {
          ...state.supplierGrid,
          loading: false,
          error: action.payload,
        },
      };
    } // upload ticket file
    case ActionTypes.SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID: {
      return {
        ...state,
        supplierAwardedQuotesGrid: {
          ...state.supplierAwardedQuotesGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID_SUCCESS: {
      return {
        ...state,
        supplierAwardedQuotesGrid: {
          ...state.supplierAwardedQuotesGrid,
          data: action?.payload?.data,
          currentPage: action?.payload?.currentPage,
          offset: action?.payload?.offset,
          pageSize: action?.payload?.pageSize,
          total: action?.payload?.total,
          loading: false,
        },
      };
    }
    case ActionTypes.SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID_ERROR: {
      return {
        ...state,
        supplierAwardedQuotesGrid: {
          ...state.supplierAwardedQuotesGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA: {
      return {
        ...state,
        quotePartsDetails: {
          ...state.quotePartsDetails,
          export: {
            loading: true,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        quotePartsDetails: {
          ...state.quotePartsDetails,
          export: {
            loading: false,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA_Error: {
      return {
        ...state,
        quotePartsDetails: {
          ...state.quotePartsDetails,
          export: {
            loading: false,
            error: action.payload,
          },
        },
      };
    }
    case ActionTypes.EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA: {
      return {
        ...state,
        awardedQuotePartsDetails: {
          ...state.awardedQuotePartsDetails,
          export: {
            loading: true,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        awardedQuotePartsDetails: {
          ...state.awardedQuotePartsDetails,
          export: {
            loading: false,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA_ERROR: {
      return {
        ...state,
        awardedQuotePartsDetails: {
          ...state.awardedQuotePartsDetails,
          export: {
            loading: false,
            error: action.payload,
          },
        },
      };
    }
    case ActionTypes.EXPORT_SUPPLIER_QUOTES_GRID_DATA: {
      return {
        ...state,
        export: {
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.SUPPLIER_UPLOAD_FILE: {
      return {
        ...state,
        supplierUploadFile: {
          ...state.supplierUploadFile,
          fileId: undefined,
          isActive: undefined,
          tempId: undefined,
          error: undefined,
          loading: true,
        },
      };
    }
    case ActionTypes.SUPPLIER_UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        supplierUploadFile: {
          ...state.supplierUploadFile,
          fileId: action.payload?.data?.id,
          isActive: action.payload?.fileStatus === 1 ? 'true' : 'false',
          tempId: action?.payload?.data?.tempResponseId,
          error: undefined,
          loading: false,
        },
      };
    }
    case ActionTypes.SUPPLIER_UPLOAD_FILE_ERROR: {
      return {
        ...state,
        supplierUploadFile: {
          ...state.supplierUploadFile,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.EXPORT_SUPPLIER_QUOTES_GRID_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        export: {
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.EXPORT_SUPPLIER_QUOTES_GRID_DATA_ERROR: {
      return {
        ...state,
        export: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA: {
      return {
        ...state,
        export: {
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        export: {
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA_ERROR: {
      return {
        ...state,
        export: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.SUPPLIER_SET_CURRENT_ACTION_DATA: {
      //Save data in local storage as well to help if user refresh page
      sessionStorage.setItem(UPLOAD_QUOTE_ID, action.quoteId);


      return {
        ...state,
        currentSupplierAction: {
          ...state.currentSupplierAction,
          actionType: action.actionType,
          quoteId: action.quoteId,
        },
      };
    }
    //discard ticket
    case ActionTypes.SUPPLIER_DISCARD_FILE: {
      return {
        ...state,
        discard: {
          ...state.discard,
          loading: true,
        },
        currentSupplierAction: {
          ...state.currentSupplierAction,
          id: null,
          fileId: null,
        },
      };
    }
    case ActionTypes.SUPPLIER_DISCARD_FILE: {
      return {
        ...state,
        discard: {
          ...state.discard,
          status: 'success',
          loading: false,
        },
      };
    }
    case ActionTypes.SUPPLIER_DISCARD_FILE_ERROR: {
      return {
        ...state,
        discard: {
          ...state.discard,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.SUPPLIER_VALIDATE_FILE_STATUS: {
      return {
        ...state,
        validateSupplierFile: {
          ...state.validateSupplierFile,
          fileData: null,
          loading: true,
        },
        currentSupplierAction: {
          ...state.currentSupplierAction,
          fileId: null,
        },
      };
    }
    case ActionTypes.SUPPLIER_FILTER_CHANGED: {
      return {
        ...state,
        supplierGridFilter: action?.filter,
      };
    }
    case ActionTypes.SUPPLIER_SORT_CHANGED: {
      return {
        ...state,
        supplierGridSort: action?.sort,
      };
    }
    case ActionTypes.SUPPLIER_AWARDED_QUOTES_FILTER_CHANGED: {
      return {
        ...state,
        supplierAwardedQuotesGridFilter: action?.filter,
      };
    }
    case ActionTypes.SUPPLIER_AWARDED_QUOTES_SORT_CHANGED: {
      return {
        ...state,
        supplierAwardedQuotesGridSort: action?.sort,
      };
    }

    case ActionTypes.SUPPLIER_VALIDATE_FILE_STATUS_SUCCESS: {
      return {
        ...state,
        validateSupplierFile: {
          ...state.validateSupplierFile,
          fileData: {
            isActive: action.payload?.data?.fileStatus === 1 ? 'true' : 'false',
            fileId: action.payload?.data?.id,
            tempResponseId: action.payload?.data?.tempResponseId,
          },
          loading: false,
        },
        currentSupplierAction: {
          ...state.currentSupplierAction,
          fileId: action.payload?.id,
        },
      };
    }

    case ActionTypes.SUPPLIER_VALIDATE_FILE_STATUS_ERROR: {
      return {
        ...state,
        validateSupplierFile: {
          ...state.validateSupplierFile,
          fileData: null,
          loading: false,
        },
      };
    }
    case ActionTypes.SUPPLIER_UPLOAD_REVIEW_GRID_DATA: {
      return {
        ...state,
        supplierReviewGrid: {
          ...state.supplierReviewGrid,
          columns: [],
          DDL: [],
          data: [],
          loading: true,
          error: undefined,
        },
        normalizationSupplierResult: {
          ...state.normalizationSupplierResult,
          isNormalized: null,
        },
      };
    }
    // Request
    case ActionTypes.SUPPLIER_UPLOAD_REVIEW_GRID_DATA_SUCCESS: {

      return {
        ...state,
        supplierReviewGrid: {
          ...state.supplierReviewGrid,
          columns: uploadedSuppliereGridColumns(
            action.payload?.data?.rows[0]?.cells
          ),
          DDL: uploadedSupplierGridDDL(action.payload?.data?.columns),
          data: action.payload?.data?.rows,
          loading: false,
          error: undefined,
        },
        currentSupplierAction: {
          ...state.currentSupplierAction,
          fileId: action.payload?.data?.fileId,
        },
        uploadSupplierFile: {
          ...state.uploadSupplierFile,
          loading: false,
        },
      };
    }
    case ActionTypes.SUPPLIER_UPLOAD_REVIEW_GRID_DATA_ERROR: {
      return {
        ...state,
        supplierReviewGrid: {
          ...state.supplierReviewGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.SUPPLIER_MAPPED_GRID_DATA: {
      return {
        ...state,
        supplierFileSubmitted: {
          ...state.supplierFileSubmitted,
          supplierFileId: undefined,
        },
        normalizationSupplierResult: {
          ...state.normalizationSupplierResult,
          isNormalized: null,
          loading: true,
        },
      };
    }
    case ActionTypes.SUPPLIER_MAPPED_GRID_DATA_SUCCESS: {
      return {
        ...state,
        normalizationSupplierResult: {
          ...state.normalizationSupplierResult,
          duplicateCount: action.payload?.data?.duplicateCount,
          errorCount: action.payload?.data?.errorCount,
          loading: false,
          isNormalized: true,
          normalizedGrid: {
            ...state.normalizationSupplierResult.normalizedGrid,
            columns: normalizedGridColumns(action.payload?.data?.mappedColumns),
            data: action.payload?.data?.rows,
          },
        },
      };
    }
    case ActionTypes.SUPPLIER_MAPPED_GRID_DATA_ERROR: {
      return {
        ...state,
        normalizationSupplierResult: {
          ...state.normalizationSupplierResult,
          loading: false,
          isNormalized: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.EXPORT_SUPPLIER_NORMALIZED_GRID_DATA: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          export: {
            loading: true,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_SUPPLIER_NORMALIZED_GRID_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          export: {
            loading: false,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_SUPPLIER_NORMALIZED_GRID_DATA_ERROR: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          export: {
            loading: false,
            error: action.payload,
          },
        },
      };
    }
    case ActionTypes.SUBMIT_SUPPLIER_FILE: {
      return {
        ...state,
        supplierFileSubmitted: {
          ...state.supplierFileSubmitted,
          supplierFileId: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.SUBMIT_SUPPLIER_FILE_SUCCESS: {
      return {
        ...state,
        supplierFileSubmitted: {
          ...state.supplierFileSubmitted,
          loading: false,
          supplierFileId: action?.payload,
        },
      };
    }
    case ActionTypes.SUBMIT_SUPPLIER_FILE_ERROR: {
      return {
        ...state,
        supplierFileSubmitted: {
          ...state.supplierFileSubmitted,
          loading: false,
          error: action.payload,
          supplierFileId: 0,
        },
      };
    }
    case ActionTypes.UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS: {
      return {
        ...state,
        updateAwardedQuoteAckStatus: {
          ...state.updateAwardedQuoteAckStatus,
          message: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS_SUCCESS: {
      return {
        ...state,
        updateAwardedQuoteAckStatus: {
          message: action?.payload.message,
          loading: false,
          error: undefined,
        }
      };
    }
    case ActionTypes.UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS_ERROR: {
      return {
        ...state,
        loading: false,
        message: undefined,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
