import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { QuoteService } from 'src/app/core/Admin/quotes/quote.service';
import { gridRequestQuoteDetails } from 'src/app/core/Admin/quotes/quotes.request';
import { ServiceMiddleware } from 'src/app/core/Admin/service.layer/api.services.middleware';
import { gridRequest } from 'src/app/core/grid.request';
import { mapFilter, MapMultipleFilter, mapSort } from 'src/app/core/utils';
import {
  ActionTypes,
  colLoadData,
  colLoadDataError,
  colLoadDataSucees,
  disLoadData,
  disLoadDataError,
  disLoadDataSucees,
  GetQuoteDetailsById,
  GetQuoteDetailsByIdError,
  GetQuoteDetailsByIdSuccess,
  getQuoteById,
  getQuoteByIdError,
  getQuoteByIdSucees,
  manLoadData,
  manLoadDataError,
  manLoadDataSucees,
  manLoadFilteredData,
  manLoadFilteredDataError,
  manLoadFilteredDataSuccess,
  QuoteExportData,
  QuoteExportDataError,
  QuoteExportDataSucees,
  QuoteLoadData,
  QuoteLoadDataError,
  QuoteLoadDataSucees,
  updateQuoteData,
  updateQuoteDataError,
  updateQuoteDataSuccess,
  updateQuote,
  updateQuoteError,
  updateQuoteSucees,
  DiscardQuote,
  DiscardQuoteError,
  DiscardQuoteSucess,
  ExportNormalizedGridData,
  ExportNormalizedGridDataError,
  ExportNormalizedGridDataSucees,
  GetLastQuoteFileStatus,
  GetLastQuoteFileStatusError,
  GetLastQuoteFileStatusSuccess,
  MappedQuoteData,
  MappedQuoteDataError,
  MappedQuoteDataSuccess,
  ReviewQuoteData,
  ReviewQuoteDataError,
  ReviewQuoteDataSuccess,
  SubmitQuoteFile,
  SubmitQuoteFileError,
  SubmitQuoteFileSuccess,
  UPLOAD_Quote_FILE,
  UPLOAD_Quote_FILE_ERROR,
  UPLOAD_Quote_FILE_SUCCESS,
  ExportQuoteDetailsGridData,
  ExportQuoteDetailsGridDataSuccess,
  ExportQuoteDetailsGridDataError,
  sendReminderMail,
  sendReminderMailSuccess,
  sendReminderMailError,
  GetPriceHistory,
  GetPriceHistorySuccess,
  GetPriceHistoryError,
  AwardBySelection,
  AwardBySelectionSuccess,
  AwardBySelectionError,
  GetAllFilteredResponseIds,
  GetAllFilteredResponseIdsSuccess,
  GetAllFilteredResponseIdsError,
  PriceHistoryExportData,
  PriceHistoryExportDataSucees,
  PriceHistoryExportDataError,
  QuoteExporPriceHistorytData,
  QuoteExporPriceHistorytDataSucees,
  QuoteExporPriceHistorytDataError,
  AckBySelection,
  AckBySelectionSuccess,
  AckBySelectionError,
  CancelAckBySelectionSuccess,
  CancelAckBySelectionError,
  GenerateLOABySelection,
  GenerateLOABySelectionSuccess,
  GenerateLOABySelectionError,
  createLOA,
  createLOASuccess,
  createLOAError,
  getLOA,
  getLOASuccess,
  getLOAError,
  GenerateLoa,
  GenerateLoaSuccess,
  GenerateLoaError,
  quoteDetailsColumnsLoadData,
  quoteDetailsColumnsLoadDataSucess,
  quoteDetailsColumnsLoadDataError,
  contractManLoadData,
  contractManLoadDataSucess as contractManLoadDataSuccess,
  contractManLoadDataError,
  DeleteLoa,
  DeleteLoaSuccess,
  DeleteLoaError,
} from './quotes.action';

@Injectable({
  providedIn: 'root',
})
export class QuoteEffects {
  APIURL: string;
  constructor(
    private actions$: Actions,
    private serviceMiddleware: ServiceMiddleware,
    private quoteService: QuoteService
  ) {}

  getQuotesGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.QUOTE_LOAD_DATA),
      switchMap((action: QuoteLoadData) => {
        this.APIURL = '/rfq/Quote/GetPageableQuotes';
        const request = gridRequest(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map((dataResponse: any) => new QuoteLoadDataSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new QuoteLoadDataError(errorResponse))
          )
        );
      })
    );
  });
  ExportQuoteGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.QUOTE_EXPORT_DATA),
      switchMap((action: QuoteExportData) => {
        const request = gridRequest(
          action?.payload?.page,
          action?.payload?.size,
          mapSort(action?.payload?.sort),
          mapFilter(action?.payload?.filter)
        );
        this.APIURL = '/rfq/Quote/Export/QuoteList';
        return this.serviceMiddleware
          .exportFilteredList(this.APIURL, request)
          .pipe(
            map(
              (dataResponse: any) =>
                new QuoteExportDataSucees(dataResponse, action.fileName)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new QuoteExportDataError(errorResponse))
            )
          );
      })
    );
  });

  ExportPriceHitoryData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.PRICE_HISTORY_EXPORT_DATA),
      switchMap((action: PriceHistoryExportData) => {
        this.APIURL = '/rfq/Quote/ExportPriceHistory';
        return this.serviceMiddleware
          .exportFilteredList(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) =>
                new PriceHistoryExportDataSucees(dataResponse, action.payload?.fileName)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new PriceHistoryExportDataError(errorResponse))
            )
          );
      })
    );
  });

  GenerateLoaFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GENERATE_LOAD_FILE),
      switchMap((action: GenerateLoa) => {
        this.APIURL = '/rfq/Loa/UploadLoaFile';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) =>
                new GenerateLoaSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new GenerateLoaError(errorResponse))
            )
          );
      })
    );
  });

  ExportQuotePriceHitoryData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.QUOTE_PRICE_HISTORY_EXPORT_DATA),
      switchMap((action: QuoteExporPriceHistorytData) => { 
        this.APIURL = '/rfq/Quote/Export/QuotePriceHistory';
        return this.serviceMiddleware
          .exportFilteredList(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) =>
                new QuoteExporPriceHistorytDataSucees(dataResponse, action.payload?.fileName)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new QuoteExporPriceHistorytDataError(errorResponse))
            )
          );
      })
    );
  });


  getManData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.QUOTE_MAN_LOAD_DATA),
      switchMap((action: manLoadData) => {
        this.APIURL = '/rfq/ManufacturerApi/GetManufacturerList';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new manLoadDataSucees(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new manLoadDataError(errorResponse))
            )
          );
      })
    );
  });

  getContractManData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CONTRACT_MAN_LOAD_DATA),
      switchMap((action: contractManLoadData) => {
        this.APIURL = `/rfq/ContractManfacturer/GetContractManufacturerList?cmName=${action?.cmName}`;
        return this.serviceMiddleware
          .Get(this.APIURL)
          .pipe(
            map((dataResponse: any) => new contractManLoadDataSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new contractManLoadDataError(errorResponse))
            )
          );
      })
    );
  });

  getManFilteredData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.QUOTE_MAN_LOAD_FILTERED_DATA),
      switchMap((action: manLoadFilteredData) => {
        this.APIURL = '/rfq/ManufacturerApi/GetManufacturersByName?name=';
        return this.serviceMiddleware.Get(this.APIURL + action.manName).pipe(
          map(
            (dataResponse: any) => new manLoadFilteredDataSuccess(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new manLoadFilteredDataError(errorResponse))
          )
        );
      })
    );
  });

  disManData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.QUOTE_DIS_LOAD_DATA),
      switchMap((action: disLoadData) => {
        this.APIURL = '/rfq/Distributor/GetDistributorListByManfacturers';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new disLoadDataSucees(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new disLoadDataError(errorResponse))
            )
          );
      })
    );
  });

  updateQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.UPDATE_QUOTE),
      switchMap((action: updateQuote) => {
        this.APIURL = '/rfq/Quote/updatequote';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new updateQuoteSucees(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new updateQuoteError(errorResponse))
            )
          );
      })
    );
  });

  getPriceHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.PRICE_HISTORY_LOAD_DATA),
      switchMap((action: GetPriceHistory) => {
        this.APIURL = '/rfq/Quote/GetSupplierPriceHistory';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new GetPriceHistorySuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new GetPriceHistoryError(errorResponse))
            )
          );
      })
    );
  });
  getQuoteById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_QUOTE_BY_ID),
      switchMap((action: getQuoteById) => {
        this.APIURL = '/rfq/Quote/GetQuoteByQuoteId/';
        return this.serviceMiddleware.Get(this.APIURL + action.quoteId).pipe(
          map((dataResponse: any) => new getQuoteByIdSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new getQuoteByIdError(errorResponse))
          )
        );
      })
    );
  });

  colManData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.COL_LOAD_DATA),
      switchMap((action: colLoadData) => {
        this.APIURL = '/rfq/Quote/GetQuoteColumns';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new colLoadDataSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new colLoadDataError(errorResponse))
          )
        );
      })
    );
  });
  getQuoteDetailsGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_QUOTE_DETAILS_RECORDS_BY_ID),
      switchMap((action: GetQuoteDetailsById) => {
        this.APIURL = `/rfq/Quote/GetPageableQuoteDetails/${action?.quoteId}`;
        const request = gridRequestQuoteDetails(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map(
            (dataResponse: any) => new GetQuoteDetailsByIdSuccess(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetQuoteDetailsByIdError(errorResponse))
          )
        );
      })
    );
  });
  updateQuoteData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.UPDATE_QUOTE_DATA),
      switchMap((action: updateQuoteData) => {
        this.APIURL = '/rfq/Quote/UpdateQuoteData';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action?.quoteDTO)
          .pipe(
            map(
              (dataResponse: any) => new updateQuoteDataSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new updateQuoteDataError(errorResponse))
            )
          );
      })
    );
  });
  getUploadReviewGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.REVIEW_QUOTE_DATA),
      switchMap((action: ReviewQuoteData) => {
        this.APIURL =
          '/rfq/Quote/MapQuoteFile/' +
          action?.fileId +
          '/' +
          action.tempQuoteId;
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new ReviewQuoteDataSuccess(dataResponse)),
          catchError((errorResponse: any) =>
            of(new ReviewQuoteDataError(errorResponse))
          )
        );
      })
    );
  });

  uploadQuoteFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.UPLOAD_Quote_FILE),
      switchMap((action: UPLOAD_Quote_FILE) => {
        this.APIURL = '/rfq/Quote/UploadQuoteFile';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) => new UPLOAD_Quote_FILE_SUCCESS(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new UPLOAD_Quote_FILE_ERROR(errorResponse))
            )
          );
      })
    );
  });

  getLastQuoteFileStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.VALIDATE_QUOTE_FILE_STATUS),
      switchMap((action: GetLastQuoteFileStatus) => {
        this.APIURL = '/rfq/Quote/GetLastQuoteFileStatus';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new GetLastQuoteFileStatusSuccess(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetLastQuoteFileStatusError(errorResponse))
          )
        );
      })
    );
  });

  discardQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.DISCARD_QUOTE),
      switchMap((action: DiscardQuote) => {
        this.APIURL = '/rfq/Quote/DiscardQuoteFile';
        return this.serviceMiddleware
          .EditRecord(this.APIURL, { id: action.fileId })
          .pipe(
            map((dataResponse: any) => new DiscardQuoteSucess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new DiscardQuoteError(errorResponse))
            )
          );
      })
    );
  });

  sendMappedData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.MAP_Quote_GRID_DATA),
      switchMap((action: MappedQuoteData) => {
        return this.quoteService
          .normalizeMappedColumnsData(
            action?.mappedColumns,
            action?.startRow,
            action?.tempQuoteId
          )
          .pipe(
            map(
              (dataResponse: any) => new MappedQuoteDataSuccess(dataResponse)
            ),
            catchError((errorResponse: any) =>
              of(new MappedQuoteDataError(errorResponse))
            )
          );
      })
    );
  });

  exportNormalizedQuoteData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_NORMALIZED_GRID_DATA),
      switchMap((action: ExportNormalizedGridData) => {
        this.APIURL =
          '/rfq/Quote/Export/normalizedQuote/' +
          action.tempQuoteId +
          '/' +
          action.exportOption;
        return this.serviceMiddleware.exportList(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new ExportNormalizedGridDataSucees(dataResponse, action.fileName)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ExportNormalizedGridDataError(errorResponse))
          )
        );
      })
    );
  });

  SubmitQuoteFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SUBMIT_QUOTE_FILE),
      switchMap((action: SubmitQuoteFile) => {
        this.APIURL = '/rfq/Quote/createquote';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) => new SubmitQuoteFileSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new SubmitQuoteFileError(errorResponse))
            )
          );
      })
    );
  });

  sendReminderMail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SEND_REMINDER_MAIL),
      switchMap((action: sendReminderMail) => {
        this.APIURL = '/rfq/Quote/sendReminder';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) => new sendReminderMailSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new sendReminderMailError(errorResponse))
            )
          );
      })
    );
  });

  exportQuoteDetailsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_QUOTE_DETAILS_GRID_DATA),
      switchMap((action: ExportQuoteDetailsGridData) => {
        const request = gridRequest(
          action?.payload?.page,
          action?.payload?.size,
          mapSort(action?.payload?.sort),
          mapFilter(action?.payload?.filter)
        );
        this.APIURL = `/rfq/Quote/Export/QuoteDetails/${action?.quoteId}?fileName=${action?.fileName}`;
        return this.serviceMiddleware.exportFilteredList(this.APIURL,request).pipe(
          map(
            (dataResponse: any) =>
              new ExportQuoteDetailsGridDataSuccess(
                dataResponse,
                action.fileName
              )
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new ExportQuoteDetailsGridDataError(errorResponse))
          )
        );
      })
    );
  });

  awardBySelection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.AWARD_BY_SELECTION),
      switchMap((action: AwardBySelection) => {
        this.APIURL = '/rfq/Quote/AwardBySelection';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) => new AwardBySelectionSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new AwardBySelectionError(errorResponse))
            )
          );
      })
    );
  });

  ackBySelection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.ACK_BY_SELECTION),
      switchMap((action: AckBySelection) => {
        this.APIURL = '/rfq/Quote/UpdateAckNotification';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) => new AckBySelectionSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new AckBySelectionError(errorResponse))
            )
          );
      })
    );
  });
  getAllFilteredResponseIds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_ALL_FILTERED_RESPONSE_IDS),
      switchMap((action: GetAllFilteredResponseIds) => {
        this.APIURL = '/rfq/Quote/GetAllFilteredResponseIds/'+ action.quoteId;
        const request = mapFilter(action?.filter);

        return this.serviceMiddleware
          .getList(this.APIURL, request)
          .pipe(
            map(
              (dataResponse: any) => new GetAllFilteredResponseIdsSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new GetAllFilteredResponseIdsError(errorResponse))
            )
          );
      })
    );
  });
  
  cancelAckBySelection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CANCEL_ACK_BY_SELECTION),
      switchMap((action: CancelAckBySelectionError) => {
        this.APIURL = '/rfq/Quote/CancelAcknowledgment';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) => new CancelAckBySelectionSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new CancelAckBySelectionError(errorResponse))
            )
          );
      })
    );
  });
  generateLOABySelection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GENERATE_LOA_BY_SELECTION),
      switchMap((action: GenerateLOABySelection) => {
        this.APIURL = '/rfq/Quote/GenerateLOA';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) => new GenerateLOABySelectionSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new GenerateLOABySelectionError(errorResponse))
            )
          );
      })
    );
  });
  createLOA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CREATE_LOA),
      switchMap((action: createLOA) => {
        this.APIURL = '/rfq/Loa/CreateLoa';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) => new createLOASuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new createLOAError(errorResponse))
            )
          );
      })
    );
  });
  getLOA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_LOA),
      switchMap((action: getLOA) => {
        const {supplierId,manfactuererId,loaId  } = action.payload;
        const dto = { supplierId : supplierId, manfactuererId: manfactuererId, loaId: loaId}
        this.APIURL = '/rfq/Loa/GetLoa';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, dto)
          .pipe(
            map(
              (dataResponse: any) => new getLOASuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new getLOAError(errorResponse))
            )
          );
      })
    );
  });

  deleteLOA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.DELETE_LOA),
      switchMap((action: DeleteLoa) => {
     
        this.APIURL = '/rfq/Loa/DeleteLoa/'+action.loaId;
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL)
          .pipe(
            map(
              (dataResponse: any) => new DeleteLoaSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new DeleteLoaError(errorResponse))
            )
          );
      })
    );
  });

  quoteDetailsColumnsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.QUOTE_DETAILS_COLUMNS_LOAD_DATA),
      switchMap((action: quoteDetailsColumnsLoadData) => {
        this.APIURL = `/rfq/Quote/GetQuoteDetailsColumns?colName=${action.colName}`;
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new quoteDetailsColumnsLoadDataSucess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new quoteDetailsColumnsLoadDataError(errorResponse))
          )
        );
      })
    );
  });
}
