import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';

export function comMapSort(sort?: SortDescriptor[]) {
  var kendoSort = _.isArray(sort) ? sort[0] : sort;
  var dir = kendoSort?.dir === 'desc' ? 'desc' : 'asc';
  switch (kendoSort?.field) {

    case 'commodityName':
      return {
        columnName: 'd.commodity_name',
        direction: dir,
      };
      case 'contractManufacturerName':
        return {
          columnName: 'contractManufacturer.contractManufacturerName',
          direction: dir,
        };
    case 'selectedOwners':
      return {
        columnName: 'u.full_name',
        direction: dir,
      };
    default:
      return { columnName: kendoSort?.field, direction: dir };
  }
  // return { columnName: 'requester.fullName', direction: dir };
}

