<div class="toolbar">
  <app-stepper
    *ngIf="hasStepper"
    [currentStep]="currentStep"
    [steps]="stepperSteps"
  ></app-stepper>
   

 
  <div *ngFor="let item of items">
    <h4 *ngIf="item.itemType === itemType.LABEL" style="margin-top:5px;margin-right: 15px;">{{item.txt}}</h4>
    <h4 *ngIf="item.itemType === itemType.ERRORLABEL" style="margin-top:5px;margin-right: 15px; color: red;">{{item.errortxt}}</h4>
    <app-button-component
      *ngIf="item.itemType === itemType.BUTTON"
      [value]="item.message"
      [buttonIcon]="item.icon"
      [hasPopOver]="item.hasPopOver"
      [disabled]="item.disable"
      [showPopoverOption]="item.showOption"
      [position]="item.position"
      [htmlData]="item.htmlData"
      [showdialog]="item.showdialog"
      [dropDownItems]="item.dropdownData"
      [popOverContent]="item.popOverContent"
      (clickButtonEvent)="item.action.call()"
      [openedComponent]="item.openedComponent"
    ></app-button-component>
  </div>
</div>
