import { GridHeaderData, IApiRequestState, IListingState } from "src/app/shared/shared.interface";
import { IBusinessUnit } from "./buisness-unit/buisness-unit.interfaces";
import { ICommodities } from "./commodity/commodity.interfaces";
import { IContractManu } from "./contract-manu/contract-manu.interfaces";
import { IDistributor, IDistributorType } from "./distributor/distributor.interfaces";
import { IManufacturerDistributors, ICommodity } from "./manufacturer-distributors/manufacturer-distributors.interface";
import { IManufacturers, IValidationError, IManufacturerStatus } from "./manufacturer/manufacturer.interfaces";
import { ITicketGrid } from "./ticket/ticket.interfaces";
import { ICompanyType, IRoleList } from "./users/invited-users/invited-users.interfaces";
import { IRegisteredUser } from "./users/registered-users/registered-users.interface";

export enum UploadTypes {
    MANUFACTURERS = "manufacturers",
    DISTRIBUTORS = "distributors",
    COMMODITIES = "commodities",
    USERS = "users",
    MFRS_DISTIS = "mfrs_distis"
}
export enum GridDataTypes {
  CMSITES = "CMsites",
  CUSTOMERSITES = "CustomerSites",
  SUMMARY = "SUMMARY",
  DIVISIONS = "Divisions",
  COMMODITIES = "Commodities"
}
export enum ApiType {
  ADD = "Add",
  EDIT = "Edit",
}
// export enum RouteTypes {
//   MANUFACTURER = "manufacturers",
//   DISTRIBUTOR = "distributors",
//   COMMODITY = "commodities",
//   USER = "user",
//   MANDISTCOMMRELATION = "manufacturer-distributors"
// }
// export interface CmMappedColumn {
//     index: number;
//     featureName: string;
//     featureId: number;
//     required: string;
// }

export interface IMapColumnsRequest {
    fileId: number;
    startRow: number;
    cmMappedColumns: GridHeaderData[];
}

export interface IAdminState {
    ticketGrid: IListingState<ITicketGrid>;
    dataGrid: IListingState<any>;
    InvitedGrid: IListingState<any>;
    RegisteredGrid: IListingState<IRegisteredUser>;
    ticketGridRecord: any;
    userEmail: any;
    ContractManufacturerGrid: IListingState<IContractManu>;
    buisnessUnitGrid: IListingState<IBusinessUnit>;
    comGrid: IListingState<ICommodities>;
    manGrid: IListingState<IManufacturers>;
    disGrid: IListingState<IDistributor>;
    loaGrid: IListingState<any>;
    manDisGrid: IListingState<IManufacturerDistributors>;
    yearCurrGrid: IListingState<any>; // type to be modified
    cmGrid: IListingState<any>; // type to be modified
    reviewTicketGrid: IListingState<any>;
    ticketType: IListingState<any>;
    resend: any;
    ticketYear: IListingState<any>;
    ticketAssignee: IListingState<any>;
    approve: any;
    reject: any;
    validationError: IValidationError;
    commodoties: ICommodity[];
    commodityOwnersList: any;
    manufacturers: IManufacturers[];
    distributers: IDistributor[];
    manStatus: IManufacturerStatus[];
    companyType: ICompanyType[];
    getCompany: any;
    roleList: IRoleList[];
    disType: IDistributorType[];
    gridActionPopupData: any;
    currentAction: ICurrentActionData; 
    uploadedFileResult: IUPloadFile;
    fileStatus: IValidateFile;
    discard: IDiscard;
    normalizationResult: INormalizationResult;
    mapUploadedFileResult: IListingState<IUploadResponse>;
    fileSubmited: IFileSubmited;
  }
  export interface IFileSubmited extends IApiRequestState {
    fileSubmited: boolean;
  }
  
  
export interface INormalizationResult extends IApiRequestState {
    duplicateCount: number;
    errorCount: number;
    isNormalized: boolean | null;
    normalizedGrid: IListingState<IUploadResponse>;
  }
  export interface IUploadResponse {
    fileId: number;
    rows: Array<IRows>;
    columns: Array<UploadColumns>;
    DDL?: Array<UploadColumns>;
    valid?:boolean;
  }
  export interface IRows {
    rowIndex: number;
    cells: Array<ICell>;
    rowComment: any;
  }
  export interface ICell {
    columnIndex: number;
    value: string;
  }
  
  export interface UploadColumns {
    index: number;
    featureName: string;
    featureId: number;
    required: boolean;
  }
  
export interface IDiscard extends IApiRequestState {
    status: string | undefined;
  }
  export interface IValidateFile extends IApiRequestState {
    fileData: IFileData | null;
  }
  export interface IFileData {
    fileId: number;
    isActive: string;
  }
  export interface ICurrentActionData {
    actionType: string;
    fileId?: string | null;
    fileType?: string | null;
  }
  export interface IUPloadFile extends IApiRequestState {
    fileId: number;
    isActive: string;
  }

  export enum UploadAction {
    UPLOAD = 'upload',
    NORMALIZE = 'normalize',
    REVIEW = 'review',
    SUBMIT = 'submit',
  }