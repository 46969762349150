import { FileStatusEnum } from 'src/app/shared/enums/file-status.enum';
import {
  IApiRequestState,
  IListingState,
} from 'src/app/shared/shared.interface';

export enum QuoteAction {
  UPLOAD = 'upload',
  NORMALIZE = 'normalize',
  MAP = 'map',
  SUBMIT = 'submit',
  SEND = 'send',
}

export interface IQuoteFileSubmited extends IApiRequestState {
  ticketFileSubmited: boolean;
}
export interface IPriorty {
  priortyId: number;
  priortyName: string;
}
export interface IDiscardQuote extends IApiRequestState {
  status: string | undefined;
}

export interface IUploadQuoteResponse {
  tempQuoteId: number;
  fileId: number;
  rows: Array<IQuoteRows>;
  columns: Array<QuoteColumns>;
  DDL?: Array<QuoteColumns>;
}

export interface IQuoteRows {
  rowIndex: number;
  cells: Array<IQuoteCell>;
  rowComment: any;
}
export class ManufacturerObject {
  manId: number;
}

export class ManufacturerFilter {
  manName: string;
  QuoteId: number;
}
export class SendReminderMail {
  manfacturers: number[];
  distributers: number[];
  messageBody: string;
  quoteId: number;
}
export class DistributerFilter {
  distributorName: string;
  manfacturers: ManufacturerObject[];
}

export interface IQuoteCell {
  columnIndex: number;
  value: string;
}

export interface QuoteColumns {
  index: number;
  featureName: string;
  columnId: number;
  required: boolean;
}

export interface IQuoteFile extends IApiRequestState {
  tempQuoteId: any;
  fileId: number;
  fileStatus: FileStatusEnum;
}

export interface IValidateQuoteFile extends IApiRequestState {
  fileData: IQuoteFile | null;
}

export interface ICurrentQuoteActionData {
  actionType: string;
  tempQuoteId?: string | null;
  fileId?: string | null;
}
export interface IQuoteAction {
  data?: IQuoteData | null;
}
export class QuoteObject {
  TempQuoteID: number;
}
export interface IQuote {
  data?: IQuoteData | null;
}
export interface IQuoteData {
  quoteId?: number;
}
export interface IDropDownHeader {
  dropDownHeader: boolean;
  columns: Array<columns>;
  selected: Array<columns>;
}

export interface columns {
  index: number;
  name: string;
  title?: string;
  required?: boolean;
}

export interface IMappedDataRequest {
  tempQuoteId: any;
  fileId: string;
  startRow: number;
  mappedColumns: columns[];
}
export interface IRequester {
  userId: string;
  fullName: string;
}
export interface IUser {
  userId: string;
  fullName: string;
}

export interface INormalizationResultDto extends IApiRequestState {
  duplicateCount: number;
  errorCount: number;
  fileId: number;
  tempQuoteId: string;
  mappedColumns: IMappedColumnDTO[];
  rows: IMappedRowDTO[];
  isNormalized: boolean | null;
  normalizedGrid: IListingState<IUploadQuoteResponse> | null;
}
export interface IMappedColumnDTO {
  index: number;
  featureId: number;
  featureName: string;
  required: boolean;
}
export interface IRowCellDTO {
  columnIndex: number;
  value: string;
}
export interface IMappedRowDTO {
  rowIndex: number;
  cells: IRowCellDTO[];
  rowComment: string;
}

export interface IQuoteState {
  quoteGrid: IListingState<IQuoteGridData>;
  filter: any;
  sort: any;
  export: any;
  manGrid: any;
  updateQuote: any;
  sendReminderMail: any;
  getQuoteById: any;
  disGrid: any;
  colGrid: any;
  cmGrid: any;
  quoteDetailsGrid: IListingState<any>;
  priceHistoryGrid: any;
  quoteData: IQuoteDetailsData;
  manFilter: any;
  cmFilter: any;
  colFilter:any;
  disFilter: any;
  currentQuoteAction: ICurrentQuoteActionData;
  submitQuoteAction: any;

  validateQuoteFile: IQuoteFile;
  discardQuote: IDiscardQuote;
  uploadQuoteFile: IQuoteFile;
  quoteFileSubmited: IQuoteFileSubmited;
  reviewQuoteGrid: IListingState<any>;
  normalizationResult: INormalizationResultDto;
  gridActionPopupData: any;
  selectAllResponses: any;
  generateLoa: any;
  loaResponseDTO: LoaResponseDTO;
  getLoadDTO: GetLoadDTO;
}
export interface IQuoteDetailsData {
  id: number;
  quoteName: string;
  operationStatus: string;
  description: string;
  dueDate: string;
  time: string;
  priority: string;
  estimateTotalPrice: number;
  quantity: number;
  fileId: number;
  currencyId: number;
  loading: false;
  error: undefined;
}
export class QuoteUpdateObj {
  Id: number;
  quoteName: string;
  description: string;
  dueDate: Date;
  time: Date;
  priority: string;
  estimateTotalPrice: number;
  quantity: number;
  fileId: string;
  QuoteColumns: QuoteUpdateColumns[] = [];
  quotePartDetails: any;
  quoteSuppliers: QuoteSuppliers[] = [];
}
export class QuoteUpdateColumns {
  columnId: number;
  quoteId: number;
}
export class SupplierHistoryObj {
  supplierId: number;
  partId: string;
  quoteId: number;
}
export class ExportSupplierHistoryObj {
  supplierId: number;
  partId: string;
  quoteId: number;
  fileName: string;

}
export class QuoteSuppliers {
  SupplierId: number;
  SupplierType: number;
  quoteId: number;
}
export class QuoteExportPriceHistory {
  quoteId: number;
  fileName: string;
 }
export interface IQuoteGridData {
  quoteId: number;
  quoteName: string;
  noOfParts: number;
  creationDate: Date;
  dueDate: Date;
  status: StatusEnum;
  priority: PriorityEnum;
  minPrice: number;
  maxPrice: number;
  estimateTotalPrice: number;
  quantity: number;
  minLeadTime: number;
  maxLeadTime: number;
  noOfResponses: number;
}


export interface LoaDTO {
    columns: number[];
    manfacutrerIds: number[];
    partsDTOs: LoaPartsDTO[];
    quoteId: number;
}
export interface LoaPartsDTO {
  partId: number;
  supplierId: number;
}
export interface LoaResponseDTO {
  supplierId: number;
  manfactuererId: number;
  loaId: number[];
  loading: boolean;
  error: any;
}
export enum supplierType {
  manufacturer = 1,
  distributor = 2,
}

export interface QuoteDetailsGridDTO {
  responseId: number | null;
  quoteId: number;
  partId: number;
  rowId: number;
  noOfResponses: number;
  supplierInternalSupplierId: number | null;
  cPN: string;
  mPN: string;
  mFR: string;
  rFQTo: string;
  quoteName: string;
  description: string;
  creationDate: string;
  responderTypeName: string;
  rFQStatus: string;
  price: number | null;
  sEAveragePrice: string;
  sEMinPrice: string;
  leadTime: number | null;
  sEMinLeadTime: string;
  sEMaxLeadTime: string;
  mOQ: string;
  lastResponseDate: string | null;
  last_Response_Date: string;
  expirationDate: string | null;
  expiration_Date: string;
  rebate: string;
  isAwarded: string | null;
  awarded: boolean | null;
  awardedNotificationDate: string | null;
  awarded_Notification_Date: string;
  awardedByName: string;
  aCKStatus: string;
  isAcknotification: string | null;
  acknotification: boolean | null;
  ackDate: string | null;
  ack_Date: string;
  canceledByName: string;
  email: string;
  creatorUserStatus: UserStatusEnum | null;
  stock: number | null;
}
export enum UserStatusEnum {
  None = 0,
  Enabled = 1,
  Disabled = 2,
  Deleted = 3,
  Locked = 4
}
export interface GetLoadDTO {
    supplierName: string;
    manfacutererName: string;
    loaList: QuoteDetailsGridDTO[];
    columns: string[];
    loading: boolean;
    error: any;
}
export enum StatusEnum {}
export enum PriorityEnum {}
export enum exportNormalizedGridOptions {
  All = 1,
  Duplication = 2,
}

export var PriorityItems: string[] = ['Urgent', 'Normal', 'Low'];

export var QuoteStatusList: string[] = ['Open', 'In progress', 'Closed'];
