import {
  IFilterModel,
  IGridRequest,
  ISortModel,
} from '../shared/shared.interface';

export function gridRequest(
  page?: number,
  size?: number,
  sortByModel?: ISortModel,
  filterModel?: IFilterModel[]
) {
  const request: IGridRequest = {
    filterModel: filterModel ? filterModel : [],
    page: page ? page : 0,
    size: size ? size : 10,
    sortByModel: sortByModel ? sortByModel : {},
  };

  return request;
}
