import { Action, createReducer, on } from '@ngrx/store';
import { Role } from 'src/app/shared/models/role';
import { IUserState } from '../user.interface';
import * as UserActions from './user.actions';
import { UserActionTypes } from './user.actions';


const INITIAL_USER_STATE: IUserState = {
  redirectUrl: '',
  profile: {
    email: '',
    firstName: '',
    lastName: '',
    personName: '',
    role: [Role.Admin]
  },
  ForgetPassword: {
    isSent: false
  },
  ResetPassword: {
    isSent: false
  },
  getUserEmailByCode: ''
};
const reducer = createReducer(
  INITIAL_USER_STATE,
  on(UserActions.userLoggedIn, (state, action) => ({
    ...state,
    profile: action.payload,
  }))
);
export function UserReducer(state = INITIAL_USER_STATE, action: any): any {
  switch (action.type) {
    case UserActionTypes.USER_FOTGET_PASSWORD: {
      return {
        ...state.ForgetPassword,
        loading: true,

      };
    }
    case UserActionTypes.USER_FOTGET_PASSWORD_SUCCESS: {
      return {
        ...state,
        ForgetPassword: {
          ...state.ForgetPassword,
          isSent: true,
          loading: false,
        },

      };
    }
    case UserActionTypes.USER_FOTGET_PASSWORD_ERROR: {
      return {
        ...state,
        ForgetPassword: {
          ...state.ForgetPassword,
          isSent: false,
          loading: false,
        },
      };
    }
    case UserActionTypes.USER_RESET_PASSWORD: {
      return {
        ...state.ResetPassword,
        loading: true,

      };
    }
    case UserActionTypes.USER_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        ResetPassword: {
          ...state.ResetPassword,
          isSent: true,
          loading: false,
        },
      };
    }
    //GET USER EMAIL BY CODE 
    case UserActionTypes.GET_USER_EMAIL_BY_CODE: {
      return {
        ...state,
        loading: false,
      };
    }

    case UserActionTypes.GET_USER_EMAIL_BY_CODE_SUCCESS: {
      return {
        ...state,
        getUserEmailByCode: action.payload,
        loading: true,

      };
    }
    case UserActionTypes.GET_USER_EMAIL_BY_CODE_ERROR: {
      return {
        ...state,
        getUserEmailByCode: action.payload,
        loading: false,
      };
    }
    case UserActionTypes.USER_RESET_PASSWORD_ERROR: {
      return {
        ...state,
        ResetPassword: {
          ...state.ResetPassword,
          isSent: false,
          loading: false,
        },
      };
    }
  }
}
// export function UserReducer(
//   state: IUserState | undefined,
//   action: Action
// ): IUserState {
//   return reducer(state, action);
// }
